import React from 'react'
import VerticalBarChart from './VerticalBarChart';

export default function RootCausePlot(props) {
    return (
        <div className=''>
            <VerticalBarChart data={props.data} name={'rootcause'} dataType="quality" filterOnCall={props.filterOnCall}/>
        </div>
    );
}
