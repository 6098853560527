import React, { useEffect, useRef } from 'react';
import utils from './app-util';
import Dropdown from './dropdown';

function formatFilterData(lob, countries, projects) {
    return [
        { name: "lobs", label: "LoB", data: lob },
        { name: "countries", label: "Countries", data: countries },
        { name: "projects", label: "Projects", data: projects }
    ]
}

function SearchAdvanced({ projects, countries, lob, advanceSearchCallback, mustIncludeCallback, emocAdvanceFilter, emocProjectCountryLoB, 
    qualityAdvanceFilter, qualityProjectCountryLoB }) {
    const elemRef = useRef(null);
    useEffect(() => {
        if (emocAdvanceFilter) {
            elemRef.current.classList.remove("risk_advanced");
            elemRef.current.classList.add("emoc_advanced");
            elemRef.current.classList.remove("quality_advanced");
        } else if(qualityAdvanceFilter) {
            elemRef.current.classList.remove("emoc_advanced");
            elemRef.current.classList.remove("risk_advanced");
            elemRef.current.classList.add("quality_advanced");
        } else {
            elemRef.current.classList.remove("emoc_advanced");
            elemRef.current.classList.add("risk_advanced");
            elemRef.current.classList.remove("quality_advanced");
        }
    });
    let filters = [
        { name: "lobs", label: "LoB", data: [] },
        { name: "countries", label: "Countries", data: [] },
        { name: "projects", label: "Projects", data: [] }
    ];
    if (emocAdvanceFilter) {
        filters = formatFilterData(emocProjectCountryLoB.lob, emocProjectCountryLoB.countries, emocProjectCountryLoB.projects);
    } else if (qualityAdvanceFilter){
        filters = formatFilterData(qualityProjectCountryLoB.lob, qualityProjectCountryLoB.countries, qualityProjectCountryLoB.projects);
    } else {
        filters = formatFilterData(lob, countries, projects);
    }

    function parentCallbackFunc(data) {
        advanceSearchCallback(data, emocAdvanceFilter, qualityAdvanceFilter);
        utils.clearBarChartHighlight();
    }
    function clearAllOnClick() {
        advanceSearchCallback({ projects: [], countries: [], lobs: [] }, emocAdvanceFilter, qualityAdvanceFilter);
        const lists = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR)
        lists.forEach(function (element) {
            element.remove();
        });
        const mustInclude = document.querySelector('.must-include');
        // If searched with must include, after clearing all it should give the result of prev query
        if (mustInclude.classList.contains("active")) {
            mustInclude.classList.remove("active");
            mustInclude.value = "";
            document.querySelector('.search-input-field').value = window.sessionStorage.getItem('_prevquery');
            document.querySelector('#search-icon-btn').click();
        }

    }
    function onkeyPress(e) {
        if (e.currentTarget.value !== "" && e.key === 'Enter') {
            /** 
             * if mustincude query searched then mark it as "active" that current search has been made using must include keyword, 
             * so that we can implement clear all button functionality
             */
            e.currentTarget.classList.add('active');
            window.sessionStorage.setItem('_prevquery', window.sessionStorage.getItem('query'));
            mustIncludeCallback(e.currentTarget.value);
        }
    }

    function onCloseClick() {
        document.querySelector('.must-include').value = "";
        const elem = document.querySelector('.advanced-search-wrapper');
        if (elem.classList.contains("active")) {
            elem.classList.remove("active");
        }
    }
    function dropdownTemplate(selectorClassName) {
        return (
            <div className={`dropdown-filtering p-2 ${selectorClassName}`}>
                <div className="d-flex no-gutters mt-2">
                    {
                        filters.map(({ name, label, data }) => {
                            return (<div className='col' key={name} style={{margin: '0px 1px'}}><Dropdown id={name} name={label} data={data} parentCallback={parentCallbackFunc} /></div>)
                        })
                    }
                </div>
            </div>
        );
    }
    return (
        <div className="mb-3 advanced-search-wrapper tour-advancedsearch-mustinclude" ref={elemRef}>
            <div className="component-title mb-2 d-flex justify-content-between"><span>Advanced Search</span>
                <span className="advance-search-clear-btn" style={{ cursor: "pointer" }} onClick={clearAllOnClick} title="Clear filters">Clear All</span>
            </div>
            <div className="card card-box-shadow">
                <div className="">
                    <button className="adv-search-close btn btn-sm" onClick={onCloseClick} title="Close advanced search panel">&times;</button>
                    <div className="form-group material-form-group mb-2">
                        <div className='p-2'>
                            <label className="mb-0 small">Must Include</label>
                            <input className="form-control material-form-control must-include" onKeyPress={onkeyPress} placeholder="Type here" />
                        </div>
                        {/* <div className='dropdown-filtering p-3'>
                            <div className="d-flex justify-content-between mt-2">
                                {
                                    filters.map(({name, label, data})=>{
                                        return (<Dropdown key={name} id={name} name={label} data={data} parentCallback={parentCallbackFunc} />)
                                    })
                                }
                            </div>
                        </div> */}

                        <div className='risk-dropdown'>
                            {
                                dropdownTemplate(`risk-dropdown-inner ${!emocAdvanceFilter ? 'active' : ''}`)
                            }
                        </div>
                        <div className='emoc-dropdown'>
                            {
                                dropdownTemplate(`emoc-dropdown-inner ${emocAdvanceFilter ? 'active' : ''}`)
                            }
                        </div>
                        <div className='quality-dropdown'>
                            {
                                dropdownTemplate(`quality-dropdown-inner ${qualityAdvanceFilter ? 'active' : ''}`)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SearchAdvanced;
