import countryCode from './data/country-code-emoji.json';
const alternateName = {
    "Russian Federation": "Russia",
    "UK": "United Kingdom",
    "US": "United States",
    "TRINIDAD AND TOBAGO": "Trinidad & Tobago",
    "BRUNEI DARUSSALAM": "Brunei"
}
export default function getCountryFlag(countryName) {
    if (alternateName[countryName] !== undefined) {
        countryName = alternateName[countryName];
    }
    let returVal = null;
    for (const country of countryCode) {
        if (country.name.toLowerCase() === countryName.toLowerCase()) {
            returVal = country.code;
            break;
        }
    }
    return returVal;
}
