
import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";
import RaceSpinner from './spinner';
import utils from './app-util';
import { toast } from 'react-toastify';
import _ from 'lodash';

function formatValue(value) {
  if (Number.isInteger(value)) {
    return value;
  } else {
    return Number(value).toFixed(2);
  }
}
function clearDisable(axis, exceptValue) {
  const options = document.querySelectorAll("select." + axis + "axis-dropdown-select option");
  options.forEach((element) => {
    if (exceptValue !== element.value) {
      element.disabled = false;
    }
  });
}
function disableXY_AxisOption_ForSameValue(axisValueTypeSelected, value) {
  if (axisValueTypeSelected === 'x') {
    if (value === "Functional_L1" || value === "Physical_L3") {
      document.querySelector("select.yaxis-dropdown-select option[value=" + value + "]").disabled = true;
      clearDisable("y", value);
    } else {
      clearDisable("x", document.querySelector("select.yaxis-dropdown-select").value);
      clearDisable("y", "");
    }
  }
  if (axisValueTypeSelected === 'y') {
    if (value === "Functional_L1" || value === "Physical_L3") {
      document.querySelector("select.xaxis-dropdown-select option[value=" + value + "]").disabled = true;
      clearDisable("x", value);
    } else {
      clearDisable("y", document.querySelector("select.xaxis-dropdown-select").value);
    }
  }
}
function HMObserver() {
  return new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.contentBoxSize[0]) {

        document.querySelector(".heatmap-svg-wrapper").style.width = `${entry.contentRect.width - 100}px`;
      }
    }
  });
}
const valueUnits = { "Frequency": "", "Cost": "Millions", "Schedule": "Months", "Probability": "RAM Rating" }, NO_OF_HITS = "No. of Hits";;
function setHeatmapName(xAxisValue, yAxisValue, plotTypeValue) {
  document.querySelector(".heatmap-name").innerText = `${plotTypeValue === 'frequency' ?
    NO_OF_HITS : plotTypeValue + " (" + valueUnits[plotTypeValue.charAt(0).toUpperCase() + plotTypeValue.slice(1)] + ")"}: 
  ${yAxisValue.replace(/_/, ' ')} v/s ${xAxisValue.replace(/_/, ' ')}`;
}
class HeatMap extends React.Component {
  VALUE_DROPDOWN_SELECT_S = ".value-dropdown select";
  VALUE_DROPDOWN_SELECT = ".value-dropdown-select";
  Y_AXIS_DROPDOWN_SELCT = ".yaxis-dropdown select";
  X_AXIS_DROPDOWN_SELECT = ".xaxis-dropdown select";
  SELECTOR_HEATMAP = "svg#heatmap";
  DROPDOWN_BORDER_PROPERTY = "1px solid #009EB4";
  constructor(props) {
    super(props);
    this.createHeatMap = this.createHeatMap.bind(this);
    this.state = {
      data: this.props.data,
      indices: this.props.indices,
      loader: false
    }
    this.msgElem = React.createRef();
  }

  componentDidMount() {
    const __this = this;
    const data = this.state.data;
    if (data !== undefined) {
      this.margin = { top: 20, right: 20, bottom: 220, left: 220 };
      this.svg = d3.select(this.node);
      this.width = data.x_tick_labels.length * 25;
      this.height = data.y_tick_labels.length * 25;




      this.xScale = d3.scaleBand().range([0, this.width]);
      this.yScale = d3.scaleBand().range([0, this.height]);
      this.xAxis = d3.axisBottom().scale(this.xScale);
      this.yAxis = d3.axisLeft().scale(this.yScale);
      this.groupSVGMain = this.svg.attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom)
        .append("g").attr('class', 'groupSVGMain')
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
      this.groupSVGMain.append("g").attr("transform", "translate(0," + this.height + ")")
        .attr("class", "x axis");
      this.groupSVGMain.append("g")
        .attr("class", "y axis")

      this.tip = d3Tip()
        .attr("class", "d3-tooltip")
        .html(function (_d) {
          const nodeData = d3.select(this).data()[0];
          return `X: ${nodeData.x}<br/>Y: ${nodeData.y}<br/>
              ${d3.select(__this.VALUE_DROPDOWN_SELECT).property('value') === 'frequency' ? NO_OF_HITS : d3.select(__this.VALUE_DROPDOWN_SELECT).property('value')}
              : ${formatValue(nodeData[d3.select(__this.VALUE_DROPDOWN_SELECT).property('value')])}`;
        });
      this.svg.call(this.tip)


      //dropdown
      d3.select(".xaxis-dropdown").append("label").attr("class", "mr-2 mb-0").text("X-Axis: ");
      this.xaxisDropdown = d3.select(".xaxis-dropdown")
        .insert("select", this.SELECTOR_HEATMAP)
        .attr("class", "xaxis-dropdown-select")
        .style("width", "200px")
        .style("border", this.DROPDOWN_BORDER_PROPERTY)
        .on("change", function () {
          disableXY_AxisOption_ForSameValue("x", d3.select(this).property('value'));
          __this.typeOfXYAxisOnChange(d3.select(this).property('value'), document.querySelector(".yaxis-dropdown-select").value);
          setHeatmapName(
            d3.select(this).property('value'),
            d3.select(__this.Y_AXIS_DROPDOWN_SELCT).property('value'),
            document.querySelector(__this.VALUE_DROPDOWN_SELECT_S).value);
        });


      d3.select(".yaxis-dropdown").append("label").attr("class", "mr-2 mb-0").text("Y-Axis: ");
      this.yaxisDropdown = d3.select(".yaxis-dropdown")
        .insert("select", this.SELECTOR_HEATMAP)
        .attr("class", "yaxis-dropdown-select")
        .style("width", "200px")
        .style("border", this.DROPDOWN_BORDER_PROPERTY)
        .on("change", function () {
          disableXY_AxisOption_ForSameValue("y", d3.select(this).property('value'));
          __this.typeOfXYAxisOnChange(document.querySelector(".xaxis-dropdown-select").value, d3.select(this).property('value'));
          setHeatmapName(
            d3.select(__this.X_AXIS_DROPDOWN_SELECT).property('value'),
            d3.select(this).property('value'),
            document.querySelector(__this.VALUE_DROPDOWN_SELECT_S).value);
        });


      d3.select(".value-dropdown").append("label").attr("class", "mr-2 mb-0").text("Type of plot: ");
      this.valueDropdown = d3.select(".value-dropdown")
        .insert("select", this.SELECTOR_HEATMAP)
        .attr("class", "value-dropdown-select")
        .style("width", "200px")
        .style("border", this.DROPDOWN_BORDER_PROPERTY)
        .on("change", function () {
          __this.typeOfPlotOnChange(d3.select(this).property('value'))
          setHeatmapName(
            d3.select(__this.X_AXIS_DROPDOWN_SELECT).property('value'),
            d3.select(__this.Y_AXIS_DROPDOWN_SELCT).property('value'),
            d3.select(this).property('value'));
        });

      this.xaxisDropdown.selectAll('option')
        .data(["LoB", "Country", "Project", "Functional_L1", "Physical_L3"])
        .enter().append("option")
        .attr("value", function (d) { return d })
        .text(function (d) { return d.replace(/_/, ' ') })


      this.valueDropdown.selectAll('option')
        .data(["Frequency", "Cost", "Schedule", "Probability"])
        .enter().append("option")
        .attr("value", function (d) { return d.toLowerCase(); })
        .text(function (d) {
          return `${(d === "Frequency" ? NO_OF_HITS : d + " (" + valueUnits[d] + ")")}`;
        })
        .property("selected", function (d) {
          return d === "cost";
        })
      this.yaxisDropdown.selectAll('option')
        .data(["Functional_L1", "Physical_L3"])
        .enter().append("option")
        .attr("value", function (d) { return d })
        .text(function (d) { return d.replace(/_/, ' ') })
        .property("selected", function (d) {
          return d === "Functinal_L1";
        })

      disableXY_AxisOption_ForSameValue("y", "Functional_L1");//To disable the xAxis value since Y-axis default selected value is Functional_L1

      // ----- Heatmap name------------
      setHeatmapName(document.querySelector(this.X_AXIS_DROPDOWN_SELECT).value,
        document.querySelector(this.Y_AXIS_DROPDOWN_SELCT).value,
        document.querySelector(this.VALUE_DROPDOWN_SELECT_S).value);

      //=========================
      this.legendSelectorID = "#heatmaplegend";
      this.legendheight = this.height < 100 ? 300 : this.height;
      this.legendwidth = 100;
      this.legendMargin = { top: 10, right: 60, bottom: 10, left: 2 };

      this.legendCanvas = d3.select(this.legendSelectorID)
        .style("height", this.legendheight + "px")
        .style("width", this.legendwidth + "px")
        .style("position", "absolute")
        .append("canvas")
        .attr("height", this.legendheight - this.legendMargin.top - this.legendMargin.bottom)
        .attr("width", 1)
        .style("height", (this.legendheight - this.legendMargin.top - this.legendMargin.bottom) + "px")
        .style("width", (this.legendwidth - this.legendMargin.left - this.legendMargin.right) + "px")
        .style("position", "absolute")
        .style("top", (this.legendMargin.top) + "px")
        .style("left", (this.legendMargin.left) + "px")
        .node();

      this.legendCanvasCtx = this.legendCanvas.getContext("2d");
      this.legendscale = d3.scaleLinear().range([1, this.legendheight - this.legendMargin.top - this.legendMargin.bottom])
      this.legendaxis = d3.axisRight().scale(this.legendscale).tickSize(6).ticks(8);
      this.legendsvg = d3.select(__this.legendSelectorID)
        .append("svg")
        .attr("height", (__this.legendheight) + "px")
        .attr("width", (__this.legendwidth) + "px")
        .style("position", "absolute")
        .style("left", "0px")
        .style("top", "0px")
      this.axisG = this.legendsvg.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(" + (__this.legendwidth - __this.legendMargin.left - __this.legendMargin.right + 3) + "," + (__this.legendMargin.top) + ")")




      this.createHeatMap();
      this.hmWrapperElem = document.querySelector(".heatmap-wrapper");

      const hmRefresh = document.querySelector('.heatmap-refresh-btn');
      hmRefresh.addEventListener('click', this.heRefreshBtnOnClick.bind(this));

      this.resizeObserver = HMObserver();
      this.resizeObserver.observe(this.hmWrapperElem);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.hmWrapperElem);

    const hmRefresh = document.querySelector('.heatmap-refresh-btn');
    hmRefresh.removeEventListener("click", this.heRefreshBtnOnClick.bind(this));
  }
  heRefreshBtnOnClick(_e) {
    const plotFilterActive =  document.querySelector('.risk-graph-analysis #heatmap rect.clicked');
    if(plotFilterActive === null){
    // Refresh heat map if failed to load the data
    const indices = utils.getVisibleRiskResultIDs();
    this.loadHeatMapData(indices, document.querySelector(this.X_AXIS_DROPDOWN_SELECT).value, document.querySelector(this.Y_AXIS_DROPDOWN_SELCT).value);

    //Remove highlighted rect and display the all card
    const highlightedRect = document.querySelector("rect.clicked");
    if (highlightedRect !== null) {
      highlightedRect.classList.remove("clicked");
    };
    setHeatmapName(
      d3.select(this.X_AXIS_DROPDOWN_SELECT).property('value'),
      d3.select(this.Y_AXIS_DROPDOWN_SELCT).property('value'),
      d3.select(this.VALUE_DROPDOWN_SELECT).property('value'));
    } else {
      plotFilterActive.classList.remove('clicked');
      this.props.heatmapFilterCallback(null);
    }
  }
  typeOfXYAxisOnChange(xval, yval) {
    const __this = this;
    let indices = sessionStorage.getItem('indices');
    this.props.heatmapFilterCallback(null);//reset to the initial
    const riskYearFilter = document.querySelector('#sinceyearrisk').value;
    const riskCustomDateFilter = document.querySelector(".date-filter-wrapper.risk .react-daterange-picker input[name='daterange_from']").value;
    const riskTECOP = document.querySelectorAll(".risk-sidebar .filter-list input:checked");
    const advSearchElems = document.querySelectorAll('.advanced-search-wrapper.risk_advanced .dropdown-filtering.active .chiplist .chip');
    if (riskYearFilter !== '' || riskCustomDateFilter !== '' || riskTECOP.length > 0 || advSearchElems.length > 0) {
      indices = utils.getVisibleRiskResultIDs();
    }

    __this.loadHeatMapData(indices, xval, yval);
  }
  loadHeatMapData(indices, xval = "LoB", yval = "Functional_L1") {
    const __this = this;
    this.setState({ loader: true });
    fetch("/heatmap_data?risk_id=" + indices + "&x_data=" + xval + "&y_data=" + yval, {
      headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
      .then(res => {
        if (res.status !== 200) {
          utils.checkSessionStatus(res);
        }
        return res.json();
      })
      .then((results) => {
        __this.setState({ loader: false, data: results }, () => {
          __this.createHeatMap();
        });
      },
        (_err) => {
          __this.setState({ loader: false });
          toast.warn("No heatmap data found");
        }
      );
  }

  typeOfPlotOnChange(val) {
    const __this = this;
    let xMaxValue = d3.max(__this.state.data.data, function (d) {
      return d[val];
    });
    xMaxValue = val === 'probability' ? 5 : xMaxValue;
    __this.color = d3.scaleSequential(d3.interpolateReds).domain([0, xMaxValue]);
    __this.legendscale.domain(__this.color.domain().reverse());
    __this.axisG.call(__this.legendaxis);
    d3.selectAll('#heatmap .rect').each(function (d, _i) {
      d3.select(this).style('fill', __this.color(d[val]))
    });
  }
  rectOnClick(_selfContext) {
    const nodeData = d3.select(_selfContext).data()[0];
    const vType = d3.select(this.VALUE_DROPDOWN_SELECT).property('value')
    if (nodeData[vType] !== 0) {
      //toggle click
      if (_selfContext.classList.contains('clicked')) {
        _selfContext.classList.remove('clicked');
        this.props.heatmapFilterCallback(null);
      } else {
        _selfContext.classList.add('clicked');
        this.props.heatmapFilterCallback({ xAxisType: d3.select(".xaxis-dropdown-select").property('value'), data: nodeData });
      }
      //clear rect highlight if you click one rect then moved to another
      if ((this.lastClickedRect !== null) && (!this.lastClickedRect.isSameNode(_selfContext))) {
        this.lastClickedRect.classList.remove('clicked');
      }
      
      this.lastClickedRect = _selfContext;
    }
  }
  createHeatMap() {
    const __this = this;
    const data = __this.state.data;
    if (data.data.length > 0) {
      __this.svg.style('display', 'block');
      __this.msgElem.current.classList.remove('active');
      d3.select(__this.legendSelectorID).style('display', 'block');

      __this.width = data.x_tick_labels.length * 25;
      __this.height = data.y_tick_labels.length * 25;
      __this.svg.attr("width", __this.width + __this.margin.left + __this.margin.right)
        .attr("height", __this.height + __this.margin.top + __this.margin.bottom)

      __this.gridSize = __this.width / data.x_tick_labels.length;

      const valueType = document.querySelector(__this.VALUE_DROPDOWN_SELECT).value;

      const xMaxValue = d3.max(data.data, function (d) {
        return d[valueType];
      });

      __this.color = d3.scaleSequential(d3.interpolateReds).domain([0, xMaxValue]);


      document.querySelectorAll('.groupSVGMain rect').forEach(function (rects) {
        if (rects) {
          rects.remove();
        }
      })

      __this.xScale.range([0, __this.width]).domain(data.x_tick_labels);

      __this.groupSVGMain.select('.x.axis').attr("transform", "translate(0," + __this.height + ")")
        .call(this.xAxis)
        .selectAll("text")
        .attr("y", 7)
        .attr("dy", ".5em")
        .attr("x", "-.5em")
        .style("text-anchor", "end")
        .attr("transform", function (_d) {
          return "translate(0,0) rotate(-45)";
        });


      //Add the vertical labels
      __this.yScale.range([0, __this.height]).domain(data.y_tick_labels);

      __this.groupSVGMain.select('.y.axis')
        .call(this.yAxis)

      __this.lastClickedRect = null;
      const rect = __this.groupSVGMain.selectAll(".rect")
        .data(data.data)
      rect.exit().remove();
      rect.enter().append("rect")
        .attr("x", function (d, _i) {
          return __this.xScale(d.x)
        })
        .attr("y", function (d, _i) {
          return __this.yScale(d.y)
        })
        .attr("rx", 2)
        .attr("ry", 2)
        .attr("class", "rect")
        .attr("width", __this.gridSize)
        .attr("height", __this.gridSize)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", "2px")
        .style("fill", function (d) {
          return __this.color(d[valueType]);
        })
        .on("mouseover", __this.tip.show)
        .on("mouseout", __this.tip.hide)
        .on("click", function (_d) {
          __this.rectOnClick(this);

        });
      //------------ColorScale legend---------------
      __this.legendscale.domain(__this.color.domain().reverse());
      this.axisG.call(this.legendaxis);
      const image = this.legendCanvasCtx.createImageData(1, __this.legendheight);
      d3.range(__this.legendheight).forEach(function (i) {
        const c = d3.rgb(__this.color(__this.legendscale.invert(i)));
        image.data[4 * i] = c.r;
        image.data[4 * i + 1] = c.g;
        image.data[4 * i + 2] = c.b;
        image.data[4 * i + 3] = 255;
      });
      __this.legendCanvasCtx.putImageData(image, 0, 0);
      const checkNodeSizeInterval = setInterval(() => {
        if (__this.groupSVGMain.node().getBoundingClientRect().height > 0) {
          __this.svg.attr('height', __this.groupSVGMain.node().getBoundingClientRect().height + 20);
          clearInterval(checkNodeSizeInterval);
        }
      }, 100);
    } else {
      this.svg.style('display', 'none');
      this.msgElem.current.classList.add('active');
      d3.select(__this.legendSelectorID).style('display', 'none');
    }
  }

  render() {
    return (
      <div className="heatmap-svg-wrapper">
        <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
        {this.state.loader ? <RaceSpinner /> : null}
        <svg id="heatmap" ref={node => this.node = node} width="100%" height="500"></svg>
      </div>
    );
  }
}
export default HeatMap;
