import React, { useEffect, useState } from 'react';
import QSelect from './QSelect';
import { countBy } from 'lodash';

export default function QulityFilters(props) {
    const [isClear, setIsClear] = useState(false);
    useEffect(() => {
        document.querySelector('.clearall-quality').onclick = function() {
            setIsClear(true);
        }
    }, [props]);
    function filtersOnChange(value, filtertype) {
        try {
            setIsClear(false);
            setTimeout(() => {
                const values = {
                    characteristics: [],
                    disciplinefunction: [],
                    equipmenttype: []
                }
                values.characteristics = JSON.parse(document.querySelector(".quality-custom-autocomplete.characteristics").dataset.selectedvalue);
                values.disciplinefunction = JSON.parse(document.querySelector(".quality-custom-autocomplete.disciplinefunction").dataset.selectedvalue);
                values.equipmenttype = JSON.parse(document.querySelector(".quality-custom-autocomplete.equipmenttype").dataset.selectedvalue);
                
                props.equipmentTypeOnChange(values, filtertype);
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div>
            <div className='quality-filter-combobox small p-2'>
                <div className='my-1'>Characteristics</div>
                <QSelect data={[...new Set(props.data.quality_characteristics.flat())]} count={countBy(props.data.quality_characteristics.flat())}
                    onChange={filtersOnChange} filterType="characteristics" placeholder="Characteristics" isClear={isClear}/>
                <div className='my-1'>Discipline/Function</div>
                <QSelect data={[...new Set(props.data.quality_discipline_function.flat())]} count={countBy(props.data.quality_discipline_function.flat())}
                    onChange={filtersOnChange} filterType="disciplinefunction" placeholder="Discipline/Function" isClear={isClear}/>
                <div className='my-1'>Equipment Type</div>
                <QSelect data={[...new Set(props.data.quality_equipment_type.flat())]} count={countBy(props.data.quality_equipment_type.flat())}
                    onChange={filtersOnChange} filterType="equipmenttype" placeholder="Equipment Type" isClear={isClear}/>
            </div>
        </div>
    )
}
