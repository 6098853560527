import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import IdleTimeTracker from '../IdleTimeTrack/IdleTimeTracker';
import NavHeader from '../nav-header';
import UserView from './userViews';
import ViewsBarPlot from './viewsBarPlot';
import utils from '../app-util';
import RaceSpinner from '../spinner';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import * as d3 from "d3";
import DownloadReportGrid from './download-report-grid';
import infoicon from '../../assets/info.svg';
import Modal from './Modal';
import ActivityHeaderDefinitions from './activityHeaderDefinitions';
function loaduserViews(url, setViewdata, setLoading) {
    setLoading(true);
    fetch(url, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (result) {
                // Mark the plot data as refreshed/new data
                if(Object.keys(result).length > 0){
                    result.viewPlotData.isNew = true;
                }
                setViewdata(result);
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Fetch Error :', err);
        });
}
export default function ActivityDashboard() {
    const history = useHistory();
    const [adminInfo] = useState({isAdmin: history.location.state.admin, isAppOwner:  history.location.state.appOwner, otheruser: history.location.state.otheruser});
    const [loading, setLoading] = useState(false);
    const [daterange, setDaterange] = useState(null);
    const [viewdata, setViewdata] = useState({
        totalUserViews: 0,
        totalUniqueUserViews: 0,
        totalQueriesSearched: 0,
        totalReportsDownloaded: 0,
        totalThematicBowtieViews: 0,
        totalPageViews: 0,
        individualViewDetails: [],
        viewPlotData: [],
        downloadCounts: [],
        newUserAdded: 0
    });
    const [openDefinitionModal, setOpenDefinitionModal] = useState(false);
    useEffect(() => {
        loaduserViews(`/allviews?lastndays=30&daterange=`, setViewdata, setLoading);
    }, []);

    function dayFilterOnChange(e) {
        // Clear the custom date filter input field
        document.querySelector('.custom-date-filter .react-daterange-picker__clear-button.react-daterange-picker__button').click();
        loaduserViews(`/allviews?lastndays=${e.target.value}&daterange=`, setViewdata, setLoading);
    }
    function dataRangePickrOnChange(value) {
        setDaterange(value);
        if(value !== null) {
            const from = d3.timeFormat("%Y-%m-%d %H:%M:%S")(value[0])
            const to = d3.timeFormat("%Y-%m-%d %H:%M:%S")(value[1])
            loaduserViews(`/allviews?lastndays=&daterange=${from},${to}`, setViewdata, setLoading);
        }
    }
    const [fitertype, setFitertype] = useState('views');
    function activityOnClick(e) {
        document.querySelector('.visit-count-cards .card-header.active').classList.remove('active');
        e.currentTarget.classList.add('active');
        const activityType = e.currentTarget.dataset.type;
        viewdata.viewPlotData.isNew = true;
        setViewdata({...viewdata, viewPlotData: viewdata.viewPlotData})
        setFitertype(activityType)
    }
    const infoiconOnClick = useCallback(()=>{
        setOpenDefinitionModal(true);
    }, []);
    return (
        <div>
            <NavHeader admin={adminInfo.isAdmin} appOwner={adminInfo.isAppOwner} otheruser={adminInfo.otheruser} />
            <div className='container-fluid'>
                <section className='py-2'>
                    <div className='d-flex flex-row'>
                        <div className='pr-2'>
                            <fieldset className='filter-main small'>
                                <legend className="">Filter User Activities</legend>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type="radio" name='filteroption' id='filteroption1' onClick={dayFilterOnChange} value={30} defaultChecked={true} />
                                    <label className="form-check-label" htmlFor='filteroption1'>Last 30 days</label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type="radio" name='filteroption' id='filteroption2' onClick={dayFilterOnChange} value={60} />
                                    <label className="form-check-label" htmlFor='filteroption2'>Last 60 days</label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input className='form-check-input' type="radio" name='filteroption' id='filteroption3' onClick={dayFilterOnChange} value={90} />
                                    <label className="form-check-label" htmlFor='filteroption3'>Last 90 days</label>
                                </div>
                            </fieldset>
                        </div>
                        <div className='pl-2'>
                            <fieldset className='custom-date-filter'>
                                <legend className="">Select Custom Date Range</legend>
                                <div className='d-inline-block'>
                                    <DateRangePicker value={daterange} onChange={dataRangePickrOnChange} />
                                </div>
                            </fieldset>

                        </div>
                        <div className='ml-auto align-self-center'><img src={infoicon} alt='Definitions' onClick={infoiconOnClick} style={{cursor: 'pointer', width: '32px'}} title='Definitions'/> </div>
                    </div>
                </section>
                <section className='py-4 visit-count-cards d-flex justify-content-center'>

                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2 active' data-type="views" onClick={activityOnClick}><h6>Total Interactions</h6></div>
                            <div className='card-body'>{viewdata.totalUserViews}</div>
                        </div>
                    </div>
                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="uniqueuser" onClick={activityOnClick}><h6>Total Unique Users</h6></div>
                            <div className='card-body'>{viewdata.totalUniqueUserViews}</div>
                        </div>
                    </div>
                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="newuser" onClick={activityOnClick}><h6>Total New Users Added</h6></div>
                            <div className='card-body'>{viewdata.newUserAdded}</div>
                        </div>
                    </div>
                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="queries_searched" onClick={activityOnClick}><h6>Total Queries Searched</h6></div>
                            <div className='card-body'>{viewdata.totalQueriesSearched}</div>
                        </div>
                    </div>

                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="downloads" onClick={activityOnClick}><h6>Total Reports Downloaded</h6></div>
                            <div className='card-body'>{viewdata.totalReportsDownloaded}</div>
                        </div>
                    </div>
                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="thematic_bowtie_views" onClick={activityOnClick}><h6>Total Thematic Bowtie Views</h6></div>
                            <div className='card-body'>{viewdata.totalThematicBowtieViews}</div>
                        </div>
                    </div>
                    <div className='col card-wrap px-2'>
                        <div className='card rounded-0 text-center'>
                            <div className='card-header px-2' data-type="pageviews" onClick={activityOnClick}><h6>Total Tab Views</h6></div>
                            <div className='card-body'>{viewdata.totalPageViews}</div>
                        </div>
                    </div>

                </section>

                <section className='pt-4 card'>
                    <div className='container-fluid'>
                        <div className='activity-plot-wrapper'>
                            <ViewsBarPlot data={viewdata.viewPlotData} filtertype={fitertype}/>
                        </div>
                    </div>
                </section>
                <section className='pt-4'>
                    <h6>Download Details:</h6>
                    <DownloadReportGrid data={viewdata.downloadCounts}/>
                </section>
                <section className='pt-5'>
                    <h6 className=''>Individual User Activities Details:</h6>
                    <UserView data={viewdata.individualViewDetails} />
                </section>
                <IdleTimeTracker />
                {loading ? <RaceSpinner /> : null}
                <Modal
                    content={ <ActivityHeaderDefinitions />}
                    show={openDefinitionModal}
                    modalName={"activityDashboardDefinition"}
                    modalTitle='Definitions'
                    afterClose={()=>{
                        setOpenDefinitionModal(false);
                    }}
                    contentScrollable={true}
                />
            </div>
        </div>
    );
}
