import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import ProjectDataGrid from './grid/project-data-grid';
import IdleTimeTracker from './IdleTimeTrack/IdleTimeTracker';
import NavHeader from './nav-header';

export default function ProjectAcronyms() {
    const history = useHistory();
    const [adminInfo] = useState({isAdmin: history.location.state.admin, isAppOwner: history.location.state.appOwner, otheruser:history.location.state.otheruser });

    return (
        <div>
            <NavHeader admin={adminInfo.isAdmin} appOwner={adminInfo.isAppOwner} otheruser={adminInfo.otheruser} />

            <ProjectDataGrid />
            <IdleTimeTracker />
        </div>
    );
}
