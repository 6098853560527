import React from 'react';
import ReactCountryFlag from "react-country-flag"
import utils from './app-util';
import mostRelevantNotRelevantOperation from './relevant-operation'
const valueConst = utils.createKeyFromSearchKeyWord();
function SearchItem(
    {
        reportCallback,
        parentCallback, flagcode, riskid, riskTitle, subTitle, category, country, project, lob, desc, riskLZero, riskLOne, riskLTwo, physicalLThree, severity,
        riskModified, mostRelevantList, notRelevantList, globalMR
    }) {
    function actionOnClick(e) {
        parentCallback({ type: e.target.dataset.type, id: e.target.dataset.riskid, query: sessionStorage.getItem('query'), datatype: e.target.dataset.datatype, bowtieURL: e.target.dataset.url })
    }
    function onAddToRiskChange(e) {
        reportCallback({ type: "risk", currentElement: e.currentTarget, riskid: e.currentTarget.dataset.riskid });
    }

    function onNotRelevantChange(e) {
        mostRelevantNotRelevantOperation(e, "risk", "/add_to_not_relevant", "/delete_from_not_relevant", "riskid", "not relevant");
    }

    function onMostRelevantChange(e) {
        mostRelevantNotRelevantOperation(e, "risk", "/add_to_most_relevant", "/delete_from_most_relevant", "riskid", "most relevant");
    }
    
    return (
        <div className={`card card-box-shadow risk-item search-item px-2 
        mb-1 ${country.split(' ').join('_')} ${project.split(' ').join('_')} ${lob !== '' ? lob.split(' ').join('_') : ""} 
        ${riskLZero !== '' ? riskLZero.replace(/\s|,|\//g, '_') : ""} ${riskLOne !== '' ? riskLOne.replace(/\s|,|\//g, '_') : ""} 
        ${riskLTwo !== '' ? riskLTwo.replace(/\s|,|\//g, '_') : ""} ${physicalLThree !== '' ? physicalLThree.replace(/\s|,|\//g, '_') : ""}`}
            data-id={riskid} data-country={country} data-lob={lob} data-project={project} data-modified={riskModified}
            key={`${valueConst}${riskid}`}>
            <div className="search-item-wrapper pt-3 pb-2">
                <div className="search-item-inner p-2">
                    <div className="d-flex justify-content-between search-item-content">
                        <div className="search-item-header-text-content" data-toggle="collapse" data-target={`#item-${riskid}`} aria-expanded="false" aria-controls={riskid}>
                            <h6 className="header-text" title={riskTitle}>{riskTitle}</h6>
                            <p className="small sub-header-text">{subTitle}</p>
                            <br />
                        </div>
                        <div className="search-item-control-content">
                            <div className="search-item-report-controls">
                                <form>
                                    <div className="form-group form-check mb-0 addreportwrapper">
                                        <input type="checkbox" className="form-check-input addreport" data-risktitle={riskTitle}
                                            onChange={onAddToRiskChange} data-riskid={riskid} id={`addReport${riskid}`} />
                                        <div className='Toastify__spinner checkbox-spinner'></div>
                                        <label className="form-check-label" htmlFor={`addReport${riskid}`}>Add to Report</label></div>
                                    <div className='relevant-field'>
                                    <div className="form-group form-check mb-0">
                                        <input type="checkbox" className="form-check-input mostrelevant"
                                            onChange={onMostRelevantChange} data-riskid={riskid} id={`mostRelevant${riskid}`}
                                            defaultChecked={mostRelevantList.includes(riskid)} disabled={globalMR.includes(riskid) ? true : false}/>
                                        <div className='Toastify__spinner checkbox-spinner'></div>
                                        <label className="form-check-label" htmlFor={`mostRelevant${riskid}`}>Most Relevant</label></div>
                                    <div className="form-group form-check mb-0">
                                        <input type="checkbox" className="form-check-input notrelevant"
                                            onChange={onNotRelevantChange} data-riskid={riskid} id={`notRelevant${riskid}`} defaultChecked={notRelevantList.includes(riskid)} />
                                        <div className='Toastify__spinner checkbox-spinner'></div>
                                        <label className="form-check-label" htmlFor={`notRelevant${riskid}`}>Not Relevant</label></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="search-item-footer d-flex align-items-center no-gutters">
                        <div className="col content-l">
                            <div className="">
                                <div className="d-inline-block">{(country !== "" ? <ReactCountryFlag countryCode={flagcode} svg /> : <img alt="" src={utils.logo}
                                    style={{ backgroundColor: "transparent", width: "18px" }} />)}<span>&nbsp;{country}</span></div><span className="verticalbar mx-2"></span>
                                <span>Project Name: {project}</span><span className="verticalbar mx-2"></span>
                                <span>Category: {category}</span><span className="verticalbar mx-2"></span><span>Severity: {severity}</span>
                            </div>
                        </div>
                        <div className="col-4 col-md-3 content-r">
                            <div className="d-flex justify-content-end">
                                {/* Action renamed to Bowtie, if want to revert to the Action feature remove the if clause and callback in mainpage.js  */}
                                <button className="btn btn-sm action-bowtie" type="button" data-riskid={riskid} data-type="Action" data-url="get_bow_tie" data-datatype={'risk'} onClick={actionOnClick}>Bowtie</button>

                                <div className='btn-group associations-btns ml-1'>
                                    <button className='btn btn-sm dropdown-toggle' type='button' data-toggle="dropdown" aria-expanded="false">Associations</button>
                                    <div className="dropdown-menu dropdown-menu-sm-right">
                                        <button className="dropdown-item" type="button" data-riskid={riskid} data-datatype={'risk'}
                                            data-type="Associated Learning"  onClick={actionOnClick}>Associated Learnings</button>
                                        <button className="dropdown-item" type="button" data-riskid={riskid} data-datatype={'risk'}
                                            data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button>
                                        <button className="dropdown-item" type="button" data-riskid={riskid} data-datatype={'risk'}
                                            data-type="Associated Quality" onClick={actionOnClick}>Associated Quality</button>
                                    </div>
                                </div>

                                {/* <button className="btn btn-sm ml-2" data-riskid={riskid} data-datatype={'risk'}
                                    data-type="Associated Learning" onClick={actionOnClick}>Associated Learnings</button>
                                <button className="btn btn-sm ml-2" data-riskid={riskid} data-datatype={'risk'}
                                    data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button>
                                <button className="btn btn-sm ml-2" data-riskid={riskid} data-datatype={'risk'}
                                    data-type="Associated Quality" onClick={actionOnClick}>Associated Quality</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-content collapse" id={`item-${riskid}`}>
                    <p className="pt-5 px-2 pb-2 mb-0 text-justify">{desc}</p>
                </div>
            </div>
        </div>
    );
}
export default SearchItem;
