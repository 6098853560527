import utils from './app-util';
import { updateTECOPCount, getTheListIfTECOPOn, getTheListIfAdvanceSearchOn } from './filter-utils';
import { getTheListIfDropDownFilterOn } from './qualityComponent/QualityFilterUtils';

function displyCard(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}
function hideCard(element) {
    element.classList.add('d-none');
    element.classList.remove('d-block');
}
function getdateFilterResult(dateRange, cardElementLists) {
    let count = 0;
    cardElementLists.forEach(function (element) {
        if (utils.isBetweenDate(parseInt(element.dataset.modified, 10), dateRange[0].getTime(), dateRange[1].getTime())) {
            count += 1;
            displyCard(element);
        } else {
            hideCard(element);
        }

    });
    return count;
}
function getYearFilterResult(dateRange, cardElementLists) {
    let count = 0;
    cardElementLists.forEach(function (element) {
        const t = new Date(parseInt(element.dataset.modified, 10)).getFullYear()
        if (t === parseInt(dateRange, 10)) {
            count += 1;
            displyCard(element);
        } else {
            hideCard(element);
        }
    });
    return count;
}
function riskDateFilterOnCall(dateRange, filterCountsAndStatus, setFilterCountsAndStatus) {
    const allCards = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
    const taggedElem = document.querySelectorAll(".risk-sidebar .filter-list input:checked");
    const advSearchElems = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
    let count = 0;
    if (filterCountsAndStatus.isAdvancedTECOPSearchOn) {
        //Do something (e.g clear the TECOP filter, heatmapfilter, Advanced dropdown filter)
    }
    if (typeof dateRange === 'object') {
        if (taggedElem.length > 0) {
            const tecopCards = getTheListIfTECOPOn(taggedElem, allCards);
            count = getdateFilterResult(dateRange, tecopCards);
        }
        // If advance filter is active then filter based on that
        else if (advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, allCards);
            count = getdateFilterResult(dateRange, advSearchCards);
        }
        else {
            count = getdateFilterResult(dateRange, allCards);
        }
    } else if (typeof dateRange === 'string') {
        if (taggedElem.length > 0) {
            const tecopCards = getTheListIfTECOPOn(taggedElem, allCards);
            count = getYearFilterResult(dateRange, tecopCards);
        }
        // If advance filter is active then filter based on that
        else if (advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, allCards);
            count = getYearFilterResult(dateRange, advSearchCards);
        }
        else {
            count = getYearFilterResult(dateRange, allCards);
        }
    }
    /**
     * Use this statement if Functional count need to be updated on date filter
     * const risk_functional_count = updateTECOPCount();
     * setFilterCountsAndStatus(filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count, riskFunctionalCount: risk_functional_count }));
     */
    const listOnDisplay = document.querySelectorAll(".search-results-inner .card.search-item.d-block");
    const risk_functional_count = updateTECOPCount(listOnDisplay);
    setFilterCountsAndStatus(_filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count, riskFunctionalCount: risk_functional_count }));
    document.querySelector('.refresh-plots-for-filter-cost').click();
    document.querySelector('.refresh-plots-for-filter-schedule').click();
    document.querySelector('.refresh-plots-for-filter-probability').click();
    document.querySelector('.refresh-plots-for-filter-probability').click();
    document.querySelector('.heatmap-refresh-btn').click();
}

function emocDateFilterOnCall(dateRange, setFilterCountEmoc) {
    const emocCards = document.querySelectorAll('.emoc-item');
    const taggedElem = document.querySelectorAll(".emoc-sidebar .filter-list input:checked");
    const advSearchElems = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
    let count = 0;
    if (typeof dateRange === 'object') {
        if (taggedElem.length > 0) {
            const tecopCards = getTheListIfTECOPOn(taggedElem, emocCards);
            count = getdateFilterResult(dateRange, tecopCards);
        } else if (advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, emocCards);
            count = getdateFilterResult(dateRange, advSearchCards);
        } else {
            count = getdateFilterResult(dateRange, emocCards);
        }
    } else {
        if (taggedElem.length > 0) {
            const tecopCards = getTheListIfTECOPOn(taggedElem, emocCards);
            count = getYearFilterResult(dateRange, tecopCards);
        } else if (advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, emocCards);
            count = getYearFilterResult(dateRange, advSearchCards);
        } else {
            count = getYearFilterResult(dateRange, emocCards);
        }
    }
    setFilterCountEmoc(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
    document.querySelector('.refresh-plots-for-filter-emoc').click();
    document.querySelector('.refresh-plots-for-filter-emoc').click();
}
function qualityDateFilterOnCall(dateRange, setFilterCountQuality) {
    const qualityCards = document.querySelectorAll('.quality-item');
    const advSearchElems = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
    const selectedValueChipElements = document.querySelectorAll(".MuiChip-label.MuiChip-labelSmall");
    let count = 0;
    if (typeof dateRange === 'object') {
        if(selectedValueChipElements.length > 0) {
            const dropdownFiltercards = getTheListIfDropDownFilterOn(selectedValueChipElements, qualityCards);
            count = getdateFilterResult(dateRange, dropdownFiltercards);
        } else if(advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, qualityCards);
            count = getdateFilterResult(dateRange, advSearchCards);
        } else {
            count = getdateFilterResult(dateRange, qualityCards);
        }
    } else {
        if(selectedValueChipElements.length > 0) {
            const dropdownFiltercards = getTheListIfDropDownFilterOn(selectedValueChipElements, qualityCards);
            count = getYearFilterResult(dateRange, dropdownFiltercards);
        } else if(advSearchElems.length > 0) {
            const advSearchCards = getTheListIfAdvanceSearchOn(advSearchElems, qualityCards);
            count = getYearFilterResult(dateRange, advSearchCards);
        }else {
            count = getYearFilterResult(dateRange, qualityCards);
        }
    }
    setFilterCountQuality(qualityCount => ({ ...qualityCount, qualityCount: count }));
}
export { riskDateFilterOnCall, emocDateFilterOnCall, qualityDateFilterOnCall, displyCard, hideCard };
