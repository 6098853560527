import utils from './app-util'
import { getSelectedYearValue, getSelectedDateRange } from './date-filter';
import { filterEmocResult } from './emocComponent/emocUtils';
import { emptyfunctinalCount } from './filter';
import { getTheListIfDateRangeOn, getTheListIfTECOPOn, getTheListIfYearOn } from './filter-utils';
import { getTheListIfDropDownFilterOn, getVisibleElementsFilterData } from './qualityComponent/QualityFilterUtils';

function uncheckOnZero() {
    const cbox = document.querySelectorAll(utils.elementSelectors.FILTER_LIST_INP);
    cbox.forEach(element => {
        if (element.dataset.number === "0") {
            element.checked = false;
        }
    });
}

function showCountry(showC, showL, dropdownFilters) {
    showC.forEach(function (value) {
        if (showL.includes(value)) {
            dropdownFilters.dropdownFilter.push(value);
        }
    });
    // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
    dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
    // dropdownFilter2: We are going to fill the project dropdown based on the Countries selected
    dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
}

function lobsOperation(data, dropdownFilters, showC, showL, showAll) {
    // When lob dropdown is selected, check if the any country filter is applied
    // if the country filter is applied then use country to filter out projects from it
    if (data.lobs.length === 0) {
        if (data.countries.length !== 0) {
            showCountry(showC, showL, dropdownFilters);
        }
        else {
            // Else part when the countries length in 0
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showL);
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
        }
    }
    // Else part has the LoB filter (length > 0 for LoB), so we apply
    // the LoB intersection with the countries
    else {
        if (data.countries.length !== 0) {
            showCountry(showC, showL, dropdownFilters);
        }
        else {
            // Else part when the countries length is 0
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showAll);
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter1;
        }
    }
}

function ifCountry0OnLobProjectNot0(data, dropdownFilters, showC, showL, showP, showAll) {
    if (data.lobs.length !== 0) {
        if (data.projects.length !== 0) {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showP)
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
        }
        else {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showC)
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter1;
        }
    }
    else {
        if (data.projects.length !== 0) {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showP)
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = utils.removeDuplicate(showAll);
        }
        else {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showAll)
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
        }
    }
}
function countryVariableUpdate(showC, showL, dropdownFilters) {
    showC.forEach(function (value) {
        if (showL.includes(value)) {
            dropdownFilters.dropdownFilter2.push(value);
        }
    });
}
function countryOperation(data, dropdownFilters, showC, showL, showP, showAll, show) {
    if (data.countries.length === 0) {
        ifCountry0OnLobProjectNot0(data, dropdownFilters, showC, showL, showP, showAll);
    }
    else {
        if (data.lobs.length !== 0) {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showC);
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            countryVariableUpdate(showC, showL, dropdownFilters);
        }
        else {
            if (data.projects.length !== 0) {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showP);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = utils.removeDuplicate(showC);
            }
            else {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showC);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = utils.removeDuplicate(show);
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter1;
            }
        }
    }
}
function projectOperation(data, dropdownFilters, showC, showL, showP) {
    if (data.countries.length === 0) {
        if (data.lobs.length !== 0) {
            if (data.projects.length === 0) {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showL);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
            } else {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showP);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
            }
        }
        else {
            dropdownFilters.dropdownFilter = utils.removeDuplicate(showP);
            // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
            dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
            // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
            dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
        }
    }
    else {
        if (data.lobs.length !== 0) {
            if (data.projects.length !== 0) {
                showP.forEach(function (value) {
                    if (showC.includes(value)) {
                        dropdownFilters.dropdownFilter.push(value);
                    }
                });
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                countryVariableUpdate(showC, showL, dropdownFilters);
            } else {
                showCountry(showC, showL, dropdownFilters);
            }
        }
        else {
            if (data.projects.length !== 0) {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showP);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = dropdownFilters.dropdownFilter;
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = utils.removeDuplicate(showC);
            } else {
                dropdownFilters.dropdownFilter = utils.removeDuplicate(showC);
                // dropdownFilter1: We are going to fill the country dropdown based on the LoB selected
                dropdownFilters.dropdownFilter1 = utils.removeDuplicate(showL);
                // dropdownFilter2: We are going to fill the project dropdown based on the LoB selected
                dropdownFilters.dropdownFilter2 = dropdownFilters.dropdownFilter;
            }
        }
    }
}
function populateLCPDropdownValues(data, dropdownFilters, showL, showC, showP, showAll, show) {
    if (data.selectedDropdown === 'lobs') {
        lobsOperation(data, dropdownFilters, showC, showL, showAll);
    } else if (data.selectedDropdown === 'countries') {
        countryOperation(data, dropdownFilters, showC, showL, showP, showAll, show);
    } else if (data.selectedDropdown === 'projects') {
        projectOperation(data, dropdownFilters, showC, showL, showP);
    }
}
/**
 * Filter the result based on filters like TECOP, Date and Year
 * @param {String} dataSelectionType - Data selection type
 * @param {NodeList} functinalTagSelected - Checked input checkboxes from Functional TECOP category
 * @param {NodeList} originalList - Total search result list
 * @param {String} listOnDisplaySelector - Current search result on disaply
 * @param {Function} setFilterValue - Set the state of TECOP value (This may be removed from this method)
 * @returns {NodeList} returns filtered list based on the filters applied
 */
function getFilteredList(dataSelectionType, functinalTagSelected, originalList, listOnDisplaySelector, setFilterValue) {
    let filteredList = [];
    const selectedYearValue = getSelectedYearValue(dataSelectionType);
    const selectedDateRangeValue = getSelectedDateRange(dataSelectionType);
    if (functinalTagSelected.length > 0) {
        if (dataSelectionType === 'risk') {
            setFilterValue(null, true);
        }
        const listOnDisplay = document.querySelectorAll(listOnDisplaySelector);
        filteredList = getTheListIfTECOPOn(functinalTagSelected, listOnDisplay);
    } else if (selectedYearValue !== "") {
        filteredList = getTheListIfYearOn(selectedYearValue, originalList);
    } else if (selectedDateRangeValue.length > 0) {
        filteredList = getTheListIfDateRangeOn(selectedDateRangeValue, originalList);
    } else {
        filteredList = originalList;
    }
    return filteredList;
}
function advancedSearch(data, filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList, isCalledFromOnHeatmapClick_ClearAll=false) {
    setFilterCountsAndStatus(filterCountsAndStatuss => ({ ...filterCountsAndStatuss, isAdvancedTECOPSearchOn: true }));
    if (filterCountsAndStatus.isHeatmapFilterOn) {
        utils.clearHeatMapFilter();
    }

    setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, isHeatmapFilterOn: false }));
    const list = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
    let mainFilteredList = [];
    const inplist = document.querySelectorAll(utils.elementSelectors.FILTER_LIST_INP + ":checked");

    if (data.lobs.length === 0 && data.countries.length === 0 && data.projects.length === 0 && !isCalledFromOnHeatmapClick_ClearAll) {
        setFilterValue(null);
    }
    mainFilteredList = getFilteredList("risk", inplist, list, ".search-results-inner .card.search-item.d-block", setFilterValue);
    populateDropdown(data, mainFilteredList, list, updateDropdownList, setFilterCountsAndStatus);
    if(!isCalledFromOnHeatmapClick_ClearAll){
        setTimeout(() => {
            document.querySelector('.refresh-plots-for-filter-cost').click();
            document.querySelector('.refresh-plots-for-filter-schedule').click();
            document.querySelector('.refresh-plots-for-filter-probability').click();
            document.querySelector('.refresh-plots-for-filter-probability').click();
            document.querySelector('.heatmap-refresh-btn').click();
        }, 2000);
    }
}

function populateDropdown(data, mainlist, list, updateDropdownList, setFilterCountsAndStatus, iseMoC = false, isQuality=false) {
    let count = 0;
    // This will give us all the cards, required to change the dropdown box options
    const showAll = [];
    list.forEach(element => {
        showAll.push(element.dataset.id);
    });

    const show = [], showL = [], showC = [], showP = [];
    mainlist.forEach(function (element) {
        element.classList.remove("d-none");
        if (data.lobs.length === 0) {
            showL.push(element.dataset.id);
        } else {
            for (let j = 0; j < data.lobs.length; ++j) {
                if (utils.hasClass(element, data.lobs[j])) {
                    showL.push(element.dataset.id);
                }
            }
        }

        if (data.countries.length === 0) {
            showC.push(element.dataset.id);
        } else {
            for (let i = 0; i < data.countries.length; ++i) {
                if (utils.hasClass(element, data.countries[i])) {
                    showC.push(element.dataset.id);
                }
            }
        }

        if (data.projects.length === 0) {
            showP.push(element.dataset.id);
        } else {
            for (let j = 0; j < data.projects.length; ++j) {
                if (utils.hasClass(element, data.projects[j])) {
                    showP.push(element.dataset.id);
                }
            }
        }
    });

    showL.forEach(function (value) {
        if (showC.includes(value) && showP.includes(value)) {
            show.push(value);
        }
    });

    // This is used for populating the drop down boxes for the Lobs, countries, projects
    const dropdownFilters = { dropdownFilter: [], dropdownFilter1: [], dropdownFilter2: [] };
    populateLCPDropdownValues(data, dropdownFilters, showL, showC, showP, showAll, show);
    const refined = utils.removeDuplicate(show);
    const lobss = [], countriess = [], projectss = [];
    const updateDropdown = { lobss: [], countriess: [], projectss: [] };
    const risk_functional_count = JSON.parse(JSON.stringify(emptyfunctinalCount));
    if (refined.length > 0) {
        for (let i = 0; i < mainlist.length; ++i) {
            if (refined.includes(mainlist[i].dataset.id)) {
                mainlist[i].classList.add('d-block');
                mainlist[i].classList.remove('d-none');


                if (!isQuality && (data.lobs.length !== 0 || data.projects.length !== 0 || data.countries.length !== 0)) {
                    for (const key in risk_functional_count) {
                        if (utils.hasClass(mainlist[i], key.replace(/\s|,|\//g, '_'))) {
                            risk_functional_count[key] = risk_functional_count[key] + 1
                        }
                    }
                }
                
                count += 1
                if (data.selectedDropdown === '') {
                    lobss.push(mainlist[i].dataset.lob);
                    countriess.push(mainlist[i].dataset.country);
                    projectss.push(mainlist[i].dataset.project);
                }
            } else {
                mainlist[i].classList.remove('d-block');
                mainlist[i].classList.add('d-none');
            }

            if (data.lobs.length === 0 && data.projects.length === 0 && data.countries.length === 0) {
                lobss.push(mainlist[i].dataset.lob);
                countriess.push(mainlist[i].dataset.country);
                projectss.push(mainlist[i].dataset.project);
            }
            if (dropdownFilters.dropdownFilter.includes(mainlist[i].dataset.id)) {
                lobss.push(mainlist[i].dataset.lob);
            }

            if (dropdownFilters.dropdownFilter1.includes(mainlist[i].dataset.id)) {
                countriess.push(mainlist[i].dataset.country);
            }

            if (dropdownFilters.dropdownFilter2.includes(mainlist[i].dataset.id)) {
                projectss.push(mainlist[i].dataset.project);
            }
        }
        if(!isQuality){
            if (data.lobs.length === 0 && data.projects.length === 0 && data.countries.length === 0) {
                mainlist.forEach(function (element) {
                    for (const key in risk_functional_count) {
                        if (utils.hasClass(element, key.replace(/\s|,|\//g, '_'))) {
                            risk_functional_count[key] = risk_functional_count[key] + 1
                        }
                    }
                })
            }
        


            setTimeout(() => {
                uncheckOnZero();
            }, 500);
        }
        if (iseMoC) {
            setFilterCountsAndStatus(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
        } else if(isQuality) {
            setFilterCountsAndStatus(filterCountQuality => ({ ...filterCountQuality, qualityCount: count }));
        }else {
            setFilterCountsAndStatus(filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count, riskFunctionalCount: risk_functional_count }));
        }
        updateDropdown.countriess = utils.removeDuplicate(countriess);
        updateDropdown.projectss = utils.removeDuplicate(projectss);
        updateDropdown.lobss = utils.removeDuplicate(lobss);
        updateDropdownList(data.selectedDropdown, updateDropdown);
    } else {
        if (data.lobs.length === 0 && data.countries.length === 0 && data.projects.length === 0) {
            mainlist.forEach(function (element) {
                element.classList.remove("d-none");
                element.classList.add("d-block");
                lobss.push(element.dataset.lob);
                countriess.push(element.dataset.country);
                projectss.push(element.dataset.project);
            });

            updateDropdown.countriess = utils.removeDuplicate(countriess);
            updateDropdown.projectss = utils.removeDuplicate(projectss);
            updateDropdown.lobss = utils.removeDuplicate(lobss);
            updateDropdownList(data.selectedDropdown, updateDropdown);
        } else {
            if (iseMoC) {
                setFilterCountsAndStatus(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
            } else if(isQuality) {
                setFilterCountsAndStatus(filterCountQuality => ({ ...filterCountQuality, qualityCount: count }));
            }else {
                setFilterCountsAndStatus(filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count, riskFunctionalCount: risk_functional_count }));
            }
            mainlist.forEach(function (element) {
                element.classList.remove('d-block');
                element.classList.add('d-none');
            });
            for (let i = 0; i < mainlist.length; ++i) {
                if (data.lobs.length === 0 && data.projects.length === 0 && data.countries.length === 0) {
                    lobss.push(mainlist[i].dataset.lob)
                    countriess.push(mainlist[i].dataset.country)
                    projectss.push(mainlist[i].dataset.project)
                }
                if (dropdownFilters.dropdownFilter.includes(mainlist[i].dataset.id)) {
                    lobss.push(mainlist[i].dataset.lob)
                }

                if (dropdownFilters.dropdownFilter1.includes(mainlist[i].dataset.id)) {
                    countriess.push(mainlist[i].dataset.country)
                }

                if (dropdownFilters.dropdownFilter2.includes(mainlist[i].dataset.id)) {
                    projectss.push(mainlist[i].dataset.project)
                }
            }

            updateDropdown.countriess = utils.removeDuplicate(countriess);
            updateDropdown.projectss = utils.removeDuplicate(projectss);
            updateDropdown.lobss = utils.removeDuplicate(lobss);
            updateDropdownList(data.selectedDropdown, updateDropdown);
        }
    }
}

function eMoCAdvanceSearch(data, _filterCountEmoc, setFilterCountEmoc, updateDropdownList) {
    const list = document.querySelectorAll(".emoc-item");
    let mainlist = [];
    const inplist = document.querySelectorAll(".emoc-sidebar .filter-list input:checked");
    /**
     *  Use for TECOP
     *  if (data.lobs.length === 0 && data.countries.length === 0 && data.projects.length === 0) {
     *      setFilterValue(null);
     *  }
     *
     */
    mainlist = getFilteredList("emoc", inplist, list, ".emoc-item", setFilterCountEmoc)
    populateDropdown(data, mainlist, list, updateDropdownList, setFilterCountEmoc, true);
    
    document.querySelector('.refresh-plots-for-filter-emoc').click();
    document.querySelector('.refresh-plots-for-filter-emoc').click();
}
function eMoCFunctionalTagClearFilter(_filterCountEmoc, setFilterCountEmoc, _setFilterValue, _updateDropdownList) {
    uncheckTECOPIfCheckedIndeterminate();
    filterEmocResult(setFilterCountEmoc)
}
function uncheckTECOPIfCheckedIndeterminate() {
    const list = document.querySelectorAll(".emoc-sidebar .filter-list input");
    list.forEach(function (element) {
        if (element.checked || element.indeterminate) {
            element.indeterminate = false;
            element.checked = false;
        }
    });
}

function getQualityFilteredList(dataSelectionType, dropdownFiltersSelected, originalList, listOnDisplaySelector, setFilterValue) {
    let filteredList = [];
    const selectedYearValue = getSelectedYearValue(dataSelectionType);
    const selectedDateRangeValue = getSelectedDateRange(dataSelectionType);
    if (dropdownFiltersSelected.length > 0) {
        const listOnDisplay = document.querySelectorAll(listOnDisplaySelector);
        filteredList = getTheListIfDropDownFilterOn(dropdownFiltersSelected, listOnDisplay);
    } else if (selectedYearValue !== "") {
        filteredList = getTheListIfYearOn(selectedYearValue, originalList);
    } else if (selectedDateRangeValue.length > 0) {
        filteredList = getTheListIfDateRangeOn(selectedDateRangeValue, originalList);
    } else {
        filteredList = originalList;
    }
    return filteredList;
}

function qualityAdvancedSearch(data, _filterCountQuality, setFilterCountQuality, updateDropdownList, setQualityFilterData) {
    const mainlist = document.querySelectorAll(".quality-item");
    const selectedValueChipElements = document.querySelectorAll(".MuiChip-label.MuiChip-labelSmall");
    const filteredList = getQualityFilteredList('quality', selectedValueChipElements, mainlist, ".quality-item", setFilterCountQuality)
    populateDropdown(data, filteredList, mainlist, updateDropdownList, setFilterCountQuality, false, true);
    setTimeout(() => {
        setQualityFilterData(getVisibleElementsFilterData());
        document.querySelector('.refresh-plots-for-filter-quality').click();
        document.querySelector('.refresh-plots-for-filter-quality').click();
    }, 1000);
}

export { advancedSearch, eMoCAdvanceSearch, eMoCFunctionalTagClearFilter, qualityAdvancedSearch };
