import React from "react";

class AcronymCellFormatter extends React.Component {

    render() {
        return (
            <>
                {
                    Object.keys(this.props.content).length > 0 ? Object.keys(this.props.content).map((acronym, _index) => {
                        return <span key={`acr${acronym}`}><span  className={`badge badge-acronym`}>{acronym}</span><span className={`grid-acronym project-acronym-case ${this.props.content[acronym] ? "case-sensitive":""}`}></span></span>;
                    }) : null
                }
            </>
        );
    }
}
export default AcronymCellFormatter;
