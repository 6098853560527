import React, { useEffect, useState } from 'react';
import DoughNut from './doughtnutChart';
import mostRelevantNotRelevantOperation from '../relevant-operation';
import GenerateReport from '../generate-report';
import summaryDescription from '../summaryDescription';
import { doughnutFilterOnCall, hzBarFilterOnCall, stackedBarFilterOnCall } from './emocPlotFilter';
import HorizontalBarChart from './horizontalBarChart';
import { resetEmocFilter } from './emocUtils';
import PlotMenu from '../plot-menu';
import alertMessage from '../alert';
import { filterLoader, loadFilteredDataForEMoCPlots, formatGraphNegativeData } from '../filter-utils';
import StackedBarchartNegative from './stackedbarplotNegative';
import utils from '../app-util';
import plotInformation from '../data/plotInformation.json';

function emocMostRelevantOnChange(e) {
    mostRelevantNotRelevantOperation(e, "emoc", "/add_to_most_relevant", "/delete_from_most_relevant", "emocid", "most relevant");
}
function emocNotRelevantOnChange(e) {
    mostRelevantNotRelevantOperation(e, "emoc", "/add_to_not_relevant", "/delete_from_not_relevant", "emocid", "not relevant");
}
const valueConst = utils.createKeyFromSearchKeyWord();
const useStateUpdate = (props, setPlotdata, setFilterCount) => {
    useEffect(() => {
        // setTimeout(() => {
            setPlotdata({
                "pie_changes_plot": props.data["pie_changes_plot"],
                "pie_trends_plot": props.data["pie_trends_plot"],
                "moc_big_rule": props.data["moc_big_rule"],
                "cost": [formatGraphNegativeData(props.data["cost_graph"], Object.keys(props.data["cost_graph"].data.pos), "pos", "emoc"), formatGraphNegativeData(props.data["cost_graph"], Object.keys(props.data["cost_graph"].data.neg), "neg", "emoc")]
            })
        // }, 1000);
        setFilterCount(props.filterCount);
        return ()=>{
            props.data.pie_trends_plot.isNew = false;
            props.data.pie_changes_plot.isNew = false;
            props.data.moc_big_rule.isNew = false;
        }
    }, [props, setPlotdata, setFilterCount]);
  };
function Emoc(props) {
    const [generateReportData, setGenerateReportData] = useState({ checked: false, id: "", title: "", type: "emoc" });
    const [filterCount, setFilterCount] = useState(props.filterCount);
    const [plotdata, setPlotdata] = useState({
        "pie_changes_plot": props.data["pie_changes_plot"],
        "pie_trends_plot": props.data["pie_trends_plot"],
        "moc_big_rule": props.data["moc_big_rule"],
        "cost": [formatGraphNegativeData(props.data["cost_graph"], Object.keys(props.data["cost_graph"].data.pos), "pos", "emoc"), formatGraphNegativeData(props.data["cost_graph"], Object.keys(props.data["cost_graph"].data.neg), "neg", "emoc")]
    });
    useStateUpdate(props, setPlotdata, setFilterCount);
    useEffect(() => {
        document.querySelector('.clearall-emoc').removeEventListener('click', emocClearAll);// In case the the event handler exist
        document.querySelector('.clearall-emoc').addEventListener('click', emocClearAll);
        document.querySelector('.refresh-plots-for-filter-emoc').removeEventListener('click', onMoCBigRulePlotFilter);
        document.querySelector('.refresh-plots-for-filter-emoc').addEventListener('click', onMoCBigRulePlotFilter);
        document.querySelector(".emoc-graph-analysis .emoc-tab-wrapper .nav-item a[aria-controls='contributingfactor']").removeEventListener("click", eMoCgraphTabContainerOnChange);
        document.querySelector(".emoc-graph-analysis .emoc-tab-wrapper .nav-item a[aria-controls='contributingfactor']").addEventListener("click", eMoCgraphTabContainerOnChange);
        document.querySelector(".emoc-graph-analysis .emoc-tab-wrapper .nav-item a[aria-controls='bigrule']").removeEventListener("click", eMoCgraphTabContainerOnChange);
        document.querySelector(".emoc-graph-analysis .emoc-tab-wrapper .nav-item a[aria-controls='bigrule']").addEventListener("click", eMoCgraphTabContainerOnChange);
    }, []);
    function eMoCgraphTabContainerOnChange() {
        const activeFilter = document.querySelector('.emoc-graph-analysis .emoc-tab-wrapper .tab-content svg.clickactive');
        if(activeFilter !== null) {
            activeFilter.classList.remove('clickactive');
            // This will work for both plot since the call is similar
            doughnutFilterOnCall({ids: '', isFilter: false}, setFilterCount);
        }
    }
    function onMoCBigRulePlotFilter() {
        loadFilteredDataForEMoCPlots("get-filtered-emoc-plots", setPlotdata);
    }
    function emocClearAll(e) {
        e.preventDefault();
        document.querySelector(".date-filter-wrapper.emoc #sinceyearemoc").selectedIndex = 0;
        document.querySelector(".date-filter-wrapper.emoc .react-daterange-picker__clear-button").click();
    }
    function emocAddToReportOnChange(e) {
        setGenerateReportData({ checked: true, id: e.target.dataset.emocid, title: e.target.dataset.emoctitle, type: "emoc" });
    }
    function doughnutChartFilterOnCall(data) {
        doughnutFilterOnCall(data, setFilterCount);
    }
    function hzBarChartFilterOnCall(data) {
        hzBarFilterOnCall(data, setFilterCount);
    }
    function stackedBarChartFilterOnCall(data) {
        stackedBarFilterOnCall(data, setFilterCount);
    }
    
    function piePlotClearOnClick(_e) {
        const mocPie = document.getElementById("mocPie");
        if (mocPie.classList.contains("clickactive")) {
            mocPie.classList.remove("clickactive");
            // Changed to check other filter if applied after clearing. OLD Method Used here: resetEmocFilter(setFilterCount);
            doughnutFilterOnCall({ ids: '', isFilter: false }, setFilterCount);
        }
    }
    function hzBarPlotClearOnClick(_e) {
        const mocBigRule = document.getElementById("mocBigRule");
        if (mocBigRule.classList.contains("clickactive")) {
            mocBigRule.classList.remove("clickactive");
            // Changed to check other filter if applied after clearing. OLD Method Used here: resetEmocFilter(setFilterCount);
            hzBarFilterOnCall({ ids: '', isFilterActive: false }, setFilterCount);
        }
    }
    return (
        <div className='emoc-container'>
            <div className='emoc-graph-analysis mt-3'>
                <div className='card card-box-shadow '>
                    <div className="emoc-tab-wrapper px-3 pb-3 pt-1">
                        <ul className="nav nav-tabs" id="emocTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="contributingfactorTab"
                                    data-toggle="tab" href="#contributingfactor" role="tab" aria-controls="contributingfactor" aria-selected="true">Contributing Factors</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="bigruleTab"
                                    data-toggle="tab" href="#bigrule" role="tab" aria-controls="bigrule" aria-selected="false">MoC Big Rule</a>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                <a className="nav-link" id="mocCostTab"
                                    data-toggle="tab" href="#moccost" role="tab" aria-controls="moccost" aria-selected="false">MoC Cost</a>
                            </li> */}
                        </ul>

                        <div className="tab-content border-bottom pb-4" id="emocTabContent">
                            { filterLoader('emoc-filter-plot-loader') }
                            <div className="tab-pane fade graph show active" id="contributingfactor" role="tabpanel"
                                aria-labelledby="contributingfactor-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="pie-c-btn" fullscreenSelector={"contributingfactor"}
                                        clearOnClick={piePlotClearOnClick} info={plotInformation.eMoC.contributingfactors}/>
                                    <DoughNut trends={plotdata["pie_trends_plot"]} changes={plotdata["pie_changes_plot"]} filterOnCall={doughnutChartFilterOnCall}/>
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="bigrule" role="tabpanel" aria-labelledby="bigrule-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="h-bar-c-btn" fullscreenSelector={"bigrule"}
                                        clearOnClick={hzBarPlotClearOnClick} info={plotInformation.eMoC.mocbigrule} />
                                    <HorizontalBarChart data={plotdata["moc_big_rule"]} filterOnCall={hzBarChartFilterOnCall} />
                                </div>
                            </div>
                            {/* <div className="tab-pane fade graph" id="moccost" role="tabpanel" aria-labelledby="moccost-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="moc-cost-bar-c-btn" fullscreenSelector={"moccost"}
                                        clearOnClick={null} info={plotInformation.eMoC.moccost}/>
                                    <StackedBarchartNegative key={'moccost'} data={plotdata["cost"]} stackedBarChartFIlterCallback={stackedBarChartFilterOnCall} dataType={'emoc'} type={"moccost"} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='emoc-results p-2'>
                {alertMessage(props.data.n_exact_results, props.data.emoc_id.length, "MoC")}
                <div className="d-flex justify-content-between mt-3 mb-2 small">
                    <div className="text-monospace">Showing top&nbsp;
                        <span className="emoc-result-count">
                            {filterCount}
                        </span> result(s)<span></span></div>
                    <div>
                        <GenerateReport data={generateReportData} type={'emoc'} />
                    </div>
                </div>
                <div className='emoc-results-wrapper'><div className='search-results-inner'>
                    {
                        props.data !== null && props.data.emocFormatted.length > 0 ? props.data.emocFormatted.map((emoc) => {
                            return (
                                <div className={`emoc-item card px-2 mb-3 rounded-0 border-0 
                                ${Array.isArray(emoc.emoc_Functional_L0) ? emoc.emoc_Functional_L0.join(' ') : emoc.emoc_Functional_L0} 
                                ${emoc.emoc_loc.split(' ').join('_')} ${emoc.lob} ${emoc.emoc_org.split(' ').join('_')}`}
                                    key={`${valueConst}${emoc.emoc_id}`}
                                    data-id={emoc.emoc_id} data-modified={emoc.emoc_created_modified_date}
                                    data-country={emoc.emoc_loc} data-lob={emoc.lob} data-project={emoc.emoc_org}>
                                    <div className='emoc-item-intro d-flex justify-content-between'>
                                        <div className='emoc-titles p-1' data-toggle="collapse"
                                            data-target={`#emoc${emoc.emoc_id}`}
                                            aria-expanded="false" aria-controls={`#emoc${emoc.emoc_id}`}>
                                            <h6 className='main-title' title={emoc.emoc_title}>{emoc.emoc_title}</h6>
                                            <p className='sub-title small'>{emoc.emoc_desc.slice(0, 130)}</p>
                                        </div>
                                        <div className='activity-control'>
                                            <div className="emoc-item-control-content">
                                                <div className="emoc-item-report-controls">
                                                    <form>
                                                        <div className="form-group form-check mb-0">
                                                            <input type="checkbox" className="form-check-input addreport" data-emoctitle={emoc.emoc_title}
                                                                onChange={emocAddToReportOnChange} data-emocid={emoc.emoc_id} id={`addReportEmoc${emoc.emoc_id}`} />
                                                            <div className='Toastify__spinner checkbox-spinner'></div>
                                                            <label className="form-check-label" htmlFor={`addReportEmoc${emoc.emoc_id}`}>Add to Report</label></div>
                                                        <div className="form-group form-check mb-0">
                                                            <input type="checkbox" className="form-check-input mostrelevant"
                                                                onChange={emocMostRelevantOnChange} data-emocid={emoc.emoc_id} id={`mostRelevantEmoc${emoc.emoc_id}`}
                                                                defaultChecked={props.data.most_relevant.includes(emoc.emoc_id)} disabled={props.data.global_most_relevant.includes(emoc.emoc_id) ? true : false}/>
                                                            <div className='Toastify__spinner checkbox-spinner'></div>
                                                            <label className="form-check-label" htmlFor={`mostRelevantEmoc${emoc.emoc_id}`}>Most Relevant</label></div>
                                                        <div className="form-group form-check mb-0">
                                                            <input type="checkbox" className="form-check-input notrelevant"
                                                                onChange={emocNotRelevantOnChange} data-emocid={emoc.emoc_id} id={`notRelevantEmoc${emoc.emoc_id}`}
                                                                defaultChecked={props.data.non_relevant.includes(emoc.emoc_id)} />
                                                            <div className='Toastify__spinner checkbox-spinner'></div>
                                                            <label className="form-check-label" htmlFor={`notRelevantEmoc${emoc.emoc_id}`}>Not Relevant</label></div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='emoc-footer small p-1' style={{ fontSize: '10px' }}>
                                        <span className='project-tag'>Project Name: {emoc.emoc_org.trim()}</span><span className="verticalbar mx-2"></span>
                                        <span className='moc-tag'>Mode Of Change: {emoc.emoc_mode_of_change}</span><span className="verticalbar mx-2"></span>
                                        <span className='moc-tag'>Category: {Array.isArray(emoc.emoc_Functional_L0) ? emoc.emoc_Functional_L0.join(', ') : 'N/A'}</span>
                                    </div>
                                    <div className="emoc-description collapse" id={`emoc${emoc.emoc_id}`}>
                                        {/* <p className="pt-3 px-1 pb-2 mb-0 text-justify">{emoc.emoc_desc}</p> */}
                                        {/* --------Accordion start-------- */}
                                        {summaryDescription("emoc", emoc.emoc_id, emoc.emoc_desc_summary, emoc.emoc_desc)}
                                        {/* --------Accordion End-------- */}
                                    </div>
                                </div>
                            );
                        })
                            : null
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default React.memo(Emoc);
