import utils from './app-util';
import { plotFilterOnDeselect, resetSearchResultOnPlotFilterToggle } from './filter-utils';
function getFilteredCards(data, allCards, _xAxisValue, _yAxisValue, _xAxisType) {
    const filteredCards = [];
    allCards.forEach(function (element) {
        if (data.data.data_ids.includes(element.dataset.id)) {
            filteredCards.push(element.dataset.id);
        }
    });
    return filteredCards;
}
export default function heatmapFilterOnCall(data, filterCountsAndStatus, setFilterCountsAndStatus, OnHeatmapClick_ClearAll) {
    const allCards = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
    let count = 0;
    if (data !== null && data !== undefined) {
        if (filterCountsAndStatus.isAdvancedTECOPSearchOn) {
            OnHeatmapClick_ClearAll();
        }

        setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, isAdvancedTECOPSearchOn: false, isHeatmapFilterOn: true }));
        const xAxisType = data.xAxisType;
        const xAxisValue = data.data.x, yAxisValue = data.data.y;
        const filteredCards = getFilteredCards(data, allCards, xAxisValue, yAxisValue, xAxisType);

        allCards.forEach(function (element) {
            if (filteredCards.includes(element.dataset.id)) {
                count += 1;
                element.classList.remove('d-none');
                element.classList.add('d-block');
            } else {
                element.classList.add('d-none');
                element.classList.remove('d-block');
            }
        })

        setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, searchresultcount: count }));
    } else {
        count = plotFilterOnDeselect(allCards, 'risk');

        setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, searchresultcount: count }));
    }
}
