import React, { useEffect, useState } from 'react';
import GenerateReport from '../generate-report';
import ResultCard from './ResultCard';
import alertMessage from '../alert';
import mostRelevantNotRelevantOperation from '../relevant-operation';
import HeatMapQuality from './heatmapQuality';
import utils from '../app-util';
import ProcessSafetyPlot from './ProcessSafetyPlot';
import RootCausePlot from './RootCausePlot';
import MRFPlot from './MRFPlot';
import COQPlot from './COQPlot';
import CostPlot from './CostPlot';
import { qualityPlotFilterOnCall, qualityHeatmapFilterOnCall, displayAllQualityCard } from './QualityFilterUtils';
import PlotMenu from '../plot-menu';
import { loadFilteredDataForQualityPlots, filterLoader } from '../filter-utils';
import plotInformation from '../data/plotInformation.json';

function qualityAddToReportOnChange() {
    console.log('Callback');
}
function qualityMostRelevantOnChange(e) {
    mostRelevantNotRelevantOperation(e, "quality", "/add_to_most_relevant", "/delete_from_most_relevant", "id", "most relevant");
}
function qualityNotRelevantOnChange(e) {
    mostRelevantNotRelevantOperation(e, "quality", "/add_to_not_relevant", "/delete_from_not_relevant", "id", "not relevant");
}


const useUpdateData = (props, setPlotData, setQualitystate) => {
  useEffect(() => {
    setPlotData({
        "heat_map_graph": props.data.heat_map_graph,
        "cost_graph":  utils.formatGraphData(props.data.cost_graph, Object.keys(props.data.cost_graph.data), 'quality'),
        "process_safety_graph": props.data.process_safety_graph,
        "root_cause_graph": props.data.root_cause_graph,
        "coq_graph": utils.formatGraphData(props.data.coq_graph, Object.keys(props.data.coq_graph.data), 'quality'),
        "mrf_graph": props.data.mrf_graph
    });
    setQualitystate({filterCount: props.filterCount});
    // return ()=>{
    //     props.data.heat_map_graph.isNew = false;
    //     props.data.cost_graph.isNew = false;
    //     props.data.process_safety_graph.isNew = false;
    //     props.data.root_cause_graph.isNew = false;
    //     props.data.coq_graph.isNew = false;
    //     props.data.mrf_graph.isNew = false;
    // }
  }, [props, setPlotData, setQualitystate]);
};
const valueConst = utils.createKeyFromSearchKeyWord();
function Quality(props) {
    const severity = { 1: "Slight", 2: "Minor", 3: "Moderate", 4: "Major", 5: "Severe" };
    const [qualitystate, setQualitystate] = useState({filterCount: props.filterCount});
    const [plotData, setPlotData] = useState({
        "heat_map_graph": props.data.heat_map_graph,
        "cost_graph":  utils.formatGraphData(props.data.cost_graph, Object.keys(props.data.cost_graph.data), 'quality'),
        "process_safety_graph": props.data.process_safety_graph,
        "root_cause_graph": props.data.root_cause_graph,
        "coq_graph": utils.formatGraphData(props.data.coq_graph, Object.keys(props.data.coq_graph.data), 'quality'),
        "mrf_graph": props.data.mrf_graph
    });
    useUpdateData(props, setPlotData, setQualitystate);
    useEffect(()=>{
        document.querySelector('.refresh-plots-for-filter-quality').removeEventListener('click', refreshPlotsOnFilterCall);
        document.querySelector('.refresh-plots-for-filter-quality').addEventListener('click', refreshPlotsOnFilterCall);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityHeatmap']").addEventListener("click", qualitygraphTabContainerOnChange);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityCost']").addEventListener("click", qualitygraphTabContainerOnChange);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityProcessSafety']").addEventListener("click", qualitygraphTabContainerOnChange);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityRootCause']").addEventListener("click", qualitygraphTabContainerOnChange);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityCOQ']").addEventListener("click", qualitygraphTabContainerOnChange);
        document.querySelector(".quality-graph-analysis .quality-tab-wrapper .nav-item a[aria-controls='qualityMRF']").addEventListener("click", qualitygraphTabContainerOnChange);
    }, []);
    function qualitygraphTabContainerOnChange() {
        const activeFilterEl = document.querySelector('.quality-graph-analysis .quality-tab-wrapper .tab-content svg.clickactive');
        const heatMapFilterActiveElement = document.querySelector('.quality-graph-analysis .quality-tab-wrapper .tab-content #heatmapquality rect.clicked')
        const isHeatMapFilterActive = (heatMapFilterActiveElement !== null);
        if(activeFilterEl !== null) {
            activeFilterEl.classList.remove('clickactive');
            // This will work for both plot since the call is similar
            const count =  qualityPlotFilterOnCall({id: '', filter: false});
            setQualitystate({filterCount: count});
        }else if(isHeatMapFilterActive){
            heatMapFilterActiveElement.classList.remove('clicked');
            const count =  qualityHeatmapFilterOnCall(null);
            setQualitystate({filterCount: count});
        }
    }
    function refreshPlotsOnFilterCall() {
        loadFilteredDataForQualityPlots("get-filtered-quality-plots", setPlotData);
    }
    const plotFiltersOnCall = (data) =>{
        const count =  qualityPlotFilterOnCall(data);
        setQualitystate({filterCount: count});
    }

    function heatmapFilterCallback(data) {
        const count = qualityHeatmapFilterOnCall(data);
        setQualitystate({filterCount: count});
    }
    function clearBtnOnClick(e) {
        const classSelector = `.${e.currentTarget.dataset.classname.split('-')[0]}`;
        const elementRootSVG = document.querySelector(classSelector);
        if (elementRootSVG.classList.contains("clickactive")) {
            let count = 0
            document.querySelector(`svg${classSelector}`).classList.remove('clickactive');
            // Used the same method on plot deselect
            count =  qualityPlotFilterOnCall({"id":"","filter": false});
            setQualitystate({filterCount: count});
        }
    }
    
    return (
        <div className='quality-container'>
            <div className='quality-graph-analysis graph-analysis mt-3'>
                <div className='card card-box-shadow '>
                    <div className="quality-tab-wrapper px-3 pb-3 pt-1">
                        <ul className="nav nav-tabs" id="qualityTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="qualityCostTab"
                                    data-toggle="tab" href="#qualityCost" role="tab" aria-controls="qualityCost" aria-selected="false">Cost</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityProcessSafetyTab"
                                    data-toggle="tab" href="#qualityProcessSafety" role="tab" aria-controls="qualityProcessSafety" aria-selected="false">Process Safety</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityRootCauseTab"
                                    data-toggle="tab" href="#qualityRootCause" role="tab" aria-controls="qualityRootCause" aria-selected="false">Root Cause</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityCOQTab"
                                    data-toggle="tab" href="#qualityCOQ" role="tab" aria-controls="qualityCOQ" aria-selected="false">COQ</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityMRFTab"
                                    data-toggle="tab" href="#qualityMRF" role="tab" aria-controls="qualityMRF" aria-selected="false">MRF</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityHeatmapTab"
                                    data-toggle="tab" href="#qualityHeatmap" role="tab" aria-controls="qualityHeatmap" aria-selected="true">Heatmap</a>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                <a className="nav-link" id="qualityNCRTab"
                                    data-toggle="tab" href="#qualityNCR" role="tab" aria-controls="qualityNCR" aria-selected="false">Repeat NCR</a>
                            </li> */}
                        </ul>

                        <div className="tab-content border-bottom pb-4" id="qualityTabContent">
                            { filterLoader('quality-filter-plot-loader') }
                            <div className="tab-pane fade graph" id="qualityHeatmap" role="tabpanel"
                                aria-labelledby="qualityHeatmapTab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection/Refresh Heatmap" customClass="qualityheatmap-c-btn" fullscreenSelector={"qualityHeatmap"} fullscreenSelectorOptional={'.heatmap'} info={plotInformation.quality.heatmap}/>
                                    <HeatMapQuality data={plotData.heat_map_graph}
                                        xaxistype={["LoB", "Project", "Country"]} yaxistype={["Equipment Type", "CP Category", "Root Cause"]}
                                        legendID="#qualityHeatmaplegend" datatype={'quality'} heatmapFilterCallback={heatmapFilterCallback} />
                                </div>
                            </div>
                            <div className="tab-pane fade graph show active" id="qualityCost" role="tabpanel" aria-labelledby="qualityCost-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection" customClass="costquality-c-btn" fullscreenSelector={"qualityCost"} info={plotInformation.quality.cost}/>
                                    <CostPlot data={plotData.cost_graph} filterOnCall={plotFiltersOnCall} />
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="qualityProcessSafety" role="tabpanel" aria-labelledby="qualityProcessSafety-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection" customClass="processsafety-c-btn" fullscreenSelector={"qualityProcessSafety"} info={plotInformation.quality.processsafety}/>
                                    <ProcessSafetyPlot data={plotData.process_safety_graph} filterOnCall={plotFiltersOnCall}/>
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="qualityRootCause" role="tabpanel" aria-labelledby="qualityRootCause-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection" customClass="rootcause-c-btn" fullscreenSelector={"qualityRootCause"} info={plotInformation.quality.rootcause}/>
                                    <RootCausePlot data={plotData.root_cause_graph} filterOnCall={plotFiltersOnCall}/>
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="qualityCOQ" role="tabpanel" aria-labelledby="qualityCOQ-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection" customClass="COQquality-c-btn" fullscreenSelector={"qualityCOQ"} info={plotInformation.quality.coq}/>
                                    <COQPlot data={ plotData.coq_graph} filterOnCall={plotFiltersOnCall} />
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="qualityMRF" role="tabpanel" aria-labelledby="qualityMRF-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu  clearOnClick={clearBtnOnClick} showRefreshBtn={true} title="Clear Selection" customClass="MRFquality-c-btn" fullscreenSelector={"qualityMRF"} info={plotInformation.quality.mrf}/>
                                    <MRFPlot data={plotData.mrf_graph} filterOnCall={plotFiltersOnCall}/>
                                </div>
                            </div>
                            <div className="tab-pane fade graph" id="qualityNCR" role="tabpanel" aria-labelledby="qualityNCR-tab" style={{ minHeight: '350px' }}>
                                <div className="p-2 f-screen bg-white">
                                    <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="qualityNCR-c-btn" fullscreenSelector={"qualityNCR"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='quality-results-container p-2'>
                {alertMessage(props.data.n_exact_results,  props.data.quality_id.length, "Quality")}
                <div className="d-flex justify-content-between mt-3 mb-2 small">
                    <div className="text-monospace">Showing top&nbsp;
                        <span className="quality-result-count">
                            {qualitystate.filterCount}
                        </span> result(s)<span></span></div>
                    <div>
                        <GenerateReport data={[]} type={'quality'} />
                    </div>
                </div>
                <div className='search-results-inner'>
                    {
                        props.data !== null && props.data.qualityFormatted.length > 0 ? props.data.qualityFormatted.map((quality) => {
                            return (
                                <ResultCard key={quality.quality_id}
                                    dataType={"quality"}
                                    dataId={quality.quality_id}
                                    dataTitle={quality.quality_title}
                                    dataDesc={quality.quality_desc}
                                    dataSummary={quality.quality_summary}
                                    dataCharacterstics={quality.characteristics}
                                    dataDisciplineFunc={quality.discipline_function}
                                    dataEquipmentType={quality.equipment_type}
                                    dataLoB={quality.lob}
                                    dataCountry={quality.country}
                                    dataProject={quality.quality_org}
                                    dataCreatedModified={quality.quality_modified}
                                    dataTags={[
                                        { labelName: "Location", flag: quality.flag, value: quality.country },
                                        { labelName: "Project Name", value: quality.quality_org },
                                        { labelName: "Equipment Type", value: quality.equipment_type },
                                        { labelName: "Severity", value: severity[quality.quality_severity] }
                                    ]}
                                    addToReportOnChange={qualityAddToReportOnChange}
                                    mostRelevantOnChange={qualityMostRelevantOnChange}
                                    notRelevantOnChange={qualityNotRelevantOnChange}
                                    associationsOnClick={props.associationsOnClick}
                                    mostRelevantList={props.data.most_relevant}
                                    notRelevantList={props.data.non_relevant}
                                    globalMostRelevantList={props.data.global_most_relevant}
                                    globalNotRelevantList={props.data.global_not_relevant}
                                    constkey={valueConst}
                                />
                            );
                        }) : null
                    }
                </div>
            </div>
        </div>
    );
}

export default React.memo(Quality);
