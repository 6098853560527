import { displyCard, hideCard } from "../dateFilterOnCall";
import { plotFilterOnDeselect } from "../filter-utils";
const EMOC_RESULT_SELECTOR = '.emoc-item';
function filterCards(emoccardList, idList) {
    let count = 0;
    emoccardList.forEach(function (element) {
        if (idList.includes(element.dataset.id)) {
            displyCard(element);
            count += 1;
        } else {
            hideCard(element);
        }
    });
    return count;
}

function doughnutFilterOnCall(data, setFilterCount) {
    const emocCards = document.querySelectorAll(EMOC_RESULT_SELECTOR), idList = data.ids.split(',');
    let count = 0
    if (data.isFilter) {
        count = filterCards(emocCards, idList)
        setFilterCount(count)
    } else {
        count = plotFilterOnDeselect(emocCards, 'emoc');
    }
    setFilterCount(count);
    setFilterCount(count);
}
function hzBarFilterOnCall(data, setFilterCount) {
    const emocCards = document.querySelectorAll(EMOC_RESULT_SELECTOR), idList = data.ids.split(',');
    let count = 0
    if (data.isFilterActive) {
        count = filterCards(emocCards, idList)
        setFilterCount(count);
    } else {
        count = plotFilterOnDeselect(emocCards, 'emoc');
    }
    setFilterCount(count);
    setFilterCount(count);
}
function stackedBarFilterOnCall(data, setFilterCount) {
    const emocCards = document.querySelectorAll(EMOC_RESULT_SELECTOR), idList = data.id.split(',');
    let count = 0
    if (data.filter) {
        count = filterCards(emocCards, idList)
    } else {
        emocCards.forEach(function (element) {
            displyCard(element)
            count += 1;
        });
    }
    setFilterCount(count);
}
export { doughnutFilterOnCall, hzBarFilterOnCall, stackedBarFilterOnCall };
