import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NavHeader from '../nav-header';
import Mostnotrelevantgrid from './grid-most-not-relevant';
import utils from '../app-util';
import IdleTimeTracker from '../IdleTimeTrack/IdleTimeTracker';
import RaceSpinner from '../spinner';

export default function MostNotRelevant() {
    const history = useHistory();
    const [adminInfo] = useState({isAdmin: history.location.state.admin, isAppOwner: history.location.state.appOwner, otheruser: history.location.state.otheruser});
    const [griddata, setGriddata] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(()=>{
        setLoading(true);
        fetch('/allmostnotrelevant', {
            method: "GET",
            headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        })
            .then(function (response) {
                if (response.status !== 200) {
                    setLoading(false);
                    utils.checkSessionStatus(response);
                    return;
                }
                response.json().then(function (result) {
                    setLoading(false);
                    if (result.success) {
                        setGriddata(result.data)
                    } else {
                        console.log("Failed to fetch the data");
                    }
                });
            }).catch(function (err) {
                setLoading(false);
                console.log('Fetch Error :', err);
            });
    },[])
    return (
        <div>
            <NavHeader admin={adminInfo.isAdmin} appOwner={adminInfo.isAppOwner} otheruser={adminInfo.otheruser}/>
            <div className='container-fluid py-2'>
                <div className='card'>
                    <h6 className='card-header'>Most/Not Relevant</h6>
                    <div className='card-body'>
                        <Mostnotrelevantgrid data={griddata}/>
                    </div>
                </div>
            </div>
            <IdleTimeTracker />
            {loading ? <RaceSpinner /> : null}
        </div>
    );
}
