function filterLists() {
    return [
        {
            name: 'technical',
            label: 'Technical',
            items: [
                {
                    name: 'Development', label: 'Development',
                    items: [
                        { name: 'conceptengineering', label: 'Concept Engineering' },
                        { name: 'geomatics', label: 'Geomatics' },
                        { name: 'geophysics', label: 'Geophysics' },
                        { name: 'petrophysics&geomechanics', label: 'Petrophysics & Geomechanics' },
                        { name: 'Production geoscience', label: 'Production Geosciences' },
                        { name: 'reservoirengineering', label: 'Reservoir Engineering' },
                        { name: 'technicalinformation&data_management', label: 'Technical Information & Data Management' },
                    ]
                },
                {
                    name: 'DownstreamProduction', label: 'Downstream Production',
                    items: [
                        { name: 'DSProduction&Manufacturing', label: 'DS Production & Manufacturing' },
                        { name: 'ReliabilityMaintenance&Turnarounds', label: 'Reliability, Maintenance & Turnarounds' }
                    ]
                },
                {
                    name: 'Discipline Engineering', label: 'Discipline Engineering',
                    items: [
                        { name: 'Mining', label: 'Mining' },
                        { name: 'Quality Engineering', label: 'Quality Engineering' },
                        { name: 'Civil, Structural & Offshore', label: 'Civil, Structural & Offshore' },
                        { name: 'Electrical', label: 'Electrical' },
                        { name: 'MechanicalMaterialsIntegrity', label: 'Mechanical, Materials, Integrity' },
                        { name: 'ProcessAutomationControlandOptimization', label: 'Process Automation, Control and Optimization' },
                        { name: 'PipelinesFlowAssurance&SubSea', label: 'Pipelines, Flow Assurance & Sub Sea' },
                        { name: 'RotatingEquipment', label: 'Rotating Equipment' },
                        { name: 'Utilities&HeatTransfer', label: 'Utilities & Heat Transfer' },
                    ]
                },
                {
                    name: 'Exploration', label: 'Exploration',
                    items: [
                        { name: 'GeosciencesExplorationEvaluation', label: 'Geosciences Exploration Evaluation' },
                        { name: 'GeosciencesSpecialistGeology', label: 'Geosciences Specialist Geology' },
                        { name: 'SeismicInterpretation', label: 'Seismic Interpretation' },
                    ]
                },
                {
                    name: 'InformationTechnologyandTelecom', label: 'Information Technology and Telecom',
                    items: [
                        { name: 'InformationTechnology', label: 'Information Technology' },
                        { name: 'Telecom', label: 'Telecom' },
                    ]
                },
                {
                    name: 'Maritime', label: 'Maritime',
                    items: [
                        { name: 'MaritimeDesign&Engineering', label: 'Maritime Design & Engineering' },
                        { name: 'MaritimeFleetOperations', label: 'Maritime Fleet Operations' },
                        { name: 'MaritimeOperationsAssurance', label: 'Maritime Operations Assurance' },
                    ]
                },
                {
                    name: 'ProcessEngineering', label: 'Process Engineering',
                    items: [
                        { name: 'process', label: 'Process' },
                    ]
                },
                {
                    name: 'Projects', label: 'Projects',
                    items: [
                        { name: 'CommissioningStartup&Operation Readiness', label: 'Commissioning Start-up & Operation Readiness' },
                        { name: 'DesignManagement', label: 'Design Management' },
                        { name: 'Fabrication', label: 'Fabrication' },
                        { name: 'Installation', label: 'Installation' },
                        { name: 'ProjectEngineering/ProjectManagement', label: 'Project Engineering/Project Management' },
                        { name: 'ProjectServices', label: 'Project Services' },
                        { name: 'Siteconstruction', label: 'Site construction' },
                        { name: 'Transportation', label: 'Transportation' },
                    ]
                },
                {
                    name: 'SafetyEnvironmentandSocialPerformance', label: 'Safety, Environment and Social Performance',
                    items: [
                        { name: 'Environment', label: 'Environment' },
                        { name: 'OperationalHSSE', label: 'Operational HSSE' },
                        { name: 'SocialPerformance', label: 'Social Performance' },
                        { name: 'TechnicalSafetyEngineering', label: 'Technical Safety Engineering' },
                    ]
                },
                {
                    name: 'UpstreamProduction', label: 'Upstream Production',
                    items: [
                        { name: 'Global Logistics', label: 'Global Logistics' },
                        { name: 'Maintenance&Integrity', label: 'Maintenance & Integrity' },
                        { name: 'ProductionOperationsUpstream', label: 'Production Operations Upstream' },
                        { name: 'ProductionTechnology&Chemistry', label: 'Production Technology & Chemistry' },
                    ]
                },
                {
                    name: 'Wells', label: 'Wells',
                    items: [
                        { name: 'CompletionsandWellInterventions', label: 'Completions and Well Interventions' },
                        { name: 'WellEngineering', label: 'Well Engineering' },
                    ]
                }
            ],
        },
        {
            name: 'economic', label: 'Economic',
            items: [
                {
                    name: 'finance', label: 'Finance',
                    items: [
                        { name: 'Controllers', label: 'Controllers' },
                        { name: 'InternalAudit', label: 'Internal Audit' },
                        { name: 'InvestorRelations', label: 'Investor Relations' },
                        { name: 'Strategy, Portfolio, Planning & Analysis', label: 'Strategy, Portfolio, Planning & Analysis' },
                        { name: 'Taxation', label: 'Taxation' },
                        { name: 'Treasury', label: 'Treasury' }
                    ]
                },
                {
                    name: 'legal', label: 'Legal',
                    items: [
                        { name: 'Ethics&Compliance', label: 'Ethics & Compliance' },
                        { name: 'Intellectual Property Services', label: 'Intellectual Property Services' }
                    ]
                },
                {
                    name: 'partner', label: 'Partner',
                    items: [
                        { name: 'Alignment', label: 'Alignment' },
                        { name: 'Funding/Finance', label: 'Funding/Finance' }
                    ]
                }
            ]
        },
        {
            name: 'commercial', label: 'Commercial',
            items: [
                { name: 'contract_execution', label: 'Contract execution' },
                { name: 'logistics', label: 'Logistics' },
                {
                    name: 'ProcurementProcessDesign', label: 'Procurement Process Design',
                    items: [
                        { name: 'ImplementationandChangeManagementPlanning', label: 'Implementation and Change Management Planning' },
                        { name: 'Strategy Development', label: 'Strategy Development' }
                    ]
                },
                {
                    name: 'Sourcing Procurement', label: 'Sourcing Procurement',
                    items: [
                        { name: 'CategoryManagement', label: 'Category Management' },
                        { name: 'InventoryManagement', label: 'Inventory Management' },
                        { name: 'Procurementhorizon/time', label: 'Procurement horizon/time' },
                        { name: 'SupplierManagement', label: 'Supplier Management' },
                        { name: 'Warehousing', label: 'Warehousing' }
                    ]
                }
            ]
        },
        {
            name: 'organisational', label: 'Organisational',
            items: [
                { name: 'CorporateSecurity', label: 'Corporate Security' },
                { name: 'External Relations', label: 'External Relations' },
                {
                    name: 'HumanResources', label: 'Human Resources',
                    items: [
                        { name: 'Competencies&Development', label: 'Competencies & Development' },
                        { name: 'Shell Resourcing', label: 'Shell Resourcing' }
                    ]
                },
            ]
        },
        {
            name: 'political', label: 'Political',
            items: [
                {
                    name: 'GovernmentRelations', label: 'Government Relations',
                    items: [
                        { name: 'ElectionChanges', label: 'Election Changes' },
                        { name: 'Fiscal', label: 'Fiscal' },
                        { name: 'Governance', label: 'Governance' },
                        { name: 'Policy&Regulation', label: 'Policy & Regulation' },
                    ]
                },
            ]
        },
    ]
}
const emptyfunctinalCount = {
    "Technical": 0, "Development": 0, "Concept Engineering": 0, "Geomatics": 0, "Geophysics": 0, "Petrophysics & Geomechanics": 0,
    "Production Geosciences": 0, "Reservoir Engineering": 0, "Technical Information & Data Management": 0, "Downstream Production": 0, "DS Production & Manufacturing": 0,
    "Reliability, Maintenance & Turnarounds": 0, "Exploration": 0, "Geosciences Exploration Evaluation": 0, "Geosciences Specialist Geology": 0, "Seismic Interpretation": 0,
    "Maritime": 0, "Maritime Design & Engineering": 0, "Maritime Fleet Operations": 0, "Maritime Operations Assurance": 0, "Process Engineering": 0, "Process": 0, "Projects": 0,
    "Commissioning Start-up & Operation Readiness": 0, "Design Management": 0, "Fabrication": 0, "Installation": 0, "Project Engineering/Project Management": 0,
    "Project Services": 0, "Site construction": 0, "Transportation": 0, "Upstream Production": 0, "Global Logistics": 0, "Maintenance & Integrity": 0,
    "Production Operations Upstream": 0,
    "Production Technology & Chemistry": 0, "Discipline Engineering": 0, "Mining": 0, "Quality Engineering": 0, "Civil, Structural & Offshore": 0, "Electrical": 0,
    "Mechanical, Materials, Integrity": 0, "Process Automation, Control and Optimization": 0, "Pipelines, Flow Assurance & Sub Sea": 0, "Rotating Equipment": 0,
    "Utilities & Heat Transfer": 0, "Information Technology and Telecom": 0, "Information Technology": 0, "Telecom": 0, "Safety, Environment and Social Performance": 0,
    "Environment": 0, "Operational HSSE": 0, "Social Performance": 0, "Technical Safety Engineering": 0, "Wells": 0,
    "Completions and Well Interventions": 0, "Well Engineering": 0,
    "Economic": 0, "Finance": 0, "Controllers": 0, "Internal Audit": 0, "Investor Relations": 0, "Strategy, Portfolio, Planning & Analysis": 0, "Taxation": 0,
    "Treasury": 0, "Legal": 0,
    "Ethics & Compliance": 0, "Intellectual Property Services": 0, "Partner": 0, "Alignment": 0, "Funding/Finance": 0, "Commercial": 0, "Contract execution": 0, "Logistics": 0,
    "Procurement Process Design": 0, "Implementation and Change Management Planning": 0, "Strategy Development": 0, "Sourcing Procurement": 0, "Category Management": 0,
    "Inventory Management": 0, "Procurement horizon/time": 0, "Supplier Management": 0, "Warehousing": 0, "Organisational": 0, "Corporate Security": 0, "External Relations": 0,
    "Human Resources": 0, "Competencies & Development": 0, "Shell Resourcing": 0, "Political": 0, "Government Relations": 0, "Election Changes": 0, "Fiscal": 0, "Governance": 0,
    "Policy & Regulation": 0
}
export { filterLists, emptyfunctinalCount };
