const tours = {
    run: false,
    steps: [
        {
            disableBeacon: true,
            target: '.tour-welcome',
            content: 'Welcome to Qurie! An AI based search engine',
        },
        {
            spotlightClicks: true,
            target: '.tour-searchinput',
            content: "The search input field allow users to input keywords, phrases, or sentences for search purposes. Additionally, it provides autocomplete suggestions based on the user's recent search activity.",
        },
        {
            placement: 'bottom',
            target: '.tour-plot-dummy',
            content: '',
        },
        {
            placement: 'top-end',
            target: '.tour-sidebar-dummy',
            content: '',
        },
        {
            target: '.tour-data-tabs',
            content: 'Shows search results for each dataset under respective tabs.'
        },
        {
            placement: 'bottom',
            target: '.tour-plot',
            content: 'In this section you can view key visuals for all tabs and visual are based on top 100 results for your searched query. All visuals are interactive (click & unclick) in nature and can act as filters.',
        },
        {
            target: '.tour-thematic-bt',
            content: 'Applicable to Risk information only. You can generate thematic bowtie for risk data. It visualizes the top 20 Causes, Consequences, and associated Actions. Before exporting you can also edit/remove the bowtie items.',
        },
        {
            spotlightPadding: 3,
            target: '#risk .search-results-inner .risk-item:first-child .action-bowtie',
            content: 'Applicable to Risk information only. Users can view the individual bowtie for a particular risk, based on available 3-part statement in Risk database or created using the machine learning algorithms if risk is not defined in 3-part statement.',
        },
        {
            spotlightPadding: 3,
            target: '#risk .search-results-inner .risk-item:first-child .associations-btns',
            content: 'This feature allows users to view associations between different dataset integrated into the Qurie.'
        },
        {
            spotlightPadding: 2,
            target: '#risk .search-results-inner .risk-item:first-child .relevant-field',
            content: 'This option allows the user to provide feedback by marking search results as "most relevant" or "not relevant". Choosing "most relevant" will prioritize that particular result to the top of the search result list, while selecting "not relevant" will prevent those marked results from appearing in future searches. This feature is customized for every induvial user.'
        },
        {
            spotlightPadding: 3,
            target: '#risk .search-results-inner .risk-item:first-child .addreportwrapper',
            content: 'User have the flexibility to add the search results to a customized report (existing or new) by clicking on "Add to Report" for required results. Upon selection of required results users should click on "Generate Report".',
        },
        {
            placement: 'top',
            target: '.tour-generate-report-results',
            content: 'After adding the search result using "Add to Report", click here to generate report.'
        },
        {
            target: '.tour-report-download',
            content: 'This feature enables users to generate and download standard report viz. Top 20, 30 & 50 or customized reports.',
        },
        {
            spotlightClicks: true,
            placement: 'bottom',
            target: '.tour-advancedsearch',
            content: 'Click the link to open the advanced search panel.'
        },
        {
            spotlightClicks: true,
            placement: 'bottom',
            target: '.tour-advancedsearch-mustinclude',
            content: 'Use advanced search to narrow down the search based on the LoB, Country, and Project. The "Must Include" provides more specific results by ensuring that the keyword or phrase is included in the results.'
        },
        {
            target: '.tour-alternate-searchterm',
            content: 'Alternate search keywords of the current search. This feature enables users to click on these alternate words to customize the original search query.',
        },
        {
            placement: 'right-start',
            target: '.tour-sidebar',
            content: 'Using the filters available user can slice & dice the top 100 search results for each dataset.',
        },
        {
            target: '.tour-clearall',
            content: 'Using this options user can clear all the filters applied by single click.',
        },
        {
            target: '.tour-userguide',
            content: 'Users can find the Detailed User Guide here.',
        },
        {
            spotlightClicks: true,
            target: '.tour-userprofile',
            content: "Using the dropdown menu, the user can log out from the current session and access a personalized menu based on level of access.",
        }
    ]
}
export default tours;
