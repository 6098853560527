function alertTemplate(nearerMatchResult, dataType) {
    return (
        <>
            {
                nearerMatchResult !== 0 ?
                    <div className='alert alert-warning alert-dismissible fade show d-flex align-items-center pl-2' role='alert' style={{ minHeight: "75px" }}>
                        <div>
                            <svg width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="alert-circle mr-2">
                                <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                        </div>
                        <div>Exact matches for "{sessionStorage.getItem('query')}" could not be found in {dataType} data.
                            Presenting semantically similar results. Please use Advanced Search for more specific queries.</div>
                        <div>
                            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{ top: "10px" }}>
                            <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                    </div>
                    : null
            }
        </>
    );
}
/**
 * It shows the exact result alert message if the exact result count is zero
 * @param {Integer} nExactResults
 * @param {String} dataType
 * @returns HTML String
 */
function alertMessage(nExactResults, nearerMatchResult, dataType) {
    return (
        <>
            {
                nExactResults === 0 ? alertTemplate(nearerMatchResult, dataType) : null
            }
        </>
    );
}

export default alertMessage;
