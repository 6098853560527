import utils from "./app-util";
import { getSelectedDateRange, getSelectedYearValue } from "./date-filter";
import { emptyfunctinalCount } from "./filter";
import { intersection } from "lodash";
import { getTheListIfDropDownFilterOn } from "./qualityComponent/QualityFilterUtils";

function updateTECOPCount(listOnDisplay) {
    const risk_functional_count = JSON.parse(JSON.stringify(emptyfunctinalCount));
    for (let i = 0; i < listOnDisplay.length; ++i) {
        for (const key in risk_functional_count) {
            if (utils.hasClass(listOnDisplay[i], key.replace(/\s|,|\//g, '_'))) {
                risk_functional_count[key] = risk_functional_count[key] + 1
            }
        }
    }
    return risk_functional_count;
}
function getTheListIfTECOPOn(checkedTagElem, allCards) {
    const tagList = [], filteredElements = [];
    checkedTagElem.forEach(function (element) {
        tagList.push(element.value);
    });

    allCards.forEach(function (element) {
        for (let i = 0; i < tagList.length; ++i) {
            if (utils.hasClass(element, tagList[i].replace(/\s|,|\//g, '_'))) {
                filteredElements.push(element)
            }
        }
    })
    return [...new Set(filteredElements)];
}

function getTheListIfAdvanceSearchOn(advSearchElems, allCards) {
    const advSearchSelections = [];
    advSearchElems.forEach(function (element) {
        advSearchSelections.push(element.dataset.item);
    });
    const emocCardList = []
    allCards.forEach(function (card) {
        advSearchSelections.forEach(function (item) {
            if (card.classList.contains(item.replace(/\s|,|\//g, '_'))) {
                emocCardList.push(card);
            }
        });
    });
    return [...new Set(emocCardList)];
}

function getTheListIfYearOn(selectedYearValue, allCards) {
    const filteredList = [];
    if (selectedYearValue !== "") {
        allCards.forEach(function (element) {
            if (new Date(parseInt(element.dataset.modified, 10)).getFullYear() === parseInt(selectedYearValue, 10)) {
                filteredList.push(element);
            }
        });
    }
    return filteredList;
}
function getTheListIfDateRangeOn(selectedDateRangeValue, allCards) {
    const filteredList = [];
    if (selectedDateRangeValue.length > 0) {
        allCards.forEach(function (element) {
            if (utils.isBetweenDate(parseInt(element.dataset.modified, 10), selectedDateRangeValue[0].getTime(), selectedDateRangeValue[1].getTime())) {
                filteredList.push(element);
            }
        });
    }
    return filteredList;
}
function loadFilteredDataForRiskPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleRiskResultIDs();
    const filterLoaderElement = document.querySelector('.filter-loader-riskplot');
    filterLoaderElement.classList.remove('d-none');
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderElement.classList.add('d-none');
        const graph_data = utils.formatGraphData(results);
        setPlotData(graph_data);
    }, (err) => {
        filterLoaderElement.classList.add('d-none');
        console.error("Error: " + err);
    }).catch((err) => {
        filterLoaderElement.classList.add('d-none');
        console.error("Error: " + err);
    });
}

function loadFilteredDataForEMoCPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleEMoCResultIDs();
    const filterLoaderMoC = document.querySelector('.emoc-filter-plot-loader')
    filterLoaderMoC.classList.remove('d-none');
    fetch(`/${url}/${filterdIds}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderMoC.classList.add('d-none');
        results.moc_big_rule.isNew = true;
        results.pie_changes_plot.isNew = true;
        results.pie_trends_plot.isNew = true;
        results.cost = [formatGraphNegativeData(results.cost, Object.keys(results.cost.data.pos), "pos", "emoc"), formatGraphNegativeData(results.cost, Object.keys(results.cost.data.neg), "neg", "emoc")]
        
        setPlotData({
            "pie_changes_plot": results.pie_changes_plot,
            "pie_trends_plot": results.pie_trends_plot,
            "moc_big_rule": results.moc_big_rule,
            "cost": results.cost
        });
    }, (err) => {
        console.error("Error: " + err);
        filterLoaderMoC.classList.add('d-none');
    }).catch((err) => {
        console.error("Error: " + err);
        filterLoaderMoC.classList.add('d-none');
    });
}
function loadFilteredDataForQualityPlots(url, setPlotData) {
    const filterdIds = utils.getVisibleResultIDs('indicesquality', '.quality-item.d-block', '.quality-item.d-none');
    const filterLoaderQuality = document.querySelector('.quality-filter-plot-loader')
    filterLoaderQuality.classList.remove('d-none');
    const hm_x_axis_val = document.querySelector(".quality-heatmap-dropdowns .xaxis-dropdown-select").value;
    const hm_y_axis_val = document.querySelector(".quality-heatmap-dropdowns .yaxis-dropdown-select").value;
    fetch(`/${url}/${filterdIds}?hm_x_axis=${hm_x_axis_val}&hm_y_axis=${hm_y_axis_val}`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        filterLoaderQuality.classList.add('d-none');
        results.heat_map_graph.isNew = true;
        results.process_safety_graph.isNew = true;
        results.root_cause_graph.isNew = true;
        results.mrf_graph.isNew = true;
        setPlotData({
            "heat_map_graph": results.heat_map_graph,
            "cost_graph":  utils.formatGraphData(results.cost_graph, Object.keys(results.cost_graph.data), 'quality'),
            "process_safety_graph": results.process_safety_graph,
            "root_cause_graph": results.root_cause_graph,
            "coq_graph": utils.formatGraphData(results.coq_graph, Object.keys(results.coq_graph.data), 'quality'),
            "mrf_graph": results.mrf_graph
        });
    }, (err) => {
        console.error("Error: " + err);
        filterLoaderQuality.classList.add('d-none');
    }).catch((err) => {
        console.error("Error: " + err);
        filterLoaderQuality.classList.add('d-none');
    });
}
function filterLoader(classSelector) {
    return (
        <div className={`${classSelector} d-none`}>
            <div className='position-absolute d-flex justify-content-center'
                style={{ left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.1)' }}>
                <div className='align-self-center racespinner shadow d-flex justify-content-center' style={{ backgroundColor: "var(--main-bg-color)" }}>
                    <div className='align-self-center'>Loading...</div>
                </div>
            </div>
        </div>
    )
}
function formatGraphNegativeData(gData, groups, plotType, dataType) {
    const data = gData.data[plotType];
    const formatted = [], newFormat = {};
    for (let i = 0; i < data[groups[1]].length; ++i) {
        const temp = {};
        temp['label'] = gData.x_tick_labels[i];
        for (const val of groups) {
            temp[val] = (plotType==='neg' ? (-data[val][i]) : data[val][i])
        }
        temp[`${dataType}_ids`] = gData[`data_${dataType}_id`][plotType]
        formatted.push(temp);
    }
    newFormat.data = formatted;
    newFormat.groups = groups;
    newFormat.x_label = gData.x_label
    newFormat.y_label = gData.y_label
    newFormat.isNew = true;
    return newFormat;
}
function updateAdvancedSearchDropdown(listOnDisplay, dataFilterDropdownUpdate) {
    const advancedSearchDropdownValues = { projectss: [], countriess: [], lobss: [] }
    listOnDisplay.forEach(function (element) {
        if (utils.hasClass(element, 'd-block')) {
            if (element.dataset.lob !== "") {
                advancedSearchDropdownValues.lobss.push(element.dataset.lob);
            }
            if (element.dataset.country !== "") {
                advancedSearchDropdownValues.countriess.push(element.dataset.country);
            }
            if (element.dataset.project !== "") {
                advancedSearchDropdownValues.projectss.push(element.dataset.project);
            }
        }
    });
    for (const key in advancedSearchDropdownValues) {
        if (advancedSearchDropdownValues.hasOwnProperty(key)) {
            advancedSearchDropdownValues[key] = utils.removeDuplicate(advancedSearchDropdownValues[key]);
        }
    }
    
    dataFilterDropdownUpdate("", advancedSearchDropdownValues);
    
}

function resetSearchResultOnPlotFilterToggle(dataType) {
    const cardList = document.querySelectorAll(`.${dataType}-item`);
    const listIfYearFilterIfOn = [...getTheListIfYearOn(getSelectedYearValue(dataType), cardList)];
    const listIfDateRangeFilterIfOn = [...getTheListIfDateRangeOn(getSelectedDateRange(dataType), cardList)];
    const listIfAdvSearchOn = [...getTheListIfAdvanceSearchOn(document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR),cardList)];
    
    let taggedElem = [], otherFilter = [];
    if(dataType === 'risk' || dataType === 'emoc') {
        taggedElem = document.querySelectorAll(`.${dataType}-sidebar .filter-list input:checked`);
        otherFilter = [...getTheListIfTECOPOn(taggedElem, cardList)];
    } else if(dataType === 'quality') {
        const selectedValueChipElements = document.querySelectorAll(".MuiChip-label.MuiChip-labelSmall");
        otherFilter = [...getTheListIfDropDownFilterOn(selectedValueChipElements, cardList)];
    }


    let newList = cardList
    if (listIfYearFilterIfOn.length > 0 && listIfAdvSearchOn.length > 0 && otherFilter.length > 0 && listIfDateRangeFilterIfOn.length > 0) {
        newList = intersection(listIfYearFilterIfOn, listIfDateRangeFilterIfOn, listIfAdvSearchOn, otherFilter);
    } else if (listIfYearFilterIfOn.length > 0 && listIfAdvSearchOn.length > 0 && otherFilter.length > 0) {
        newList = intersection(listIfYearFilterIfOn, listIfAdvSearchOn, otherFilter);
    } else if (listIfDateRangeFilterIfOn.length > 0 && listIfAdvSearchOn.length > 0 && otherFilter.length > 0) {
        newList = intersection(listIfDateRangeFilterIfOn, listIfAdvSearchOn, otherFilter);
    } else if (listIfYearFilterIfOn.length > 0 && otherFilter.length > 0) {
        newList = intersection(listIfYearFilterIfOn, otherFilter);
    } else if (listIfYearFilterIfOn.length > 0 && listIfAdvSearchOn.length > 0) {
        newList = intersection(listIfYearFilterIfOn, listIfAdvSearchOn);
    } else if (otherFilter.length > 0 && listIfAdvSearchOn.length > 0) {
        newList = intersection(otherFilter, listIfAdvSearchOn);
    } else if (listIfDateRangeFilterIfOn.length > 0 && otherFilter.length > 0) {
        newList = intersection(listIfDateRangeFilterIfOn, otherFilter);
    } else if (listIfDateRangeFilterIfOn.length > 0 && listIfAdvSearchOn.length > 0) {
        newList = intersection(listIfDateRangeFilterIfOn, listIfAdvSearchOn);
    } else if (listIfDateRangeFilterIfOn.length > 0 && listIfYearFilterIfOn.length > 0 ) {
        newList = intersection(listIfDateRangeFilterIfOn, listIfYearFilterIfOn);
    } else if ( listIfYearFilterIfOn.length > 0 ) {
        newList = listIfYearFilterIfOn;
    } else if ( listIfDateRangeFilterIfOn.length > 0 ) {
        newList = listIfDateRangeFilterIfOn;
    } else if ( otherFilter.length > 0 ) {
        newList = otherFilter;
    } else if ( listIfAdvSearchOn.length > 0 ) {
        newList = listIfAdvSearchOn;
    }
    return newList;
}

function displyCard(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}

function plotFilterOnDeselect(originalList, dataType) {
    let count = 0;
    const toggleResult = resetSearchResultOnPlotFilterToggle(dataType);
    if (toggleResult.length > 0) {
        toggleResult.forEach(function (element) {
            element.classList.remove('d-none');
            element.classList.add('d-block');
            count += 1;
        });
    } else {
        originalList.forEach(function (element) {
            displyCard(element);
            count += 1;
        });
    }
    return count;
}

export {
    updateTECOPCount, getTheListIfTECOPOn, getTheListIfAdvanceSearchOn,
    getTheListIfYearOn, getTheListIfDateRangeOn, loadFilteredDataForRiskPlots, loadFilteredDataForEMoCPlots, filterLoader,
    loadFilteredDataForQualityPlots, formatGraphNegativeData, updateAdvancedSearchDropdown, resetSearchResultOnPlotFilterToggle, plotFilterOnDeselect
}
