import React, { useEffect, useState } from 'react';
import { Bowtie } from './bowtie';
import { toJpeg } from 'html-to-image';
import utils from './app-util';
import alerttriangle from '../assets/alert-triangle.svg';

export const COLUMNSORDER = { 'causes': 'Causes', 'preventive_actions': 'Preventive Actions', 'event': 'Event', 'recovery_actions': 'Recovery Actions', 'consequences': 'Consequences' };
const THEMATIC_DOWNLOAD_CLASS = "thematic-download";
const html2pdf =require('html2pdf.js');
function getDataList(classSelector, idType) {
    const list = []
    if (idType === 'riskid') {
        document.querySelectorAll(classSelector).forEach(function (element) {
            list.push(element.dataset.riskid);
        });
    } else {
        document.querySelectorAll(classSelector).forEach(function (element) {
            list.push(element.dataset.actionid);
        });
    }

    return list;
}

function BowtiePopup({ show, thematicBowtie, data, bowtieAfterClose, dataid, datatype, summaryBowtie = false, title, error }) {
    const isConsolidatedSummary = title === 'Consolidated Summary' ? true : false;
    function openModal(value) {
        return `modal bowtiepopup ${value ? "show" : ""}`
    }
    function onPopupClose() {
        bowtieAfterClose(false)
    }
    function downloadPDF() {
        const bowtieElement = document.querySelector(".bowtiepopup .modal-content");
        const opt = {
            margin: 0.5,
            filename: `${document.querySelector('.titledetail').textContent}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' },
            pagebreak: {
                mode: 'avoid-all'
            }
        };
        ["menu-btns", "bowtie-row-container", "bowtie-cause-content", "bowtie-preventive-actions-content", "bowtie-recovery-actions-content", "bowtie-consequence-content", "bowtie-event-content", "trash"].forEach(function (selector) {
            document.querySelector(`.${selector}`).classList.add('onpdfdownload');
        });
        html2pdf().set(opt).from(bowtieElement).save();
        setTimeout(() => {
            ["menu-btns", "bowtie-cause-content", "bowtie-preventive-actions-content", "bowtie-recovery-actions-content", "bowtie-row-container", "bowtie-consequence-content", "bowtie-event-content", "trash"].forEach(function (selector) {
                const sel = document.querySelector(`.${selector}`)
                sel.classList.remove('onpdfdownload');
            });
        }, 2000);
    }

    const getOrderedSummaryData = () => {
        let orderedObj = {};
        const column = Object.keys(COLUMNSORDER);
        column.forEach(key => {
            if (key === 'event') {
                const event_str = [];
                event_str.push(window.sessionStorage.getItem('query'));
                orderedObj[COLUMNSORDER[key]] = event_str;
            } else {
                const listItems = document.querySelectorAll(`.${key} .list-group-item`);
                orderedObj[COLUMNSORDER[key]] = [];
                listItems.forEach((item, index) => {
                    if (!item.classList.contains('d-none') && data.hasOwnProperty(key)) {
                        orderedObj[COLUMNSORDER[key]].push(data[key][index]);
                    }
                });
            }
        })
        return orderedObj;
    }

    function onClick(e) {
        if (!e.currentTarget.classList.contains(THEMATIC_DOWNLOAD_CLASS)) {
            // The image download statement kept as future use as currently on hold, if needed we can set the 'isImage' flag to true
            const isImage = false;
            if (isImage) {
                const bowtieElement = document.querySelector(".bowtie-wrapper");
                toJpeg(bowtieElement).then(function (dataUrl) {
                    const link = document.createElement('a');
                    link.download = 'bowtie.jpeg';
                    link.href = dataUrl;
                    link.click();
                })
            } else {
                if(e.currentTarget.classList.contains("excel")){
                    if (summaryBowtie) {
                        utils.exportSummaryDataExcel(getOrderedSummaryData(), getDownloadTitle().concat('.xlsx'), isConsolidatedSummary);
                    } else {
                        utils.makeDownloadRequest(`/individual-bt-download/${dataid}`, `Bowtie_${dataid}`);
                    }
                }else{
                    downloadPDF();
                }
            }
        } else {
            const editBtn = document.querySelector(".edit");
            if (editBtn.classList.contains('active')) {
                editBtn.click();
            }
            const payload = {
                "h_cause": getDataList(".bowtie-cause-content .list-group-item.d-none", "riskid"),
                "h_consequence": getDataList(".bowtie-consequence-content .list-group-item.d-none", "riskid"),
                "preventive_actions": {
                    "risk_id": getDataList(".bowtie-preventive-actions-content .list-group-item.d-none", "riskid"),
                    "action_id": getDataList(".bowtie-preventive-actions-content .list-group-item.d-none .trash", "actionid")
                },
                "recovery_actions": {
                    "risk_id": getDataList(".bowtie-recovery-actions-content .list-group-item.d-none", "riskid"),
                    "action_id": getDataList(".bowtie-recovery-actions-content .list-group-item.d-none .trash", "actionid")
                }
            }
            const query = sessionStorage.getItem('query');
            if (e.currentTarget.classList.contains("excel")) {
                utils.makeDownloadRequest(`/thematic-bt-download/${query}/${JSON.stringify(payload)}/${utils.getVisibleRiskResultIDs()}`, query);
            } else {
                downloadPDF();
            }
        }
    }
    function onEditClick(e) {
        const cause = document.querySelector('.bowtie-cause-content');
        const conseq = document.querySelector('.bowtie-consequence-content');
        const prevAction = document.querySelector('.bowtie-preventive-actions-content');
        const recovAction = document.querySelector('.bowtie-recovery-actions-content');
        if (cause.classList.contains('active')) {
            cause.classList.remove('active');
            conseq.classList.remove('active');
            prevAction.classList.remove('active');
            recovAction.classList.remove('active');
            e.target.classList.remove('active');
        } else {
            cause.classList.add('active');
            conseq.classList.add('active');
            prevAction.classList.add('active');
            recovAction.classList.add('active');
            e.target.classList.add('active');
        }
    }
    function getDownloadTitle() {
        return `${sessionStorage.getItem('query').split(' ').join('_')}_${title.split(' ').join('_')}`;
    }
    function titleContent() {
        if (thematicBowtie) {
            return (<h6 className="modal-title ml-2"><span className='font-weight-bold'>Search Query: </span>
                <span className='text-capitalize titledetail'>{sessionStorage.getItem('query')}</span></h6>);
        } else if (summaryBowtie) {
            return (<h6 className="modal-title ml-2"><span className="font-weight-bold">{title ? `${title}: ` : 'Summary:'} </span>
                <span style={{ display: 'none' }} className='titledetail'>{getDownloadTitle()}</span>
                <span>{data['event']}</span>
            </h6>);
        } else {
            return (<h6 className="modal-title ml-2">
                <span className='font-weight-bold'>Title: </span>
                <span className='titledetail'>{data[`${datatype}_title`]}</span>
            </h6>);
        }
    }
    function errorMsg() {
        return (
            <h6 className="modal-title mb-1 small font-italic text-danger"><img className="mr-2" alt="Back to results" src={alerttriangle} />{error}</h6>
        )
    }
    function getActionBtns() {
        return (
            <>
                {(thematicBowtie || summaryBowtie) ? <button type='button' className='bowtiebtn edit mr-2' onClick={onEditClick} title="Edit Bowtie"></button> : null}
                <button type='button' id='bowtiedownload' className={thematicBowtie ? 'bowtiebtn download dropdown-toggle thematic-download ' : 'bowtiebtn download dropdown-toggle '}
                    title="Download Bowtie" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                <div className="dropdown-menu" aria-labelledby='bowtiedownload'>
                    <button className={`dropdown-item small download excel ${thematicBowtie ? THEMATIC_DOWNLOAD_CLASS : ""}`} onClick={onClick}>Download Excel</button>
                    <button className={`dropdown-item small download pdf ${thematicBowtie ? THEMATIC_DOWNLOAD_CLASS : ""}`} onClick={onClick}>Download PDF</button>
                </div>
            </>
        );
    }


    return (
        <>
            <div className={openModal(show)} tabIndex="-1">
                <div className="modal-dialog modal-xl" style={{ margin: '.2rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {!error ? titleContent() : errorMsg()}

                            <div className='ml-auto d-flex align-self-center menu-btns'>
                                {!error && getActionBtns()}
                                <button type="button" className="bowtiebtn closebtn ml-2" data-dismiss="modal" aria-label="Close" onClick={onPopupClose} title="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Bowtie inputData={data} thematicBowtie={thematicBowtie} datatype={datatype} summaryBowtie={summaryBowtie} isConsolidatedSummary={isConsolidatedSummary} />
                        </div>
                    </div>
                </div>
            </div>
            {show ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </>
    );
}
export default BowtiePopup;
