
import  utils from '../../app/app-util';

const API_URL = '/get_chat_response/';
async function getPromptResponse({ query, onSuccess, onError }) {
    fetch(API_URL+ query, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
    .then((res) => {
        if (res.status === 403 || res.status === 401) {
            return Promise.reject(res);
        } else {
            return utils.checkSessionStatus(res);
        }
    })
    .then((results) => {
        onSuccess(results);
    },
    (error) => {
        onError(error);
    })

}

async function clearChatbot() {
    fetch('/reset_conversation', {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
    .then((res) => {
        if (res?.status !== 200) {
            console.error('Error occured while clearing chatbot:', res?.statusText)
        } 
    },
    (error) => {
        console.error('Error occured while clearing chatbot:', error)
    })
}
export {getPromptResponse, clearChatbot}