import utils from "../app-util";
import { getSelectedDateRange, getSelectedYearValue } from "../date-filter";
import { getTheListIfAdvanceSearchOn, getTheListIfDateRangeOn, getTheListIfYearOn } from "../filter-utils";
function displayCard(card) {
    card.classList.remove("d-none");
    card.classList.add("d-block");
}
function hideCard(card) {
    card.classList.add("d-none");
    card.classList.remove("d-block");
}
function filterEmocResult(setFilterCountEmoc) {
    let count = 0;
    let emocCards = [];
    const emocCardItems = document.querySelectorAll('.emoc-item');
    const tagElem = document.querySelectorAll(".emoc-sidebar .filter-list input:checked");
    emocCards = getFilteredResultList(emocCardItems)

    // Filter the result cards according TECOP selection
    const filterTags = [];
    let filterList = [];
    tagElem.forEach(function (element) {
        filterTags.push(element.value)
    })

    if (filterTags.length > 0) {
        emocCards.forEach(function (card) {
            filterTags.forEach(function (tag) {
                if (utils.hasClass(card, tag.replace(/\s|,|\//g, '_'))) {
                    filterList.push(card.dataset.id)
                }
            });
        });

        filterList = [...new Set(filterList)]
        if (filterList.length > 0) {
            emocCards.forEach(function (card) {
                if (filterList.includes(card.dataset.id)) {
                    displayCard(card);
                    count++;
                } else {
                    hideCard(card);
                }
            });
            setFilterCountEmoc(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
        } else {
            emocCards.forEach(function (card) {
                hideCard(card);
            });
            setFilterCountEmoc(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
        }
    } else {
        emocCards.forEach(function (card) {
            displayCard(card);
            count++;
        });
        setFilterCountEmoc(filterCountEmoc => ({ ...filterCountEmoc, emocCount: count }));
    }

    document.querySelector('.refresh-plots-for-filter-emoc').click();
    document.querySelector('.refresh-plots-for-filter-emoc').click();
}
/**
 * Return the element list based on the advanced search, date and year filter
 * @param {NodeList} originalEmocList - Total moc search result list
 * @returns {NodeList}
 */
function getFilteredResultList(originalEmocList) {
    let filteredCards = [];
    const advSearchElems = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
    const selectedYearValue = getSelectedYearValue("emoc");
    const selectedDateRangeValue = getSelectedDateRange("emoc");
    if (advSearchElems.length > 0) {
        // Get the selected items from advanced search if advanced search applied before TECOP
        filteredCards = getTheListIfAdvanceSearchOn(advSearchElems, originalEmocList)
    } else if (selectedYearValue !== "") {
        // Get the selected items from year filter if it applied before TECOP
        filteredCards = getTheListIfYearOn(selectedYearValue, originalEmocList);
    } else if (selectedDateRangeValue.length > 0) {
        // Get the selected items from date range calender filter if it applied before TECOP
        filteredCards = getTheListIfDateRangeOn(selectedDateRangeValue, originalEmocList);
    } else {
        filteredCards = originalEmocList;
    }
    return filteredCards;
}

function resetEmocFilter(setFilterCount) {
    let count = 0
    document.querySelector('#bigrule svg#mocBigRule').classList.remove('clickactive');
    document.querySelector("#contributingfactor svg#mocPie").classList.remove('clickactive')
    document.querySelectorAll('.emoc-item').forEach(function (card) {
        displayCard(card);
        count++;
    });
    setFilterCount(count)
}
export { filterEmocResult, resetEmocFilter };
