import React from 'react'
import StackedBarchart from '../stacked-bar-chart';

export default function CostPlot(props) {
    return (
        <div className=''>
            <span className='small'>Root Cause</span>
            <StackedBarchart data={ props.data} stackedBarChartFIlterCallback={props.filterOnCall} dataType="quality" type={'costquality'} parentCallback={props.parentCallback}/>
        </div>
    );
}
