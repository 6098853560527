import React from 'react'
import * as d3 from "d3";
import utils from '../app-util';
function getPosCXCoordinate(radius, midangle) {
    return radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
}
class DoughNut extends React.Component {
    constructor(props) {
        super(props);
        this.drawChart = this.drawChart.bind(this);
        this.node = React.createRef();
        this.msgElem = React.createRef();
        this.state = {
            changes: this.props['changes'],
            trends: this.props['trends']
        }
    }
    componentDidMount() {
        this.svg = d3.select(this.node.current);
        this.margin = 45;
        this.width = utils.getFixedPlotDimension().width;
        this.height = utils.getFixedPlotDimension().height;
        this.radius = Math.min(this.width, this.height) / 2 - this.margin

        this.svg.attr("class", "piesvg")
            .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
            .attr('preserveAspectRatio', 'xMinYMin')
        this.g = this.svg.append("g").attr("class", "piepath")
            .attr("transform", `translate(${Math.min(this.width, this.height) / 2},${Math.min(this.width, this.height) / 2})`)

        this.drawChart(this.props.changes, 'changes');


        const _this = this;
        const initialWidth = utils.getFixedPlotDimension().width, initialHeight = utils.getFixedPlotDimension().height;
        function updateOnFullScreen() {
            document.querySelector("#contributingfactor .f-screen").style.width = _this.width;
            document.querySelector("#contributingfactor .f-screen").style.height = _this.height;
            _this.svg.attr("width", _this.width)
                .attr("height", _this.height)
                .attr('viewBox', '0 0 ' + (_this.width) + ' ' + (_this.height))
                .attr('preserveAspectRatio', 'xMinYMin')
            _this.radius = Math.min(_this.width, _this.height) / 2 - _this.margin
            _this.g.attr("transform", `translate(${Math.min(_this.width, _this.height) / 2},${Math.min(_this.width, _this.height) / 2})`)
        }
        document.querySelector("#contributingfactor").addEventListener('fullscreenchange', (_e) => {
            if (document.fullscreenElement) {
                _this.width = window.innerWidth - 100;
                _this.height = window.innerHeight - 100;
                updateOnFullScreen();
            } else {
                _this.width = initialWidth;
                _this.height = initialHeight;
                updateOnFullScreen();
            }
            const dropdownValue = document.querySelector(".pie-dropdown").value
            setTimeout(() => {
                _this.drawChart(_this.props[dropdownValue], dropdownValue);
            }, 100);
        })
    }
    shouldComponentUpdate(nextProps) {
        const emocTab = JSON.parse(sessionStorage.getItem("emocTab"));
        
        if(nextProps.changes.isNew && !emocTab.otclicked && !emocTab.stclicked && emocTab.newload){
            // this.setState({changes: this.props.changes, trends: this.props.trends});
            const selectedOption = document.querySelector('.pie-dropdown ').value;
            this.drawChart(this.props[selectedOption], selectedOption);
        }
        
        return false;
    }
    static getDerivedStateFromProps(props, state){
        const emocTab = JSON.parse(sessionStorage.getItem("emocTab"));
        if(props.changes.isNew && !emocTab.otclicked && !emocTab.stclicked && emocTab.newload){
            return {
                changes: props.changes, 
                trends: props.trends
            };
        }
        return null;
    }
    optionOnChange(e) {
        this.drawChart(this.state[e.target.value], e.target.value);
    }

    formatPieData(pieData) {
        let sum = 0, temp = {};
        for (const key in pieData) {
            if (Object.hasOwnProperty.call(pieData, key)) {
                sum += pieData[key];
            }
        }
        for (const key in pieData) {
            if (Object.hasOwnProperty.call(pieData, key)) {
                temp[key] = Math.round(pieData[key] / sum * 100);
            }
        }
        temp = this.sortData(Object.entries(temp))
        return temp;
    }
    sortData(pieData) {
        pieData.sort(function (a, b) {
            return d3.ascending(a[1], b[1]);
        });

        return pieData;
    }
    sliceOnCLick(_selfContext) {
        const ids = d3.select(_selfContext).attr("data-ids");

        let filter = false;
        if (d3.select(_selfContext).classed('active') && this.svg.classed('clickactive')) {
            d3.select(_selfContext).classed("active", false);
            this.svg.classed('clickactive', false);
        } else {
            filter = true;
            d3.select(_selfContext).classed("active", true);
            this.svg.classed('clickactive', true);
        }
        // clear the highlighted rect on other rect clicking
        if ((this.isSliceHighlighted !== null) && (!this.isSliceHighlighted.isSameNode(_selfContext))) {
            this.isSliceHighlighted.classList.remove('active');
        }
        
        this.isSliceHighlighted = _selfContext;
        this.props.filterOnCall({ ids: ids, isFilter: filter });
    }
    
    drawChart(data, _contributingFactor) {
        const _this = this;
        if (Object.keys(data.data).length > 0) {
            this.svg.style('opacity', '1');
            this.msgElem.current.classList.remove('active');
            this.props[_contributingFactor].isNew = false;
            
            const data_formatted = _this.formatPieData(data.data);
            const extractedValues = data_formatted.map(function (item) {
                return item[1];
            });
            const color = d3.scaleSequential(d3.interpolateReds).domain([Math.min(...extractedValues), Math.max(...extractedValues)])
            // Compute the position of each group on the pie:
            const pie = d3.pie()
                .sort(null)
                .value(d => d[1])

            const data_ready = pie(data_formatted)

            // The arc generator
            const arc = d3.arc()
                .innerRadius(_this.radius * 0.5) // This is the size of the donut hole
                .outerRadius(_this.radius * 0.8)

            // Another arc that won't be drawn. Just for labels positioning
            const outerArc = d3.arc()
                .innerRadius(_this.radius * 0.9)
                .outerRadius(_this.radius * 0.9)

            let overlappedCount = 5, overlappedCountText = 5;
            // Add the polylines between chart and labels:
            const polyline = this.g.selectAll('polyline')
                .data(data_ready)
            polyline.join('polyline')
                // .transition()
                // .duration(500)
                .attr("stroke", "black")
                .style("fill", "none")
                .attr("stroke-width", 1)
                .attr('points', function (d) {
                    const posA = arc.centroid(d) // line insertion in the slice
                    const posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
                    const posC = outerArc.centroid(d); // Label position = almost the same as posB
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                    posC[0] = getPosCXCoordinate(_this.radius, midangle)
                    //to overcome the polyline overlapping on equal/small values
                    if (d.endAngle - d.startAngle < 10 * Math.PI / 180) {
                        overlappedCount--;
                        posB[1] = posB[1] - overlappedCount * 10
                        posC[1] = posC[1] - overlappedCount * 10
                    }
                    return [posA, posB, posC]
                }).style('opacity', .3)

            // Add the polylines between chart and labels:
            const labelText = _this.g.selectAll('text')
                .data(data_ready)
            labelText.join('text')
                // .transition()
                // .duration(500)
                .text(d => `${d.data[1]}%`)
                .attr('transform', function (d) {
                    const pos = outerArc.centroid(d);
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                    pos[0] = _this.radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                    pos[1] = pos[1] + 4;
                    //to overcome the text overlapping on equal/small values
                    if (d.endAngle - d.startAngle < 10 * Math.PI / 180) {
                        overlappedCountText--;
                        pos[1] = pos[1] - overlappedCountText * 10
                    }
                    return `translate(${pos})`;
                })
                .style('text-anchor', function (d) {
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                    return (midangle < Math.PI ? 'start' : 'end')
                }).style('font-size', "12px")

            _this.isSliceHighlighted = null;
            // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
            const path = _this.g.selectAll('path')
                .data(data_ready)
                .join('path')
                .attr("data-ids", function (d) {
                    return data.data_emoc_id[d.data[0]];
                })
                .on("mouseover", function (_e, d) {
                    _this.g.append('text')
                        .attr('class', 'toolCircle')
                        .attr('dy', -15)
                        .html(`<tspan x="0">${d.data[0]}</tspan><tspan x="0" dy="1.2em">${data.data[d.data[0]]}</tspan>`)
                        .style('font-size', '.7em')
                        .style('text-anchor', 'middle')
                    _this.g.append('circle')
                        .attr('class', 'toolCircle')
                        .attr('r', _this.radius * 0.48)
                        .style('fill', color(d.data[1]))
                        .style('fill-opacity', 0.35)
                }).on('mouseout', function () {
                    d3.selectAll('.toolCircle').remove();
                }).on('click', function (_e, _d) {
                    _this.sliceOnCLick(this);
                });

            path
                // .transition()
                // .duration(500)
                .attr('d', arc)
                .attr('class', 'slice')
                .attr('fill', (d, _i) => color(d.data[1]))
                .style("opacity", 0.9)
                .style('cursor', 'pointer');



            _this.svg.selectAll(".legend").remove();
            //Add legend to plot
            const legend = _this.svg.selectAll("legend")
                .data(data_ready)
                .join('g')
                .attr("transform", function (_d, i) {
                    return `translate(${(_this.width / 2 + 110)},${(i * 25 + _this.margin + 20)})`;
                })
                .attr("class", "legend")

            legend.append("rect")
                .attr("width", 10)
                .attr("height", 10)
                .attr("fill", (d, _i) => color(d.data[1]))

            legend.append("text")
                .text(function (d) {
                    return d.data[0];
                })
                .style("font-size", 12)
                .attr("y", 9.5)
                .attr("x", 15);

            // Align the dropdown to legends
            d3.select(".pie-dropdown ").style("transform", `translate(${(this.width / 2 + 110)}px,0px)`);
        } else {
            this.svg.style('opacity', '0');
            this.msgElem.current.classList.add('active');
        }
    }

    render() {
        return (
            <div className='mt-4 position-relative'>
                <select className='pie-dropdown small' style={{ border: "1px solid var(--dark-grey-color)", width: "150px" }} onChange={this.optionOnChange.bind(this)}>
                    <option value="changes">Changes</option>
                    <option value="trends">Trends</option>
                </select>
                <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
                {/* <div style={{ width: "100%", height: "400px" }}> */}
                <svg id="mocPie" ref={this.node} width="100%" height="400"></svg>
                {/* </div> */}
            </div>
        );
    }
}
export default React.memo(DoughNut);
