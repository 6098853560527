import PlotMenu from './plot-menu';
import StackedBarchart from './stacked-bar-chart';
import Heatmap from './heatmap';
import SearchItem from './searchitem';
import LearningItem from './learningitem';
import GenerateReport from './generate-report';
import alertMessage from './alert';
import { filterLoader } from './filter-utils';
import plotInformation from './data/plotInformation.json';
import ShowConsolidatedSummary from './consolidated-summary';


// const riskSummary1="The risk management process is a framework for the actions that need to be taken to manage risk effectively. It is a structured approach to identifying, assessing and reducing risk. The risk management process is a continuous cycle that involves identifying, assessing, controlling and monitoring risks. The process is designed to help organizations make informed decisions about how to manage risk effectively. The risk management process is an essential part of any organization's risk management strategy. It helps organizations identify and assess risks, develop risk management plans, and monitor and control risks. The risk management process is a key component of an organization's risk management strategy. It helps organizations identify and assess risks, develop risk management plans, and monitor and control risks. The risk management process is a key component of an organization's risk management strategy. It helps organizations identify and assess risks, develop risk management plans, and monitor and control risks.";
function graphAnalysis(costgraph, schedulegraph, probabilitygraph, heatmapgraph, riskids,
    consolidatedSummary, consolidatedSummaryLoading, setHeatmapgraphData, ...args) {
    return (
        <div className="risk-graph-analysis graph-analysis mt-3">
            <div className="card card-box-shadow tour-plot">
                <div className="tab-wrapper px-3 pb-3 pt-1">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="cost-tab"
                                data-toggle="tab" href="#cost" role="tab" aria-controls="cost" aria-selected="true">Cost</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="schedule-tab"
                                data-toggle="tab" href="#schedule" role="tab" aria-controls="schedule" aria-selected="false">Schedule</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="probability-tab"
                                data-toggle="tab" href="#probability" role="tab" aria-controls="probability" aria-selected="false">Probability</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="heatmap-tab"
                                data-toggle="tab" href="#heatmap" role="tab" aria-controls="heatmap" aria-selected="false">Heatmap</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link tour-thematic-bt" id="thematicbowtie-tab"
                                data-toggle="tab" href="#thematicbowtietab" role="tab"
                                aria-controls="thematicbowtietab" aria-selected="false" onClick={args[2]}>Thematic Bowtie</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="summary-tab"
                                data-toggle="tab" href="#summary" role="tab"
                                aria-controls="summary" aria-selected="false" onClick={args[3]}>Risk Summary <span className="beta-tag">(&beta;)</span></a>
                        </li>
                    </ul>
                    <div className="tab-content border-bottom pb-4" id="myTabContent">
                        {filterLoader('filter-loader-riskplot')}
                        <div className="tab-pane fade graph show active" id="cost" role="tabpanel" aria-labelledby="cost-tab">
                            <div className="p-2 f-screen bg-white">
                                <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="cost-c-btn" fullscreenSelector={"cost"} info={plotInformation.risk.cost} />
                                <StackedBarchart key={1} data={costgraph} stackedBarChartFIlterCallback={args[1]} type={"cost"} dataType="risk" />
                            </div>
                        </div>
                        <div className="tab-pane fade graph" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                            <div className="p-2 f-screen bg-white">
                                <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="schedule-c-btn" fullscreenSelector={"schedule"} info={plotInformation.risk.schedule} />
                                <StackedBarchart key={2} data={schedulegraph} stackedBarChartFIlterCallback={args[1]} type={"schedule"} dataType="risk" />
                            </div>
                        </div>
                        <div className="tab-pane fade graph" id="probability" role="tabpanel" aria-labelledby="probability-tab">
                            <div className="p-2 f-screen bg-white">
                                <PlotMenu showRefreshBtn={true} title="Clear Selection" customClass="probability-c-btn" fullscreenSelector={"probability"} info={plotInformation.risk.probability} />
                                <StackedBarchart key={3} data={probabilitygraph} stackedBarChartFIlterCallback={args[1]} type={"probability"} dataType="risk" />
                            </div>
                        </div>
                        <div className="tab-pane fade graph" id="heatmap" role="tabpanel" aria-labelledby="heatmap-tab">
                            <div className="p-2 f-screen bg-white">
                                <PlotMenu showRefreshBtn={true} customClass={"heatmap-refresh-btn"} title="Clear Selection/Refresh Heatmap" fullscreenSelector={"heatmap"} info={plotInformation.risk.heatmap}/>
                                <div className="mt-3 small">
                                    <div className="d-flex text-center heatmap-dropdowns">
                                        <div className="flex-fill xaxis-dropdown"></div>
                                        <div className="flex-fill yaxis-dropdown"></div>
                                        <div className="flex-fill value-dropdown"></div>
                                    </div>
                                    <div className="heatmap-name font-weight-bold text-center mt-3"></div>
                                </div>

                                <div className="heatmap-wrapper">
                                    <Heatmap data={heatmapgraph} indices={riskids} heatmapFilterCallback={args[0]}
                                        setHeatmapgraph={setHeatmapgraphData} />
                                    <div id="heatmaplegend"></div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade graph" id="consolidated" role="tabpanel" aria-labelledby="consolidated-tab">
                            <div className="p-2 f-screen bg-white">
                                <ShowConsolidatedSummary data={consolidatedSummary} loading={consolidatedSummaryLoading}></ShowConsolidatedSummary>
                            </div>
                            <div className='declare-type'>This is the consolidated summary</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function riskResults(result, filterCountsAndStatus, callBack, reportCallback, generateReportData, generateRportBtnRef) {
    return (
        <div className="search-results">
            <div className='mx-2'>{alertMessage(result.risk.n_exact_results, result.risk.risk_id.length, "Risk Management")}</div>
            <div className="d-flex justify-content-between my-2 mx-3 small  align-items-center">
                <div className="text-monospace">Showing top&nbsp;
                    <span className="result-count">{filterCountsAndStatus.searchresultcount}</span> result(s)<span></span></div>
                <div className="generate-report-placeholder tour-generate-report-results">
                    <GenerateReport data={generateReportData} type={'risk'} generateBtnRef={generateRportBtnRef}/>
                </div>
            </div>
            <div className="search-results-inner tour-results">
                {
                    (result.risk.risk_id.length > 0 ? (
                        result.riskFormatted.map(({
                            risk_id, title, subtitle, category, country, project, lob, desc,
                            risk_L0, risk_L1, risk_L2, severity, flag, _phy_L2, phy_L3, _phy_L4, risk_modified
                        }) => {
                            return (<SearchItem key={risk_id} flagcode={flag} riskid={risk_id} riskTitle={title} subTitle={subtitle}
                                category={category} country={country} project={project} lob={lob} desc={desc} riskLZero={risk_L0}
                                riskLOne={risk_L1} riskLTwo={risk_L2} physicalLThree={phy_L3}
                                severity={severity} parentCallback={callBack} reportCallback={reportCallback} riskModified={risk_modified}
                                mostRelevantList={result.risk.most_relevant} notRelevantList={result.risk.non_relevant}
                                globalMR={result.risk.global_most_relevant} globalNR={result.risk.global_not_relevant} />);
                        })
                    ) :
                        <span className="small d-block text-center my-4">No Risk data found for this query.</span>
                    )
                }
            </div>
        </div>
    );
}

function learningResults(result, reportLessonCallback, lessonReportData) {
    return (
        <>
            <div className='mx-2 mt-2'>{alertMessage(result.lesson.n_exact_results, result.lesson.ll_id.length, "Learnings")}</div>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2 mx-3 small">
                <div className="text-monospace">Showing top&nbsp;
                    <span className="result-count">
                        {result !== null && Object.keys(result.lesson).length > 1 ? result.lesson.ll_id.length : 0}</span> result(s)</div>
                <div>
                    <GenerateReport data={lessonReportData} type={'lesson'} />
                </div>
            </div>
            <div className="mt-3 learnings-inner">
                {
                    (result.lesson.ll_id.length > 0 && Object.keys(result.lesson).length > 1 ? (
                        result.lesson.ll_title.map((title, index) => {
                            const desc = result.lesson.ll_desc[index];
                            const ll_id = result.lesson.ll_id[index];
                            const subTitle = desc.slice(0, 250);
                            return (<LearningItem key={ll_id} ll_id={ll_id} title={title}
                                subTitle={subTitle} desc={desc} reportLessonCallback={reportLessonCallback} summary={result.lesson.ll_desc_summary[index]}
                                mostRelevantList={result.lesson.most_relevant} notRelevantList={result.lesson.non_relevant}
                                globalMR={result.lesson.global_most_relevant} globalNR={result.lesson.global_not_relevant} />);
                        })
                    ) : <span className="small d-block text-center my-4">No Learnings found for this query.</span>)
                }
            </div>
        </>
    );
}

export { graphAnalysis, riskResults, learningResults };
