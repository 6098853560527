import ClipLoader from 'react-spinners/ClipLoader'
import React, { useRef, useState, useEffect } from 'react';



const RaceSpinner = ({ isSummarySpinner, outsideClickCallback }) => {
    const [visible, setVisible] = useState(true);
    const spinnerRef = useRef(null);
    useEffect(() => {
        if (!isSummarySpinner) return;
        function handleClickOutside(event) {
            if (spinnerRef.current && !spinnerRef.current.contains(event.target)) {
                setVisible(false);
                outsideClickCallback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [spinnerRef]);

    if (!visible) return null;

    return (
        <div className="racespinner-wrapper text-center">
            <div className="racespinner-wrapper-inner d-flex justify-content-center align-items-center">
                <div className="racespinner shadow" ref={spinnerRef} style={{ backgroundColor: "var(--main-bg-color)", padding: "2rem 0px" }}>
                    <ClipLoader size={35} color={"#404040"} loading={true} />
                    <div className='mt-2 small'>{isSummarySpinner ? 'Fetching summary...' : 'Fetching results...'}</div>
                </div>
            </div>
        </div>
    )
};
export default RaceSpinner;

