import utils from "../app-util";
import { LoginUtils } from "../login";
class IdleTimer {
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(sessionStorage.getItem("_expiredTime"), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
    this.renewInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(sessionStorage.getItem("_expiredTime"), 10);
      if (expiredTime < Date.now() && this.onTimeout) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 1000);
  }
  renewInterval() {
    setInterval(() => {
      //Renew token
      const tokExpTime = utils.states.state.getET() - 2 * 60 * 1000;
      if (tokExpTime !== null && tokExpTime < Date.now() && Date.now() < parseInt(sessionStorage.getItem("_expiredTime"), 10)) {
        const token = new LoginUtils();
        token.getToken();
      }
    }, 1 * 60 * 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      sessionStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    sessionStorage.removeItem("_expiredTime");
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}
export default IdleTimer;
