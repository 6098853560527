import React from "react";
import ShowLoader from "./summaryloader";

function showConsolidatedSummary({ data, loading }) {
    var lines = [];
    if (data.risk_insights_summary !== undefined) {
        lines = data.consolidated_summary.split("\n");
    }
    return (
        <div>
            {loading ? (
                <div> Generating Summary. Please wait .. !!
                    <span className="consolidated-loader"><ShowLoader></ShowLoader></span>
                </div>

            ) : (
                <div className="summaryLoaderCons">
                    {data.consolidated_summary !== undefined ? (
                        <div>
                            <h1>"Found something"</h1>
                        </div>
                    ) : (
                        <div> 
                            <h1>"Found nothing</h1>
                        </div>

                    )
                }
                </div>
            )
        }
        </div>
    );
}

export default showConsolidatedSummary;
