import React from 'react';
import { toast } from 'react-toastify';
import utils from './app-util';
import report from '../assets/report.svg';
import trash from '../assets/trash_alt.svg';
import getAllReports from './report-utils';
function enableSpinner(element) {
    element.classList.add('active');
}
function disableSpinner(element) {
    element.classList.remove('active');
}
const GENERATE_TEXT_MSG = "Generate Report";
class DownloadReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            openlayer: false,
            reportGenerated: false,
            isDownloaded: false
        };
        this.refreshDownloadList = null;
        this.layerCloseOnClick = this.layerCloseOnClick.bind(this);
    }
    componentDidMount() {
        this.refreshDownloadList = document.querySelector(".download-list-refresh");
        this.refreshDownloadList.addEventListener('click', this.refreshOnclick.bind(this));
        this.loadDownloadReports();

        //Close the dropdown on clicking anywhere
        document.body.addEventListener('click', function (e) {
            const parent = document.getElementById("downloadReport");
            if (!utils.parentContainsChild(parent, e.target) && 
                !e.target.classList.contains('download-report-toggle-icon') && 
                !e.target.classList.contains('green-overlay-close') && 
                !e.target.classList.contains('download-report-toggle')) {
                document.querySelector(".download-report-toggle")?.classList.add('collapsed');
                document.querySelector("#downloadReport")?.classList.remove("show");
            }
        });
        this.getGeneratedReportStatus();
    }
    /**
     * status 0: Generated report downloaded
     * status 1: Report generation completed
     * status 2: Report generation in progress(yet to be implemented)
     */
    getGeneratedReportStatus() {
        fetch(`/last-generated-report-status`, {
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(res => {
                return utils.checkSessionStatus(res);
            })
            .then(
                (results) => {
                    const generatebutton = document.querySelector(".generate-btn");
                    if (results.status === 1) {
                        this.setState({ openlayer: true, reportGenerated: true, isDownloaded: false });
                        generatebutton.textContent = results.message;
                        generatebutton.disabled = false;
                        generatebutton.classList.add('downloadnow');
                    } else if (results.status === 2) {
                        //if the generation is in progress
                        generatebutton.textContent = "Generating..."
                        generatebutton.disabled = true;
                        generatebutton.classList.remove('downloadnow');
                    } else {
                        generatebutton.textContent = "Generate"
                        generatebutton.disabled = false;
                        generatebutton.classList.remove('downloadnow');
                    }
                },
                (_err) => {
                    console.error("Status not found");
                }
            );
    }
    resendRequestToGetStatus(downloadOptionSection) {
        const statusInterval = setInterval(() => {
            if (this.state.reportGenerated || this.state.isDownloaded) {
                clearInterval(statusInterval);
                this.setState({ reportGenerated: false });
                this.enableDownloadOptionSection(downloadOptionSection);
            }
            this.getGeneratedReportStatus();
        }, 20000);
    }
    
    componentWillUnmount() {
        this.refreshDownloadList.removeEventListener('click', this.refreshOnclick.bind(this));
    }
    refreshOnclick(_e) {
        this.loadDownloadReports();
    }
    loadDownloadReports() {
        getAllReports(this.reportList, this.refreshDownloadList);
    }
    reportList = (results) => {
        this.setState({ items: results });
    }
    onClick(e) {
        const downloadBtn = e.target;
        const reports = document.querySelectorAll(".download-report-inner ul li input[type='checkbox']"), reportNames = [];
        for (let i = 0; i < reports.length; i++) {
            if (reports[i].checked) {
                reportNames.push(reports[i].value);
            }
        }
        if (reportNames.length > 0) {
            const downloadInterval = setInterval(() => {
                const rName = reportNames.pop();
                utils.makeDownloadRequest(`/download-report/${rName}`, rName, downloadBtn, "Download")
                if (reportNames.length === 0) {
                    document.querySelectorAll(".download-report-inner ul li input[type='checkbox']:checked").forEach(function (element) {
                        element.checked = false;
                    });
                    clearInterval(downloadInterval);
                }
            }, 1000);
        } else {
            if (reports.length === 0) {
                toast.warn("No report found in the existing report list");
            } else {
                toast.warn("Please select from the existing report list");
            }
        }
    }

    iterateElement(items, n_records) {
        const temp = [];
        for (let i = 0; i < items.length; i++) {
            if (i === parseInt(n_records, 10)) {
                break;
            }
            temp.push(items[i].dataset.id);
        }
        return temp;
    }
    getIDs(type, n_records) {
        let ids = [];
        if (type === 'Risk') {
            const items = document.querySelectorAll('.search-item');
            ids = this.iterateElement(items, n_records);
        }
        if (type === 'Learning') {
            const items = document.querySelectorAll('.learn-item');
            ids = this.iterateElement(items, n_records);
        }
        if (type === 'moc') {
            const items = document.querySelectorAll('.emoc-item');
            ids = this.iterateElement(items, n_records);
        }
        if (type === 'Quality') {
            const items = document.querySelectorAll('.quality-item');
            ids = this.iterateElement(items, n_records);
        }
        return ids;
    }
    getRiskOptions(datatype) {
        const options = [];
        document.querySelectorAll(`.top-report-option.top-n-${datatype} input[name='topreportoption[]']:checked`).forEach(function (element) {
            options.push(element.value);
        })
        return options;
    }
    enableDownloadOptionSection(downloadOptionSection) {
        downloadOptionSection.style.pointerEvents = 'all';
        downloadOptionSection.style.opacity = '1';
    }
    disableDownloadOptionSection(downloadOptionSection) {
        downloadOptionSection.style.pointerEvents = 'none';
        downloadOptionSection.style.opacity = '0.3';
    }
    saveReportDetailInLS(name) {
        const query = sessionStorage.getItem('query');
        localStorage.setItem('rdetail', JSON.stringify({ name: name, query: query }));
    }
    getReportDetailFromLS() {
        if (window.localStorage['rdetail']) {
            return JSON.parse(localStorage.getItem('rdetail'));
        } else {
            return { name: "Name not recorded", query: "Query not recorded" };
        }
    }
    generateReportOnSuccess(results, generateBtn, downloadOptionSection) {
        if (results.status) {
            this.enableDownloadOptionSection(downloadOptionSection);
            generateBtn.disabled = false;
            generateBtn.classList.add('downloadnow');
            generateBtn.textContent = "Download Now";
            this.setState({ openlayer: true });
            toast.success("Successfully generated the report");
        } else {
            this.enableDownloadOptionSection(downloadOptionSection);
            generateBtn.disabled = false;
            generateBtn.textContent = GENERATE_TEXT_MSG;
            toast.warn("Failed to generate the report");
        }
    }
    generateBtnOnClick(e) {
        const generateBtn = e.target;
        const downloadOptionSection = document.querySelector(".download-option-section");
        const selectedOption = document.querySelectorAll(".download-report-inner ul li input[name='topdownload']:checked")[0];
        const downloadtype = selectedOption.dataset.downloadtype, downloadValue = selectedOption.value;
        const riskOptions = this.getRiskOptions(downloadtype.toLowerCase());
        if (!generateBtn.classList.contains("downloadnow")) {
            //Save the generated report details for reference to check if report generation completed for the saved data
            this.saveReportDetailInLS(`Top ${downloadValue} ${downloadtype}`);
            generateBtn.disabled = true;
            generateBtn.textContent = "Generating...";
            const payloadGR = {
                query: sessionStorage.getItem('query'),
                ids: this.getIDs(downloadtype, downloadValue),
                report_type: downloadtype,
                report_data: downloadtype === 'Risk' || downloadtype === 'Quality' ? riskOptions : [],
                n_records: downloadValue
            }
            this.disableDownloadOptionSection(downloadOptionSection);
            this.setState({ isDownloaded: false });
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const results = JSON.parse(xhr.responseText);
                    this.generateReportOnSuccess(results, generateBtn, downloadOptionSection);
                } else {
                    utils.checkSessionStatus(xhr.response, true);
                }
            }
            xhr.onerror = () => {
                if (xhr.response !== "") {
                    this.enableDownloadOptionSection(downloadOptionSection);
                    generateBtn.disabled = false;
                    generateBtn.textContent = GENERATE_TEXT_MSG;
                    toast.error("Error occured");
                }
            }
            xhr.ontimeout = (_e) => {
                console.warn("Request Timed out");
            }
            xhr.onabort = () => {
                console.log("Aborted");
            }
            xhr.open('POST', '/generate-report', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader("X-CSRFToken", utils.csrf());
            xhr.setRequestHeader('Authorization', `Bearer ${window.sessionStorage.getItem("_at")}`);
            xhr.timeout = 5 * 60 * 1000;
            xhr.send(JSON.stringify(payloadGR));
            this.resendRequestToGetStatus(downloadOptionSection);
        } else {
            // reset the reportGenerated value to false before download to check the status with time interval
            this.setState({ openlayer: false, reportGenerated: false, isDownloaded: true });
            generateBtn.classList.remove('downloadnow');
            const reportName = `${this.getReportDetailFromLS().name}s - ${this.getReportDetailFromLS().query}`;
            utils.makeDownloadRequest(`/download-generated-report/${reportName}`, reportName, generateBtn, GENERATE_TEXT_MSG);
        }

    }
    topDownloadOnChange(e) {
        const generateBtn = document.querySelector(".generate-btn");
        const downloadTypeSelection = e.currentTarget.dataset.downloadtype;
        let filterSelection =""
        if ( !(downloadTypeSelection === "Learning" ||  downloadTypeSelection === "moc")){
            filterSelection = `:not(.top-n-${downloadTypeSelection.toLowerCase()})`
        }
        const notSelectedOptionsIfchecked = document.querySelectorAll(`.top-report-option${filterSelection} input[name='topreportoption[]']:checked`);
        notSelectedOptionsIfchecked.forEach(function (option) {
            if (!option.disabled) {
                option.checked = false;
            }
        });
        if (!generateBtn.disabled) {
            generateBtn.classList.remove('downloadnow');
            generateBtn.textContent = GENERATE_TEXT_MSG;
        }
    }
    layerCloseOnClick() {
        this.setState({ openlayer: false, reportGenerated: false });
    }
    deleteExistingReport(name, controlElement) {
        const refreshDownloadList = this.refreshDownloadList;
        enableSpinner(controlElement);
        fetch(`/delete-existing-report/${name}`, {
            method: 'DELETE',
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        }).then(function (response) {
            if (response.status !== 200) {
                disableSpinner(controlElement);
                utils.checkSessionStatus(response);
                console.log('Error Status Code On Delete: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    disableSpinner(controlElement);
                    refreshDownloadList.click();
                    toast.success(data.message);
                } else {
                    disableSpinner(controlElement);
                    toast.warn(data.message);
                }
            });
        }).catch(function (err) {
            disableSpinner(controlElement);
            console.log('Error occured on delete:', err);
        });
    }
    deleteOnClick(e) {
        e.preventDefault();
        const confirmation = window.confirm(`Do you want to delete the report "${e.target.dataset.name}"?`);
        if (confirmation) {
            this.deleteExistingReport(e.target.dataset.name, e.target);
        }
    }
    render() {
        return (
            <>
                <a className="download-report-toggle text-decoration-none tour-report-download"
                    id="downloadDropdownlink" href="#downloadReport" role='button' data-toggle="collapse" aria-expanded="false" aria-controls="downloadReport">
                    <img className="mr-1 download-report-toggle-icon" alt="" src={report} />
                </a>
                <div id="downloadReport" className="download-report-wrapper collapse">
                    <div className="download-report-inner">
                        <div className="download-list-refresh small"></div>
                        <div className='download-report-list-wrapper d-flex p-2'>
                            <div className='col'>
                                {
                                    this.state.openlayer ?
                                        <div className='position-absolute d-flex align-items-center justify-content-center fade'
                                            style={{ width: '94%', height: '85%', zIndex: 10, backgroundColor: 'var(--success-color)', opacity: '.95', color: "#ffffff" }}>
                                            <button type='button' className='position-absolute green-overlay-close'
                                                style={{ top: '0px', right: '0px', color: '#ffffff', backgroundColor: 'transparent', border: 'none' }}
                                                title="Close" onClick={this.layerCloseOnClick}>&times;</button>
                                            <div className='text-center'>
                                                <span className='info-icon info-white mr-1 mx-auto'>i</span>
                                                Generated report {`"${this.getReportDetailFromLS().name}"`} is ready to be download.
                                            </div>
                                        </div> : null
                                }

                                <div className='d-flex download-option-section'>
                                    <div className='col border-left border-right top-n-download-risk'>
                                        <h5 className='font-weight-bold text-uppercase small'>Risk</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 20"}>
                                                    <input type="radio" className='topdownload' name="topdownload"
                                                        data-downloadtype={"Risk"} value={20} defaultChecked={true}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 20"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 30"}>
                                                    <input type="radio" className='topdownload' name="topdownload"
                                                        data-downloadtype={"Risk"} value={30} onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 30"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 50"}>
                                                    <input type="radio" className='topdownload' name="topdownload"
                                                        data-downloadtype={"Risk"} value={50} onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 50"}</label>
                                            </li>
                                        </ul>
                                        <div className='ml-1 small'>
                                            <span className=''>Please choose the below options</span>
                                            <section className='top-report-option top-n-risk d-flex justify-content-between'>
                                                <div className=''>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]"
                                                            id="option1" value="Risk" defaultChecked disabled />
                                                        <label className="form-check-label" htmlFor="option1">Risk</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="option2" value="Action" />
                                                        <label className="form-check-label" htmlFor="option2">Action</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="option5" value="Quality" />
                                                        <label className="form-check-label" htmlFor="option5">Quality</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="option3" value="LFE" />
                                                        <label className="form-check-label" htmlFor="option3">LFE</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="option4" value="MOC" />
                                                        <label className="form-check-label" htmlFor="option4">MOC</label>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className='col border-right'>
                                        <h5 className='font-weight-bold text-uppercase small'>Learnings</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 20"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Learning"} value={20}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 20"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 30"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Learning"} value={30}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 30"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 50"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Learning"} value={50}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 50"}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col border-right'>
                                        <h5 className='font-weight-bold text-uppercase small'>eMoC</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 20"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"moc"} value={20}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 20"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 30"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"moc"} value={30}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 30"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 50"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"moc"} value={50}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 50"}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col border-right top-n-download-quality'>
                                        <h5 className='font-weight-bold text-uppercase small'>Quality</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 20"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Quality"} value={20}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 20"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 30"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Quality"} value={30}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 30"}</label>
                                            </li>
                                            <li className="list-group-item">
                                                <label className="m-0" title={"Top 50"}>
                                                    <input type="radio" className='topdownload' name="topdownload" data-downloadtype={"Quality"} value={50}
                                                        onChange={this.topDownloadOnChange.bind(this)} />&nbsp;{"Top 50"}</label>
                                            </li>
                                        </ul>
                                        <div className='ml-1 small'>
                                            <span className=''>Please choose the below options</span>
                                            <section className='top-report-option top-n-quality d-flex justify-content-between'>
                                                <div className=''>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]"
                                                            id="qualityoption1" value="Quality" defaultChecked disabled />
                                                        <label className="form-check-label" htmlFor="qualityoption1">Quality</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="qualityoption2" value="Action" />
                                                        <label className="form-check-label" htmlFor="qualityoption2">Action</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="qualityoption5" value="Risk" />
                                                        <label className="form-check-label" htmlFor="qualityoption5">Risk</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="qualityoption3" value="LFE" />
                                                        <label className="form-check-label" htmlFor="qualityoption3">LFE</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="topreportoption[]" id="qualityoption4" value="MOC" />
                                                        <label className="form-check-label" htmlFor="qualityoption4">MOC</label>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-2'>
                                    <button className="btn btn-sm rounded-0 w-100 generate-btn"
                                        style={{ backgroundColor: 'var(--yellow-bg-color)', color: '#ffffff' }}
                                        onClick={this.generateBtnOnClick.bind(this)}>Generate Report</button>
                                </div>
                            </div>
                            <div className='col'>
                                <div className=''>
                                <div><h5 className='font-weight-bold text-uppercase small d-block'>Existing Reports</h5></div>
                                <div><ul className="list-group list-group-flush d-block" style={{ height: "195px" }}>
                                    {
                                        this.state.items.length > 0 ? this.state.items.map((item, _index) => {
                                            if (item[0] !== null) {
                                                return <li key={item[0]} className="list-group-item">
                                                    <div className='d-flex justify-content-between'>
                                                        <label className="m-0" title={item[0]}><input type="checkbox" value={item[0]} />&nbsp;{item[0]}</label>
                                                        <div><img className='ml-2 existing-report-delete-btn' src={trash} alt='Delete'
                                                            style={{ width: "12px", cursor: "pointer" }} data-name={item[0]} onClick={this.deleteOnClick.bind(this)} />
                                                            <div className="Toastify__spinner existing-report-spinner"></div></div>
                                                    </div></li>;
                                            } else {
                                                return null;
                                            }
                                        }) : <li className="list-group-item p-2 my-3">No reports found</li>
                                    }
                                </ul></div>
                                <div className='position-absolute w-100' style={{bottom: 0, left: 0}}><button className="btn btn-sm rounded-0 w-100 download-btn align-self-end" onClick={this.onClick.bind(this)}>Download</button></div>
                                </div>
                            </div>
                        </div>

                        {/* <button className="btn btn-sm rounded-0 w-100 download-btn" onClick={this.onClick.bind(this)}>Download</button> */}
                    </div>
                </div>
            </>
        );
    }
}
export default DownloadReport;
