import React from "react";
import { getPromptResponse } from "./services";
import Loader from "./Loader";

const ActionProvider = (props) => {
  const { createChatBotMessage, setState, children } = props;

  const ERRORMESSGAGE = 'It seems like we’re having trouble with that request. Please try again latter'

  const handleUserMessage = (message) => {
    //add loading untill api data gets fetched
    const loading = createChatBotMessage(<Loader />);
    setState((prev) => ({ ...prev, loading: true, messages: [...(prev?.messages || []), loading] }));
    // get response from api as per the typed input
    getPromptResponse({
      query: message,
      onSuccess: (res) => {
        const responseVal = res?.response;
        let botMessage = ''
        if (responseVal && typeof responseVal === 'string') {
          if (responseVal.startsWith('Error code')) {
            botMessage = 'Maximum limit reached. Please try after again later.';
            console.error(responseVal);
          } else {
            botMessage = responseVal;
          }
        }
        const chatbotMessage = createChatBotMessage(botMessage);
        setState((prev) => {
          const newPrevMsg = prev?.messages.slice(0, -1); // Remove Loading here
          return { ...prev, loading: false, messages: [...newPrevMsg, chatbotMessage] };
        });
      },
      onError: (err) => {
        const botMessage = createChatBotMessage(  <div dangerouslySetInnerHTML={{ __html: ERRORMESSGAGE }} />);
        setState((prev) => {
          const newPrevMsg = prev?.messages.slice(0, -1); // Remove Loading here
          return { ...prev, loading: false, messages: [...newPrevMsg, botMessage] };
        });
      },
    });
  };

  const clearChatMessages = () => {
    setState(prev => {
      //except for initial message, clear other
      const slicedMessage = prev.messages.slice(0, 1);
      return ({...prev, messages: slicedMessage});
    })
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleUserMessage,
            clearChatMessages
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;