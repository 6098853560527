import React, { useState, useMemo, useEffect, useCallback } from 'react';
import DataGrid, { SelectCellFormatter } from 'react-data-grid';
import AcronymCellFormatter from './acronymCellFormatter';
import Toolbar from './data-grid-toolbar';
import ActionButton from './delete-formatter';
import { FilterRenderer, FilterContext, EmptyRowsRenderer, rowKeyGetter, stopPropagation, togglefilterClass } from './user-data-grid';
import utils from '../app-util';
import { toast } from 'react-toastify';
import RaceSpinner from '../spinner';
import ProjectFormPopup from './project-form-popup';

const HEADER_CLASSNAME = "filter-cell";

function deleteProject(rows, objProp, setRows) {
    const indexOfDeletedRow = rows.findIndex(function (item, _i) {
        return item.id === objProp.row.id
    });
    const deletedRow = rows[indexOfDeletedRow];
    if (window.confirm(`Do you want to delete the row?`)) {
        fetch('/delete-project/' + deletedRow.id, {
            method: 'DELETE',
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        }).then(function (response) {
            if (response.status !== 200) {
                utils.checkSessionStatus(response);
                console.log('Error Status Code On Delete: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    toast.success(data.message);
                    // Delete the element using index and Set the state to update the grid
                    setRows([...rows.slice(0, indexOfDeletedRow), ...rows.slice(indexOfDeletedRow + 1)]);
                } else {
                    toast.warn(data.message);
                }
            });
        }).catch(function (err) {
            console.log('Error occured on delete:', err);
        });
    }

}
function editOnClick(objProp, setEditdata, setOpenprojectpopup) {
    setEditdata(objProp.row);
    setOpenprojectpopup(true);
}
function projectRowsFilter(rows, filters) {
    return rows.filter((r) => {
        return (
            (filters.project ? Object.keys(r.project).join().toLowerCase().includes(filters.project.toLowerCase()) : true) &&
            (filters.acronym ? Object.keys(r.acronym).join().toLowerCase().includes(filters.acronym.toLowerCase()) : true) &&
            (filters.mask_status !== 'All' ? r.mask_status.toString() === filters.mask_status : true)
        );
    });
}
function handleHeaderCellOnClick(e, setsortColumns) {
    if (!e.target.classList.contains("filter-inputclass-name")) {
        // Enable filter functionality by passing the columnkey and sort direction
        const columnKey = e.currentTarget.children[0].dataset.key;

        const sort = e.currentTarget.getAttribute("aria-sort", "descending");
        if (sort === "descending") {
            setsortColumns([{ columnKey: columnKey, direction: 'ASC' }]);
        } else if (sort === "ascending") {
            setsortColumns([])
        } else {
            setsortColumns([{ columnKey: columnKey, direction: 'DESC' }]);
        }
    }
}
const textfieldRenderer = function (setFilters, field_name) {
    return (p) => (
        <>
            <FilterRenderer {...p}>
                {({ filters, ...rest }) => (
                    <input
                        {...rest}
                        className='filter-inputclass-name'
                        value={filters['field_name']}
                        onChange={(e) => {
                            let val = {};
                            val = { ...filters };
                            val[field_name] = e.target.value
                            setFilters(val)
                        }
                        }
                        onKeyDown={stopPropagation}
                    />
                )}
            </FilterRenderer>
        </>
    );
}

function boolfieldWrapper(setFilters, columnKey) {
    return (p) => {
        return (
            <>
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <select
                            {...rest}
                            className='filter-inputclass-name'
                            value={filters[columnKey]}
                            onChange={(e) => {
                                let obj = {}
                                obj = { ...filters }
                                obj[columnKey] = e.target.value
                                setFilters(obj)
                            }
                            }
                            onKeyDown={stopPropagation}
                        >
                            <option value="All">All</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                        </select>
                    )}
                </FilterRenderer>
            </>
        );
    }
}

function checkboxFormatter(columnKey) {
    return function ({ row, onRowChange, isCellSelected }) {
        let obj = {}
        obj = { ...row }
        obj[columnKey] = !row[columnKey]
        return (
            <SelectCellFormatter
                value={row[columnKey]}
                onChange={() => {
                    onRowChange(obj);
                }}
                onClick={stopPropagation}
                isCellSelected={isCellSelected}
            />
        );
    }
}

const acronymFormatter = ({ row }) => {
    return (
        <AcronymCellFormatter content={row.acronym} />
    );
}

const projectFormatter = ({ row }) => {
    return (
        <>{Object.keys(row.project)[0]}<span className={`grid-projectname project-acronym-case ml-1 ${row.project[Object.keys(row.project)[0]] ? "case-sensitive":""}`}></span></>
    );
}

function columnsFormat(rows, deleteTheProject, setFilters, setRows, setEditdata, setOpenprojectpopup, setIsEditOn) {
    return [
        {
            key: 'project', name: 'Project Title', headerCellClass: HEADER_CLASSNAME, headerRenderer: textfieldRenderer(setFilters, 'project'),
            formatter: projectFormatter, editable: false
        },
        {
            key: 'acronym', name: 'Acronyms', headerCellClass: HEADER_CLASSNAME, headerRenderer: textfieldRenderer(setFilters, 'acronym'),
            formatter: acronymFormatter, editable: false
        },
        {
            key: 'mask_status', name: 'Masked', width: 160, headerCellClass: HEADER_CLASSNAME, headerRenderer: boolfieldWrapper(setFilters, 'mask_status'),
            formatter: checkboxFormatter('mask_status'), editable: false
        },
        {
            key: 'action', name: 'Actions', width: 100, sortable: false,
            formatter(objProp) {
                return (
                    <ActionButton edit={true}
                        onClick={(_args) => {
                            deleteTheProject(rows, objProp, setRows);
                        }}
                        editOnClick={(_args) => {
                            setIsEditOn(true)
                            editOnClick(objProp, setEditdata, setOpenprojectpopup);
                        }}
                    />
                );
            }
        }
    ]
}
function loadProjects(setLoading, setRows) {
    setLoading(true);
    fetch('/getmaskeddetail', {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                // Format the data to be grid ready
                setRows(data);
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Fetch Error :', err);
        });
}
export default function ProjectDataGrid() {
    const [rows, setRows] = useState([]);
    const filterColumnObj = { project: "", acronym: "", mask_status: "All", enabled: false };
    const [filters, setFilters] = useState(filterColumnObj);
    const [sortcolumn, setsortColumn] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openprojectpopup, setOpenprojectpopup] = useState(false);
    const [editdata, setEditdata] = useState(null);
    const [isEditOn, setIsEditOn] = useState(false);
    useEffect(() => {
        loadProjects(setLoading, setRows);

        setTimeout(() => {
            document.querySelectorAll('.rdg-cell[role="columnheader"]').forEach((e) => {
                e.addEventListener('click', headerOnClick);
            })
        }, 2000);
    }, [])

    const onSortColumnsChange = useCallback((sortcolumns) => {
        setsortColumn(sortcolumns.slice(-1));
    }, []);
    const gridColumns = useMemo(() => {
        return columnsFormat(rows, deleteProject, setFilters, setRows, setEditdata, setOpenprojectpopup, setIsEditOn);
    }, [rows]);

    function headerOnClick(e) {
        handleHeaderCellOnClick(e, setsortColumn);
    }
    const filteredRows = useMemo(() => {
        return projectRowsFilter(rows, filters);
    }, [rows, filters]);

    const gridSortedRows = useMemo(() => {
        if (sortcolumn.length === 0) {
            return filteredRows;
        }
        const { columnKey, direction } = sortcolumn[0];

        let gridSortedRowss = [...filteredRows];

        switch (columnKey) {
            case 'project':
                gridSortedRowss = gridSortedRowss.sort((a, b) => Object.keys(a[columnKey])[0].localeCompare(Object.keys(b[columnKey])[0]));
                break;
            case 'acronym':
                gridSortedRowss = gridSortedRowss.sort((a, b) => Object.keys(a[columnKey]).join(' ').localeCompare(Object.keys(b[columnKey]).join(' ')))
                break;
            case 'mask_status':
                gridSortedRowss = gridSortedRowss.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            default:
        }
        return direction === 'DESC' ? gridSortedRowss.reverse() : gridSortedRowss;
    }, [filteredRows, sortcolumn]);
    function toggleGridFilters(v = true) {
        togglefilterClass()
        setFilters((_filters) => ({ ..._filters, enabled: v ? !_filters.enabled : _filters.enabled }));
    }
    function clearGridFilters() {
        togglefilterClass(true);
        setFilters(filterColumnObj);
    }
    function onGridRowUpdate(prop, updated) {
         const indexUpdatedRow = updated.indexes[0];
         const rowUpdated = prop[indexUpdatedRow];
        fetch('/update-project', {
            method: "POST",
            headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            body: JSON.stringify(rowUpdated)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    utils.checkSessionStatus(response)
                    console.log('Error Status Code: ' + response.status);
                    return;
                }
                response.json().then(function (data) {
                    if (data.success) {
                        setRows(prop);
                        toast.success(data.message);
                    } else {
                        toast.warn(data.message);
                    }
                });
            }).catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }
    function onProjectAdd() {
        setOpenprojectpopup(true);
    }
    function afterProjectSubmit(row, isEditMode) {
        const gridRows = [...rows];
        if (isEditMode) {
            const indexOfUpdatedRow = gridRows.findIndex(function (item, _i) {
                return item.id === row.id
            });
            gridRows[indexOfUpdatedRow].project = row.project;
            gridRows[indexOfUpdatedRow].acronym = row.acronym;
            gridRows[indexOfUpdatedRow].mask_status = row.mask_status ? true: false;
        } else {
            gridRows.push({ id: gridRows.length + 1, project: row.project, acronym: row.acronym, mask_status: row.mask_status });
        }
        setRows(gridRows);
        loadProjects(setLoading, setRows);
        setOpenprojectpopup(false);
        setIsEditOn(false);
    }
    function afterPopupClose(value) {
        setOpenprojectpopup(value);
        // If edit mode is On reset it after popup close
        if(isEditOn) {
            setIsEditOn(false);
        }
    }
    return (
        <div className='p-2'>
            <Toolbar enableFilter={true} onAddRow={onProjectAdd} onToggleFilter={toggleGridFilters} onClearFilter={clearGridFilters} addRowButtonText={'Add New Project'} />
            <FilterContext.Provider value={filters}>
                <DataGrid className='' rowKeyGetter={rowKeyGetter} columns={gridColumns} rows={gridSortedRows} onRowsChange={onGridRowUpdate}
                    noRowsFallback={<EmptyRowsRenderer />}
                    headerRowHeight={filters.enabled ? 70 : undefined}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true,
                    }}
                    sortColumns={sortcolumn}
                    onSortColumnsChange={onSortColumnsChange}
                />
            </FilterContext.Provider>
            <ProjectFormPopup show={openprojectpopup} afterProjectSubmit={afterProjectSubmit} afterPopupClose={afterPopupClose} editData={editdata} isEditOn={isEditOn}/>
            {loading ? <RaceSpinner /> : null}
        </div>
    );
}
