import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import utils from "./app-util";

let codeVerifier, pingURL, code_challenge;
const base64 = require('base64-js');
const HAS_CRYPTO = typeof window !== 'undefined' && !!window.crypto;
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            loginscreen: [],
            pingURL: process.env.REACT_APP_PING_URL,
            client_id: process.env.REACT_APP_CLIENT_ID,
            isLogin: true
        }
        this.onLoadPingURL = this.onLoadPingURL.bind(this)
        this.getToken = this.getToken.bind(this)
        this.getURLParameter = this.getURLParameter.bind(this)
    }

    getURLParameter(name, url) {
        if (!url) {
            url = window.location.href
        }
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]")

        const regexS = "[\\?&]" + name + "=([^&#]*)"
        const regex = new RegExp(regexS)
        const results = regex.exec(url)
        return results == null ? null : results[1]

    }
    componentDidMount() {
        if (this.getURLParameter("code", window.location.href)) {

            this.getToken()
        } else {
            this.getURL()
        }
    }
    getToken() {

        const state = this.getURLParameter("code", window.location.href)
        const details = {
            "client_id": this.state.client_id,//"wfhc dev",//"",
            "grant_type": "authorization_code",
            "redirect_uri": process.env.REACT_APP_SSO_APP_REDIRECT_URL,//"https%3A%2F%2Fbitumendevapp.shell.com%2Fredirect.html",
            "code_verifier": window.sessionStorage.getItem("verifier"),
            "code": state
        }
        let formBody = []
        for (const property in details) {
            if (details.hasOwnProperty(property)) {
                const encodedKey = property
                const encodedValue = details[property]
                formBody.push(encodedKey + "=" + encodedValue)
            }
        }


        formBody = formBody.join("&")
        const url = new URL("https://" + this.state.pingURL + ".shell.com/as/token.oauth2")
        const searchParams = new URLSearchParams(details)
        searchParams.forEach((value, key) => {
            url.searchParams.append(key, value)
        })
        fetch("https://" + this.state.pingURL + ".shell.com/as/token.oauth2", {
            method: 'POST', "dataType": "json",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody
        }).then(res => res.json()).then(
            (result) => {
                utils.states.state.setState(result.refresh_token);
                if (result.access_token) {
                    const token = result.access_token.split('.')[1];
                    const parseEmail = JSON.parse(atob(token));
                    window.sessionStorage.setItem("_at", result.access_token);
                    utils.states.state.setET(parseEmail.exp * 1000);
                    if (parseEmail.uid) {
                        const email = parseEmail.email;
                        window.sessionStorage.setItem("uid", parseEmail.uid);
                        const givenName = parseEmail.givenName;
                        const sn = parseEmail.sn;
                        window.sessionStorage.setItem("displayName", givenName + " " + sn);
                        this.setState({
                            username: email
                        }, this.invokeLoginValidation());
                    } else {
                        // alert("There is no Email Attached to your ID, Please contact PING Team.")
                    }
                }
            },
            (error) => { console.log(error) }
        )
    }
    onLoadPingURL = (_state) => {

    }
    bufferToString = (buffer) => {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const state = []
        for (let i = 0; i < buffer.byteLength; i += 1) {
            const index = buffer[i] % CHARSET.length
            state.push(CHARSET[index])
        }
        return state.join('')
    }
    generateRandom = (size) => {
        const that = this;
        const buffer = new Uint8Array(size);
        if (HAS_CRYPTO) {
            window.crypto.getRandomValues(buffer);
        }
        else {
            // fall back to Math.random() if nothing else is available
            for (let i = 0; i < size; i += 1) {
                buffer[i] = Math.random();
            }
        }
        return that.bufferToString(buffer);
    }
    urlSafe = (buffer) => {
        const encoded = base64.fromByteArray(new Uint8Array(buffer));
        return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
    deriveChallenge = (code) => {
        const that = this;
        return new Promise(function (resolve, reject) {
            crypto.subtle.digest('SHA-256', that.textEncodeLite(code)).then(function (buffer) {
                return resolve(that.urlSafe(new Uint8Array(buffer)))
            }, function (error) { return reject(error) })
        })
    }
    base64URLEncode = (str) => {
        return str.toString('base64')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '')
    }
    textEncodeLite(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0; i < str.length; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return bufView;
    }
    setupCodeVerifier = () => {
        const _this = this;
        codeVerifier = this.generateRandom(43);
        const challenge = this.deriveChallenge(codeVerifier).catch(function (_error) {
            return undefined;
        })
        return challenge.then(function (result) {
            if (result) {
                code_challenge = result
                _this.navToPing()
            }
        })
    }


    async getURL(_urlObject) {
        await this.setupCodeVerifier();
    }
    navToPing = () => {
        const challenge = code_challenge
        pingURL = "https://" + this.state.pingURL + ".shell.com/as/authorization.oauth2?redirect_uri=" + process.env.REACT_APP_SSO_APP_REDIRECT_URL
            + "&client_id=" + this.state.client_id + "&response_type=code&scope=email&audience=API_AUDIENCE"
            + "&access_type=offline&code_challenge=" + challenge + "&code_challenge_method=S256"
        window.sessionStorage.setItem("verifier", codeVerifier);
        window.location.href = pingURL;

    }
    // componentWillMount() { }

    invokeLoginValidation() {
        const _that = this;
        const obj = {};
        obj["emailAddress"] = window.sessionStorage.getItem("email");

        // _that.props.history.push({ pathname: "/search" })

        fetch('/request-accepted', {
            method: "GET",
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        })
            .then(res => {
                return utils.checkSessionStatus(res);
            })
            .then(
                (results) => {
                    if ('success' in results) {
                        if (results.success) {
                            _that.props.history.push({ pathname: "/search" });
                        } else {
                            _that.props.history.push({ pathname: "/acceptance-form" });
                        }
                    } else {
                        console.error(results);
                    }
                },
                (_err) => {
                    console.error("Error occured on fetching the query");
                }
            ).catch(function (error) {
                console.error(error);
            })

    }

    handleClick = event => {
        event.preventDefault();
        this.invokeLoginValidation();
    };

    render() {

        return (
            <div className="">
                {/* <Searchpage /> */}
                <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <h2 className="brand-name">Welcome to Qurie</h2>
                        <h6>Authentication in progress...</h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Login)

class LoginUtils {
    getToken() {
        const payload = {
            "client_id": process.env.REACT_APP_CLIENT_ID,
            "grant_type": "refresh_token",
            "refresh_token": utils.states.state.getState(),
        }
        let formBody = []
        for (const property in payload) {
            if (payload.hasOwnProperty(property)){
                const encodedKey = property;
                const encodedValue = payload[property];
                formBody.push(encodedKey + "=" + encodedValue);
            }
        }
        formBody = formBody.join("&")
        const url = new URL(`https://${process.env.REACT_APP_PING_URL}.shell.com/as/token.oauth2`);
        fetch(url, {
            method: 'POST', "dataType": "json",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody
        }).then(res => res.json()).then(
            (result) => {
                utils.states.state.setState(result.refresh_token);
                if (result.access_token) {
                    const at = result.access_token.split('.')[1];
                    const detail = JSON.parse(atob(at));
                    utils.states.state.setET(detail.exp * 1000);
                    window.sessionStorage.setItem("_at", result.access_token);
                }
            },
            (error) => { console.log(error) }
        )
    }
}
export { LoginUtils }
