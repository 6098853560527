import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";
import utils from '../app-util';
const TEXT_ANCHOR = 'text-anchor';
class HorizontalBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.msgElem = React.createRef();
        this.state = {
            data: this.props.data
        }
    }
    componentDidMount() {
        this.margin = { top: 10, right: 35, bottom: 40, left: 160 };
        this.width = utils.getFixedPlotDimension().width - this.margin.left - this.margin.right;
        this.height = utils.getFixedPlotDimension().height - this.margin.top - this.margin.bottom;
        this.svg = d3.select(this.node.current);
        this.svg
            .attr("width", "100%")//this.width + this.margin.left + this.margin.right)
            .attr("height", 400)//this.height + this.margin.top + this.margin.bottom)
            .attr('viewBox', '0 0 ' + (this.width + this.margin.left + this.margin.right) + ' ' + (this.height + this.margin.top + this.margin.bottom))
            .attr('preserveAspectRatio', 'xMinYMin')
        this.g = this.svg.append('g')
            .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

        this.xScale = d3.scaleLinear().range([0, this.width]);
        this.yScale = d3.scaleBand().range([this.height, 0]);
        this.xAxis = d3.axisBottom(this.xScale);
        this.yAxis = d3.axisLeft(this.yScale);

        this.g.append("g").attr("class", "x axis ")
            .attr('id', "xAxis")
            .attr("transform", `translate(0, ${this.height})`)

        this.g.append("g")
            .attr("class", "y axis")
            .attr('id', "yAxis")
        this.g.append("text")
            .attr('class', "xlabel")
            .attr(TEXT_ANCHOR, "end")
            .attr("x", this.width / 2)
            .attr("y", this.height + this.margin.bottom + this.margin.top - 10)
            .text("Count")
            .style("font-size", "12px");
        this.g.append("text")
            .attr('class', "ylabel")
            .attr("x", -this.height / 2 - 50)
            .attr("y", -150)
            .attr("transform", "rotate(-90)")
            .attr(TEXT_ANCHOR, "start")
            .style("font-size", "12px")
            .text("Reason for Change");
        this.treemapTooltip = d3Tip()
            .attr("class", "d3-hzbarchart-tooltip d3-tooltip")
            .offset(function () {
                return [10, 0]
            })
            .html(function (_d) {
                const nodeData = d3.select(this).data()[0];
                return `${nodeData[0]}<br>${nodeData[1]}`;
            });
        this.svg.call(this.treemapTooltip)
        this.drawHzBarChart(this.props.data)

        const _this = this;
        const initialWidth = utils.getFixedPlotDimension().width, initialHeight = utils.getFixedPlotDimension().height;
        function updateOnFullScreen() {
            document.querySelector("#bigrule .f-screen").style.width = _this.width;
            document.querySelector("#bigrule .f-screen").style.height = _this.height;
            _this.svg.attr("width", _this.width)
                .attr("height", _this.height)
                .attr('viewBox', '0 0 ' + (_this.width) + ' ' + (_this.height))
                .attr('preserveAspectRatio', 'xMinYMin')
            _this.xScale.range([0, _this.width - _this.margin.left - _this.margin.right])
            _this.yScale.range([_this.height, 0]).padding(0.1)
            _this.svg.select('.x.axis').attr("transform", "translate(0," + (_this.height) + ")");
            _this.svg
                .transition()
                .attr("width", _this.width)
                .attr("height", _this.height + _this.margin.top + _this.margin.bottom);
            _this.svg.select(".xlabel")
                .attr("x", _this.width / 2 - 50)
                .attr("y", _this.height + _this.margin.bottom + _this.margin.top - 15);
        }
        document.querySelector("#bigrule").addEventListener('fullscreenchange', (_e) => {
            if (document.fullscreenElement) {
                _this.width = window.innerWidth - 100;
                _this.height = window.innerHeight - 100;
                updateOnFullScreen();
            } else {
                _this.width = initialWidth;
                _this.height = initialHeight;
                updateOnFullScreen();
            }
            setTimeout(() => {
                _this.drawHzBarChart(_this.props.data);
            }, 100);
        })
    }
    componentDidUpdate() {
        const emocTab = JSON.parse(sessionStorage.getItem("emocTab"));
        
        if(this.props.data.isNew && !emocTab.otclicked && !emocTab.stclicked && emocTab.newload){
            this.drawHzBarChart(this.props.data)
        }
            
        
    }

    drawHzBarChart = (dataBarChart) => {
        if (Object.keys(dataBarChart.data).length > 0) {
            this.svg.style('display', 'block');
            this.msgElem.current.classList.remove('active');
            this.props.data.isNew = false;
            const data = Object.entries(dataBarChart.data).sort(function (a, b) {
                return d3.ascending(a[1], b[1]);
            });
            const onlyValuesExtracted = data.map(function (item) {
                return item[1];
            });
            const color = d3.scaleSequential(d3.interpolateReds).domain([Math.min(...onlyValuesExtracted), Math.max(...onlyValuesExtracted)])
            const max = d3.max(data, function (d) {
                return d[1];
            })
            this.xScale.domain([0, max]);
            this.yScale.domain(data.map(function (d) {
                return d[0];
            })).padding(.1);

            this.svg.selectAll(".moc-rect-g").remove();
            const _this = this;
            let isBarHighlighted = null
            const barg = this.g.selectAll("moc-rect-g")
                .data(data)
                .join("g")
                .attr("data-ids", (d) => {
                    return this.props.data.data_emoc_id[d[0]];
                })
                .attr('class', 'moc-rect-g')
                .style('cursor', 'pointer')
                .on("mousemove", this.treemapTooltip.show)
                .on("mouseout", this.treemapTooltip.hide)
                .on("click", function (e, _d) {
                    const target = e.currentTarget;
                    const ids = target.dataset.ids;


                    let filter = false;
                    if (d3.select(this).classed("active") && _this.svg.classed('clickactive')) {
                        d3.select(this).classed("active", false);
                        _this.svg.classed('clickactive', false);

                    } else {
                        d3.select(this).classed("active", true);
                        _this.svg.classed('clickactive', true);
                        filter = true;
                    }
                    // clear the highlighted rect on other rect clicking
                    if ( (isBarHighlighted !== null) && (!isBarHighlighted.isSameNode(this))) {
                        isBarHighlighted.classList.remove('active');
                    }
                    
                    isBarHighlighted = this;
                    _this.props.filterOnCall({ ids: ids, isFilterActive: filter });
                });
            barg.append("rect")
                .attr("x", this.xScale(0))
                .attr("y", d => this.yScale(d[0]))
                .attr("width", d => this.xScale(d[1]))
                .attr("height", this.yScale.bandwidth())
                .attr("fill", d => color(d[1]))
                .attr('class', 'moc-rect bar');
            barg.append('text')
                .attr("x", d => this.xScale(d[1]))
                .attr("y", d => this.yScale(d[0]) + this.yScale.bandwidth() / 2)
                .text((d) => d[1])
                .style('font-size', '12px')

            this.svg.select('.y.axis').selectAll("text")
                .attr("transform", "translate(-6,-8)rotate(-55)")
                .style("text-anchor", "end");

            this.svg.select('.x.axis').call(this.xAxis);
            this.svg.select('.y.axis').call(this.yAxis);
        } else {
            this.svg.style('display', 'none');
            this.msgElem.current.classList.add('active');
        }
    }
    render() {
        return (
            <>
                <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
                <svg id="mocBigRule" ref={this.node} width="100%" height="400"></svg>
            </>
        );
    }

}
export default React.memo(HorizontalBarChart);
