import React from 'react';
export default function ActivityHeaderDefinitions() {
    return (
        <section>
            <h5>Total Interactions:</h5>
            <p className="small">Total Interactions is the count of interactions made by all users for the selected date range. It counts the number of clicks made by user while interacting with Qurie irrespective of the feature or function used viz. search, tab switch, filter applied, reports download etc.</p>
            
            <h5>Total Unique User:</h5>
            <p className="small">Count of total unique users logged in Qurie for the selected date range.</p>
            
            <h5>Total Queries Searched:</h5>
            <p className="small">Count of total search queries performed by users for the selected date range.</p>
            
            <h5>Total Reports Downloaded:</h5>
            <p className="small">Count of total reports downloaded by the users for the selected date range. This count is the sum of the total number of reports downloaded whether it is a standard or custom report.</p>
            
            <h5>Total Thematic Bowtie Views:</h5>
            <p className="small">Total count of Thematic bowtie views generated/viewed by users for the selected date range.</p>
            
            <h5>Total Tab Views:</h5>
            <p className="small">The tab view counts is calculated per user based on the switches/interactions between different data tabs viz. Risk, Learnings, eMoC & Quality. It is the count of total tab views done by all users between the selected dates.</p>
            
            <h5>Bar Plot:</h5>
            <p className="small">As a default the bar plot visualizes the timeseries data for Total Interactions KPI for the selected data range. Super users can visualize the information for other KPI’s by clicking on respective information card viz. Total Unique User, Total Queries Searched, Total Reports Downloaded, Total Thematic Bowtie Views and Total Tab Views.</p>
            
            <h5>Download Details:</h5>
            <p className="small">It summarizes the details of total reports downloaded for the selected date ranges.</p>
            
            <h5>Individual User Activities Details:</h5>
            <p className="small">The grid table displays all the individual users’ activity count details chronologically like total interactions/views, report downloads, individual/Thematic bowtie views, and number of queries searched etc.</p>
        </section>
    );
}