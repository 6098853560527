
import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";
import RaceSpinner from '../spinner';
import utils from '../app-util';
import { toast } from 'react-toastify';

function formatValue(value) {
    if (Number.isInteger(value)) {
        return value;
    } else {
        return Number(value).toFixed(2);
    }
}
function HMObserver(selectorElem) {
    return new ResizeObserver(entries => {
        for (const entry of entries) {
            if (entry.contentBoxSize[0]) {

                selectorElem.style.width = `${entry.contentRect.width - 100}px`;
            }
        }
    });
}
const valueUnits = { "Frequency": "", "Cost": "Millions", "Schedule": "Months", "Probability": "RAM Rating", "Severity": "" }, NO_OF_HITS = "No. of Hits";;
const DROPDOWN_STYLE = "d-block mb-0";
class HeatMapQuality extends React.Component {
    DROPDOWN_BORDER_PROPERTY = "1px solid #009EB4";
    constructor(props) {
        super(props);
        this.createHeatMap = this.createHeatMap.bind(this);
        this.state = {
            data: this.props.data,
            indices: this.props.indices,
            loader: false
        }
        this.msgElem = React.createRef();
    }

    componentDidMount() {
        const __this = this;
        const data = this.state.data;
        if (data !== undefined) {
            this.margin = { top: 20, right: 20, bottom: 220, left: 220 };
            this.svgNode = this.node;
            this.svg = d3.select(this.node);
            
            this.width = data.x_tick_labels.length * 25;
            this.height = data.y_tick_labels.length * 25;

            this.xScale = d3.scaleBand().range([0, this.width]);
            this.yScale = d3.scaleBand().range([0, this.height]);
            this.xAxis = d3.axisBottom().scale(this.xScale);
            this.yAxis = d3.axisLeft().scale(this.yScale);
            this.groupSVGMain = this.svg.attr("width", this.width + this.margin.left + this.margin.right)
                .attr("height", this.height + this.margin.top + this.margin.bottom)
                .append("g").attr('class', `${this.props.datatype}groupSVGMain`)
                .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
            this.groupSVGMain.append("g").attr("transform", "translate(0," + this.height + ")")
                .attr("class", "x axis");
            this.groupSVGMain.append("g")
                .attr("class", "y axis")

            this.tip = d3Tip()
                .attr("class", "d3-tooltip")
                .html(function (_d) {
                    const nodeData = d3.select(this).data()[0];
                    return `X: ${nodeData.x}<br/>Y: ${nodeData.y}<br/>
              ${__this.valueDropdown.node().value === 'frequency' ? NO_OF_HITS : __this.valueDropdown.node().value}
              : ${formatValue(nodeData[__this.valueDropdown.node().value])}`;
                });
            this.svg.call(this.tip)
            //---------------Dropdown Container-------------------

            this.dropdownContainer = d3.select(this.svgNode.parentNode.parentNode).insert("div", ":first-child")
                .attr('class', `${this.props.datatype}-heatmap-dropdowns d-flex justify-content-around small mt-3 text-center`);
            this.heatMapName = d3.select(this.dropdownContainer.node().parentNode).insert("div", ':nth-child(2)')
                .attr('class', `${this.props.datatype}-heatmap-name heatmap-name font-weight-bold text-center mt-3`).style('font-size','14px');
            this.xaxisDropdownContainer = d3.select(this.dropdownContainer).node().insert("div").attr('class', 'xaxis-dropdown');
            this.yaxisDropdownContainer = d3.select(this.dropdownContainer).node().insert("div").attr('class', 'yaxis-dropdown');
            this.valueDropdownContainer = d3.select(this.dropdownContainer).node().insert("div").attr('class', 'value-dropdown');

            this.xaxisDropdownContainer.append("label").attr("class", DROPDOWN_STYLE).text("X-Axis: ");
            this.xaxisDropdown = this.xaxisDropdownContainer
                .insert("select")
                .attr("class", "xaxis-dropdown-select")
                .style("min-width", "200px")
                .style("border", this.DROPDOWN_BORDER_PROPERTY)
                .on("change", function () {
                    
                    __this.typeOfXYAxisOnChange(d3.select(this).property('value'), __this.yaxisDropdown.node().value);
                    __this.setHeatmapName(
                        d3.select(this).property('value'),
                        __this.yaxisDropdown.node().value,
                        __this.valueDropdown.node().value, __this.heatMapName.node());
                });

            this.yaxisDropdownContainer.append("label").attr("class", DROPDOWN_STYLE).text("Y-Axis: ");
            this.yaxisDropdown = this.yaxisDropdownContainer
                .insert("select")
                .attr("class", "yaxis-dropdown-select")
                .style("min-width", "200px")
                .style("border", this.DROPDOWN_BORDER_PROPERTY)
                .on("change", function () {
                    __this.typeOfXYAxisOnChange(__this.xaxisDropdown.node().value, d3.select(this).property('value'));
                    __this.setHeatmapName(
                        __this.xaxisDropdown.node().value,
                        d3.select(this).property('value'),
                       __this.valueDropdown.node().value, __this.heatMapName.node());
                });


            this.valueDropdownContainer.append("label").attr("class", DROPDOWN_STYLE).text("Type of plot: ");
            this.valueDropdown = this.valueDropdownContainer
                .insert("select")
                .attr("class", "value-dropdown-select")
                .style("min-width", "200px")
                .style("border", this.DROPDOWN_BORDER_PROPERTY)
                .on("change", function () {
                    __this.typeOfPlotOnChange(d3.select(this).property('value'))
                    __this.setHeatmapName(
                       __this.xaxisDropdown.node().value,
                       __this.yaxisDropdown.node().value,
                        d3.select(this).property('value'), __this.heatMapName.node());
                });

            this.xaxisDropdown.selectAll('option')
                .data(this.props.xaxistype)
                .enter().append("option")
                .attr("value", function (d) { return d })
                .text(function (d) { return d.replace(/_/, ' ') })


            this.valueDropdown.selectAll('option')
                .data(["Frequency", "Cost", "Severity"])
                .enter().append("option")
                .attr("value", function (d) { return d.toLowerCase(); })
                .text(function (d) {
                    if(d === "Frequency") {
                        return NO_OF_HITS;
                    } else if(d === "Severity") {
                        return d;
                    } else {
                        return `${d} (${valueUnits[d]})`;
                    }
                })
                .property("selected", function (d) {
                    return d === "cost";
                })
            this.yaxisDropdown.selectAll('option')
                .data(this.props.yaxistype)
                .enter().append("option")
                .attr("value", function (d) { return d.split(" ").join("") })
                .text(function (d) { return d.replace(/_/, ' ') })
                .property("selected", function (d) {
                    return d === "Functinal_L1";
                })

            
            //---------------Dropdown end-------------------
            // ----- Heatmap name------------
            this.setHeatmapName(this.xaxisDropdown.node().value, this.yaxisDropdown.node().value, this.valueDropdown.node().value, this.heatMapName.node())
            //=========================
            this.legendheight = 300;
            this.legendwidth = 100;
            this.legendMargin = { top: 10, right: 60, bottom: 10, left: 2 };

            this.legendContainer =d3.select(this.svgNode.parentNode).insert('div', ':nth-child(1)')
                .attr('id', `${this.props.datatype}Heatmaplegend`)
                .style("height", this.legendheight + "px")
                .style("width", this.legendwidth - 20 + "px")
                .style("position", "absolute")
                .style("top", (this.legendMargin.top) + 100 + "px")
                .style("right", "0px")
            this.legendCanvas= this.legendContainer.append("canvas")
                .attr("height", this.legendheight - this.legendMargin.top - this.legendMargin.bottom)
                .attr("width", 1)
                .style("height", (this.legendheight - this.legendMargin.top - this.legendMargin.bottom) + "px")
                .style("width", (this.legendwidth - this.legendMargin.left - this.legendMargin.right) + "px")
                .style("position", "absolute")
                .style("top", (this.legendMargin.top) + "px")
                .style("left", (this.legendMargin.left) + "px")
                .node();

            this.legendCanvasCtx = this.legendCanvas.getContext("2d");
            this.legendscale = d3.scaleLinear().range([1, this.legendheight - this.legendMargin.top - this.legendMargin.bottom])
            this.legendaxis = d3.axisRight().scale(this.legendscale).tickSize(6).ticks(8);
            this.legendsvg = d3.select(__this.legendContainer.node())
                .append("svg")
                .attr("height", (__this.legendheight) + "px")
                .attr("width", (__this.legendwidth-30) + "px")
                .style("position", "absolute")
                .style("left", "0px")
                .style("top", "0px")
            this.axisG = this.legendsvg.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(" + (__this.legendwidth - __this.legendMargin.left - __this.legendMargin.right + 3) + "," + (__this.legendMargin.top) + ")")


            this.createHeatMap();
            this.hmWrapperElem = document.querySelector(".heatmap-wrapper");
            
            
            const hmRefresh = document.querySelector('.qualityheatmap-c-btn');
            hmRefresh.addEventListener('click', this.heRefreshBtnOnClick.bind(this));
            
            this.resizeObserver = HMObserver(this.svgNode.parentNode);
            this.resizeObserver.observe(this.hmWrapperElem);
        }
    }

    componentWillUnmount() {
        this.resizeObserver.unobserve(this.hmWrapperElem);

        const hmRefresh = document.querySelector('.qualityheatmap-c-btn');
        hmRefresh.removeEventListener("click", this.heRefreshBtnOnClick.bind(this));
    }
    setHeatmapName(xAxisValue, yAxisValue, plotTypeValue, nameSelector) {
        if(plotTypeValue === 'frequency') {
            nameSelector.innerText = `${NO_OF_HITS}${valueUnits[plotTypeValue.charAt(0).toUpperCase() + plotTypeValue.slice(1)]}: 
            ${yAxisValue.replace(/_/, ' ')} v/s ${xAxisValue.replace(/_/, ' ')}`;
        } else if (plotTypeValue === 'severity') {
            nameSelector.innerText = `${plotTypeValue}${valueUnits[plotTypeValue.charAt(0).toUpperCase() + plotTypeValue.slice(1)]}: 
            ${yAxisValue.replace(/_/, ' ')} v/s ${xAxisValue.replace(/_/, ' ')}`;
        } else{
            nameSelector.innerText = `${plotTypeValue} (${valueUnits[plotTypeValue.charAt(0).toUpperCase() + plotTypeValue.slice(1)]}): 
            ${yAxisValue.replace(/_/, ' ')} v/s ${xAxisValue.replace(/_/, ' ')}`;
        }
    }
    heRefreshBtnOnClick(_e) {
        const plotFilterActive =  document.querySelector('#heatmapquality rect.clicked');
        if(plotFilterActive === null){
        // Refresh heat map if failed to load the data
        const indices = utils.getVisibleResultIDs('indicesquality', '.quality-container .quality-item.d-block', '.quality-container .quality-item.d-none' );
        this.loadHeatMapData(indices, this.xaxisDropdown.node().value, this.yaxisDropdown.node().value);

        //Remove highlighted rect and display the all card
        const highlightedRect = document.querySelector("rect.clicked");
        if (highlightedRect !== null) {
            highlightedRect.classList.remove("clicked");
        };
        
        this.setHeatmapName(
            this.xaxisDropdown.node().value,
            this.yaxisDropdown.node().value,
            this.valueDropdown.node().value, this.heatMapName);
        } else {
            plotFilterActive.classList.remove('clicked');
            this.props.heatmapFilterCallback(null);
        }
    }
    typeOfXYAxisOnChange(xval, yval) {
        const __this = this;
        let indices = sessionStorage.getItem('indicesquality');
        this.props.heatmapFilterCallback(null);
        const qualityYearFilter = document.querySelector('#sinceyearquality').value;
        const qualityCustomDateFilter = document.querySelector(".date-filter-wrapper.quality .react-daterange-picker input[name='daterange_from']").value;
        const qualityDropdownFilter = document.querySelectorAll(".MuiChip-label.MuiChip-labelSmall");
        const advSearchElems = document.querySelectorAll('.advanced-search-wrapper.quality_advanced .dropdown-filtering.active .chiplist .chip');
        if (qualityYearFilter !== '' || qualityCustomDateFilter !== '' || qualityDropdownFilter.length > 0 || advSearchElems.length > 0) {
            indices = utils.getVisibleResultIDs('indicesquality', '.quality-container .quality-item.d-block', '.quality-container .quality-item.d-none' );
        }

        __this.loadHeatMapData(indices, xval, yval);
    }
    loadHeatMapData(indices, xval = "LoB", yval = "EquipmentType") {
        const __this = this;
        this.setState({ loader: true });
        fetch("/quality_heatmap_data?quality_id=" + indices + "&x_data=" + xval + "&y_data=" + yval, {
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(res => {
                if (res.status !== 200) {
                    utils.checkSessionStatus(res);
                }
                return res.json();
            })
            .then((results) => {
                results.isNew = true;
                __this.setState({ loader: false, data: results }, () => {
                    __this.createHeatMap();
                  });
            },
                (_err) => {
                    __this.setState({ loader: false });
                    toast.warn("No heatmap data found");
                }
            );
    }

    typeOfPlotOnChange(val) {
        const __this = this;
        let xMaxValue = d3.max(__this.state.data.data, function (d) {
            return d[val];
        });
        xMaxValue = val === 'probability' ? 5 : xMaxValue;
        __this.color = d3.scaleSequential(d3.interpolateReds).domain([0, xMaxValue]);
        __this.legendscale.domain(__this.color.domain().reverse());
        __this.axisG.call(__this.legendaxis);
        d3.selectAll(`#heatmap${this.props.datatype} .rect`).each(function (d, _i) {
            d3.select(this).style('fill', __this.color(d[val]))
        });
    }
    rectOnClick(_selfContext) {
        const nodeData = d3.select(_selfContext).data()[0];
        const vType = this.valueDropdown.node().value;
        if (nodeData[vType] !== 0) {
            //toggle click
            if (_selfContext.classList.contains('clicked')) {
                _selfContext.classList.remove('clicked');
                this.props.heatmapFilterCallback(null);
            } else {
                _selfContext.classList.add('clicked');
                this.props.heatmapFilterCallback({ xAxisType: this.xaxisDropdown.node().value, yAxisType: this.yaxisDropdown.node().value, data: nodeData });
            }
            //clear rect highlight if you click one rect then moved to another
            if ((this.lastClickedRect !== null) && (!this.lastClickedRect.isSameNode(_selfContext))) {
                this.lastClickedRect.classList.remove('clicked');
            }
            
            this.lastClickedRect = _selfContext;
        }
    }
    shouldComponentUpdate() {
        const qualityTab = JSON.parse(sessionStorage.getItem("qualityTab"));
        if(this.props.data.isNew && !qualityTab.otclicked && !qualityTab.stclicked && qualityTab.newload){
            this.createHeatMap();
        }
        return false;
    }
    static getDerivedStateFromProps(props, state){
        const qualityTab = JSON.parse(sessionStorage.getItem("qualityTab"));
        if(props.data.isNew && !qualityTab.otclicked && !qualityTab.stclicked && qualityTab.newload){
            return {
                data: props.data
            };
        }
        return null;
    }
    createHeatMap() {
        const __this = this;
        const data = this.state.data.isNew ? this.state.data : this.props.data;
        if (data.data.length > 0) {
            this.props.data.isNew = false;
            data.isNew = false;
            __this.svg.style('display', 'block');
            __this.msgElem.current.classList.remove('active');
            d3.select(__this.legendContainer.node()).style('display', 'block');

            __this.width = data.x_tick_labels.length * 25;
            __this.height = data.y_tick_labels.length * 25;
            __this.svg.attr("width", __this.width + __this.margin.left + __this.margin.right)
                .attr("height", __this.height + __this.margin.top + __this.margin.bottom)

            __this.gridSize = __this.width / data.x_tick_labels.length;

            const valueType = __this.valueDropdown.node().value;

            const xMaxValue = d3.max(data.data, function (d) {
                return d[valueType];
            });

            __this.color = d3.scaleSequential(d3.interpolateReds).domain([0, xMaxValue]);

            __this.xScale.range([0, __this.width]).domain(data.x_tick_labels);

            __this.groupSVGMain.select('.x.axis').attr("transform", "translate(0," + __this.height + ")")
                .call(this.xAxis)
                .selectAll("text")
                .attr("y", 7)
                .attr("dy", ".5em")
                .attr("x", "-.5em")
                .style("text-anchor", "end")
                .attr("transform", function (_d) {
                    return "translate(0,0) rotate(-45)";
                });


            //Add the vertical labels
            __this.yScale.range([0, __this.height]).domain(data.y_tick_labels);

            __this.groupSVGMain.select('.y.axis')
                .call(this.yAxis)

            __this.lastClickedRect = null;
            d3.select('.rectGroup').remove();
            const rectG = __this.groupSVGMain.append('g').attr('class', 'rectGroup');
            const rect = rectG.selectAll(".rect")
                .data(data.data)
            rect.exit().remove();
            rect.enter().append("rect")
                .attr("x", function (d, _i) {
                    return __this.xScale(d.x)
                })
                .attr("y", function (d, _i) {
                    return __this.yScale(d.y)
                })
                .attr("rx", 2)
                .attr("ry", 2)
                .attr("class", "rect")
                .attr("width", __this.gridSize)
                .attr("height", __this.gridSize)
                .attr("stroke", "#ffffff")
                .attr("stroke-width", "2px")
                .style("fill", function (d) {
                    return __this.color(d[valueType]);
                })
                .on("mouseover", __this.tip.show)
                .on("mouseout", __this.tip.hide)
                .on("click", function (_d) {
                    __this.rectOnClick(this);

                });
            //------------ColorScale legend---------------
            __this.legendscale.domain(__this.color.domain().reverse());
            this.axisG.call(this.legendaxis);
            const image = this.legendCanvasCtx.createImageData(1, __this.legendheight);
            d3.range(__this.legendheight).forEach(function (i) {
                const c = d3.rgb(__this.color(__this.legendscale.invert(i)));
                image.data[4 * i] = c.r;
                image.data[4 * i + 1] = c.g;
                image.data[4 * i + 2] = c.b;
                image.data[4 * i + 3] = 255;
            });
            __this.legendCanvasCtx.putImageData(image, 0, 0);
            const checkNodeSizeInterval = setInterval(() => {
                if (__this.groupSVGMain.node().getBoundingClientRect().height > 0) {
                  __this.svg.attr('height', __this.groupSVGMain.node().getBoundingClientRect().height + 20);
                  clearInterval(checkNodeSizeInterval);
                }
              }, 100);
        } else {
            this.svg.style('display', 'none');
            this.msgElem.current.classList.add('active');
            d3.select(__this.legendContainer.node()).style('display', 'none');
        }
    }

    render() {
        return (
            <div className='heatmap-wrapper'>
            <div className={`${this.props.datatype}heatmap-svg-wrapper`} style={{width:'85%', overflowX: 'auto', overflowY:'hidden'}}>
                <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
                {this.state.loader ? <RaceSpinner /> : null}
                <svg id={`heatmap${this.props.datatype}`} className={`heatmap ${this.props.datatype}heatmap`} ref={node => this.node = node} width="100%" height="500"></svg>
            </div>
            </div>
        );
    }
}
export default React.memo(HeatMapQuality);
