import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import usericon from '../assets/user.svg'
import utils from './app-util';
import iconbook from '../assets/book-open.svg';
import userManual from './data/Qurie - User Manual.pdf'
import { useAppContext } from './context/context';

function UserProfile(props) {
    const history = useHistory();
    const [notification, setNotification] = useState({ accessCount: 0, notificationSeen: 0 });
    useEffect(() => {
        if (!sessionStorage["_ns"]) {
            sessionStorage.setItem("_ns", 0)
        }
        if (props.admin || props.appOwner) {
            fetch(`/access-requests`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            }).then(res => {
                return utils.checkSessionStatus(res);
            }).then((results) => {
                if (results.success) {
                    setNotification({ accessCount: parseInt(results.accesscount, 10), notificationSeen: parseInt(sessionStorage.getItem("_ns"), 10) });
                }
            }, (_err) => {
                console.error("Error occured");
            })
        }

        setTimeout(() => {
            if (history.location.pathname === "/grid") {
                setNotification({ accessCount: 0, notificationSeen: 1 });
                sessionStorage.setItem("_ns", 1)
            }
        }, 4000);
    }, [props, history]);
    function onClick(e) {
        const url = e.currentTarget.dataset.url;
        history.push({ pathname: url, state: { admin: props.admin, appOwner: props.appOwner } })
    }
    function onLogoutClick(e) {
        e.preventDefault();
        history.replace('/dashboard', null)
        history.replace('/search', null)
        history.replace('/grid', null)
        history.replace('/acronyms', null)
        window.sessionStorage.clear();
        window.location.href = '/logout';
    }
    const { setState } = useAppContext();
    function handleTourClick() {
        const riskTab = document.querySelector('#risk-tab');
        if (riskTab !== null) {
            if (riskTab.classList.contains('active')) {
                setState({ run: true, tourActive: true });
            } else {
                riskTab.click();
                setTimeout(() => {
                    setState({ run: true, tourActive: true });
                }, 500);
            }
        } else {
            setState({ run: true, tourActive: true });
        }
    }
    function renderTourMenu() {
        return (history.location.pathname === "/dashboard") || (history.location.pathname === "/search") ? <button className="dropdown-item" type="button" onClick={handleTourClick}>Qurie Tour</button> : null
    }
    function renderAdminAndAcronymPageComponent() {
        if (props.appOwner) {
            return (
                <>
                    <button className="dropdown-item" type="button" data-url="/grid" onClick={onClick}>Admin Portal</button>
                    <button className="dropdown-item" type="button" data-url="/acronyms" onClick={onClick}>Project &amp; Acronyms</button>
                    <button className="dropdown-item" type="button" data-url="/user-activity" onClick={onClick}>Activity Dashboard</button>
                    <button className="dropdown-item" type="button" data-url="/most-not-relevant" onClick={onClick}>Most/Not Relevant</button>
                    {
                        renderTourMenu()
                    }
                </>
            );
        } else if(props.admin) {
            return (
                <>
                    <button className="dropdown-item" type="button" data-url="/user-activity" onClick={onClick}>Activity Dashboard</button>
                    <button className="dropdown-item" type="button" data-url="/most-not-relevant" onClick={onClick}>Most/Not Relevant</button>
                    {
                        renderTourMenu()
                    }
                </>
            );
        } else if(props.otheruser) {
            return (
                <>
                    {
                        renderTourMenu()
                    }
                </>
            );
        }
    }
    return (
        <div className='d-flex'>
            <div className='mr-3'><a className='tour-userguide' href={userManual} target='_blank' rel='noreferrer' title='User Manual'><img src={iconbook} alt="User Manual"/></a></div>
            <div className="dropdown tour-userprofile">
                {props.admin && notification.accessCount > 0 && !notification.notificationSeen ?
                    <span className='badge badge-danger position-absolute' style={{ top: '-2px', left: '-6px' }}>{notification.accessCount}</span> : null}
                <a className="dropdown-toggle" style={{ textDecoration: "none" }} href="/#"
                    role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                    <div className="d-inline-block">
                        <img className="ml-1 user-avatar" alt="" src={usericon} />
                    </div>
                    <span style={{ color: "#212529" }}>{sessionStorage.getItem("displayName")}</span>
                </a>

                <div className="dropdown-menu dropdown-user-profile" aria-labelledby="dropdownMenuLink">
                    {
                        renderAdminAndAcronymPageComponent()
                    }
                    <button className="dropdown-item" type="button" onClick={onLogoutClick}>Log Out</button>
                </div>
            </div>
        </div>
    );
}
export default React.memo(UserProfile);
