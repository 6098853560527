import React from 'react';
import summaryDescription from '../summaryDescription';
import Tagging from './Tagging';
function ResultCard(props) {
    const mustIncl = document.querySelector('.must-include');
    function addToReportOnChange(e) {
        try {
            props.addToReportOnChange(e);
        } catch (error) {
            console.error(error);
        }
    }

    function mostRelevantOnChange(e) {
        try {
            props.mostRelevantOnChange(e);
        } catch (error) {
            console.error(error);
        }
    }

    function notRelevantOnChange(e) {
        try {
            props.notRelevantOnChange(e);
        } catch (error) {
            console.error(error);
        }
    }

    function actionOnClick(e) {
        e.preventDefault();
        try {
            props.associationsOnClick({
                type: e.target.dataset.type,
                id: e.target.dataset.id,
                query: sessionStorage.getItem('query'),
                datatype: e.target.dataset.datatype,
                bowtieURL: e.target.dataset.url
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className={`${props.dataType}-item search-item card mb-3 rounded-0 border-0
            ${props.dataCountry.replace(/\s|,|\//g, '_')} ${props.dataLoB.replace(/\s|,|\//g, '_')} ${props.dataProject.replace(/\s|,|\//g, '_')}`}
                key={`#${props.valueConst}${props.dataId}${mustIncl !== null ? mustIncl.value : ""}`}
                data-id={props.dataId} data-modified={props.dataCreatedModified} data-lob={props.dataLoB}
                data-country={props.dataCountry} data-project={props.dataProject}
                data-characteristics={props.dataCharacterstics !== "" ? props.dataCharacterstics.join('@#'):""} 
                data-disciplinefunction={props.dataDisciplineFunc !== "" ? props.dataDisciplineFunc.join('@#'):""}
                data-equipmenttype={props.dataEquipmentType}
                >
                <div className='search-item-wrapper'>
                    <div className='search-item-inner p-2'>

                        <div className={`result-content-item-intro d-flex justify-content-between py-1`}>
                            <div className='titles' data-toggle="collapse"
                                data-target={`#${props.dataType}${props.dataId}`}
                                aria-expanded="false" aria-controls={`#${props.dataType}${props.dataId}`}>
                                <h6 className='main-title' title={props.dataTitle}>{props.dataTitle}</h6>
                                <p className='sub-title small'>{props.dataDesc.slice(0, 130)}</p>
                                <br />
                            </div>
                            <div className='activity-control'>
                                <div className="activity-control-inner">
                                    <div className="report-controls">
                                        <form>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input addreport" id={`addReport${props.dataType}${props.dataId}`} data-id={props.dataId}
                                                    data-type={props.dataType} data-title={props.dataTitle} onChange={addToReportOnChange} />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`addReport${props.dataType}${props.dataId}`}>Add to Report</label></div>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input mostrelevant"
                                                    onChange={mostRelevantOnChange} data-id={props.dataId} id={`mostRelevant${props.dataType}${props.dataId}`}
                                                    defaultChecked={props.mostRelevantList.includes(props.dataId)} disabled={props.globalMostRelevantList.includes(props.dataId) ? true : false}
                                                />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`mostRelevant${props.dataType}${props.dataId}`}>Most Relevant</label></div>
                                            <div className="form-group form-check mb-0">
                                                <input type="checkbox" className="form-check-input notrelevant"
                                                    onChange={notRelevantOnChange} data-id={props.dataId} id={`notRelevant${props.dataType}${props.dataId}`}
                                                    defaultChecked={props.notRelevantList.includes(props.dataId)} disabled={props.globalNotRelevantList.includes(props.dataId) ? true : false}
                                                />
                                                <div className='Toastify__spinner checkbox-spinner'></div>
                                                <label className="form-check-label" htmlFor={`notRelevant${props.dataType}${props.dataId}`}>Not Relevant</label></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='quality-footer d-flex align-items-center no-gutters'>
                            <div className="col content-left">
                                <Tagging tagList={props.dataTags} />
                            </div>
                            <div className="col-4 col-md-3 content-right">
                                <div className="d-flex justify-content-end">
                                    {/* <button className="btn btn-sm action-bowtie" type="button" data-id={props.dataId} data-type="Action"
                                     data-url="get_quality_bow_tie" data-datatype={'quality'} onClick={actionOnClick}>Bowtie</button> */}
                                    <div className='btn-group associations-btns ml-1'>
                                        <button className='btn btn-sm dropdown-toggle' type='button' data-toggle="dropdown" aria-expanded="false">Associations</button>
                                        <div className="dropdown-menu dropdown-menu-sm-right">
                                            <button className="dropdown-item" type='button'  data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated Risk" onClick={actionOnClick}>Associated Risk</button>
                                            <button className="dropdown-item" type='button' data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated Learning" onClick={actionOnClick}>Associated Learnings</button>
                                            <button className="dropdown-item" type='button' data-id={props.dataId} data-datatype={'quality'}
                                                data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button>
                                        </div>
                                    </div>

                                    {/* <button className="btn btn-sm" data-id={props.dataId} data-datatype={'quality'}
                                    data-type="Associated Risk" onClick={actionOnClick}>Associated Risk</button>
                                    <button className="btn btn-sm ml-2" data-id={props.dataId} data-datatype={'quality'}
                                        data-type="Associated Learning" onClick={actionOnClick}>Associated Learnings</button>
                                    <button className="btn btn-sm ml-2" data-id={props.dataId} data-datatype={'quality'}
                                        data-type="Associated MOC" onClick={actionOnClick}>Associated MOC</button> */}
                                </div>
                            </div>
                        </div>

                        <div className="detail-content collapse px-2 pt-3" id={`${props.dataType}${props.dataId}`}>
                            {/* --------Accordion start-------- */}
                            {summaryDescription("quality", props.dataId, props.dataSummary, props.dataDesc)}
                            {/* --------Accordion End-------- */}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
ResultCard.defaultProps = {
    dataType: "",
    dataId: "",
    dataTitle: "",
    dataDesc: "",
    dataSummary: "",
    dataLoB: "",
    dataCountry: "",
    dataProject: "",
    dataCharacterstics: "",
    dataDisciplineFunc: "",
    dataEquipmentType: "",
    dataCreatedModified: "",
    dataTags: []
}
export default ResultCard;
