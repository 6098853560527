import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import utils from './app-util';
import getAllReports from './report-utils';

function getReportList(setExistingReport) {
    getAllReports(setExistingReport, document.querySelector(".existingReportLoaderMsg"));
}
function createList(data) {
    return (
        <ol className='list-group list-group-flush list-group-numbered overflow-auto' style={{ maxHeight: "160px" }}>
            {
                data.map(({ id, title }) => {
                    return <li className='list-group-item pl-1 py-1' key={`rl${id}`} data-riskid={id}>{title}</li>;
                })
            }
        </ol>
    );
}
const getTitleList = (type, titles) => {
    type = type === 'emoc' ? 'eMoC' : type;
    return (
        <section className={`${type}-list-wrapper pl-1`}>
            <h6 className={`report-download-data-title rddt-${type}`}>{'Record'}s selected</h6>
            <div className={`${type}-list`}>
                {
                    titles.length > 0 ? createList(titles)
                        : <span className='small text-danger'>You have not selected anything to generate report</span>
                }
            </div>
        </section>
    );
}

function resetAllSelectedRiskForReportGeneration(type) {
    const checkedRiskLessonEmoc = document.querySelectorAll(`.${type}-item .addreport:checked`);
    checkedRiskLessonEmoc.forEach(function (inputElement) {
        inputElement.checked = false;
    });
}

function addToRiskLessonEmoc(url, payload, reportSaveBtn, reportForm, closeReportDownloadPopup, rdMessage, type) {
    const refreshDownloadList = document.querySelector(".download-list-refresh");
    reportSaveBtn.classList.add('active');
    reportSaveBtn.disabled = true;
    rdMessage.textContent = "Generating report...";
    fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(payload)
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        if (results.status) {
            reportSaveBtn.disabled = false;
            reportSaveBtn.classList.remove('active');
            closeReportDownloadPopup();
            const isExistingOptionSelected = reportForm.existingReportNameOption.checked;
            reportForm.reset();
            //Keep the existing option selected after reset if it has choosed by user
            if (isExistingOptionSelected) {
                reportForm.existingReportNameOption.checked = true;
            }
            toast.success("Successfully added");
            rdMessage.textContent = "";
            refreshDownloadList.click();
            resetAllSelectedRiskForReportGeneration(type)
        } else {
            reportSaveBtn.disabled = false;
            reportSaveBtn.classList.remove('active');
            rdMessage.textContent = "";
        }
    }, (_err) => {
        reportSaveBtn.disabled = false;
        reportSaveBtn.classList.remove('active');
        toast.error("Error occured");
        rdMessage.textContent = "";
    });
}
function GenerateReportPopup(props) {
    const [newname, setNewname] = useState(true);
    const [existingReport, setExistingReport] = useState([]);
    const [open, setOpen] = useState(props.show);
    const reportNameRef = React.createRef();
    const reportForm = React.createRef(), rdMessage = React.createRef();

    useEffect(() => {
        getReportList(setExistingReport);
        setOpen(props.show)
    }, [props.show])
    function optionOnChange(e) {
        console.log(e.target.value);
    }
    function onExistingReportOptionSelect(e) {
        if (e.target.checked) {
            setNewname(false);
        } else {
            setNewname(true);
        }
    }
    function openReportDownloadPopup(value) {
        return `modal report-download-modal ${value ? "show" : ""}`
    }
    function closeReportDownloadPopup() {
        setOpen(_open => false);
        props.afterClose(false);
    }
    function getReportType(dataType) {
        const cBoxes = document.querySelectorAll(`.report-option.report-option-${dataType} input[name='reportoption[]']:checked`), values = [];
        for (const cBox of cBoxes) {
            values.push(cBox.value);
        }
        return values;
    }
    function sendRequestToAddReportRiskLessonEmoc(url, payload, reportSaveBtn) {
        if (payload.reportoption !== undefined) {
            if (payload.reportoption.length > 0) {
                addToRiskLessonEmoc(url, payload, reportSaveBtn, reportForm.current, closeReportDownloadPopup, rdMessage.current, props.type);
            } else {
                toast.error("Please choose the option(e.g. Risk or Action or LFE)");
            }
        } else {
            addToRiskLessonEmoc(url, payload, reportSaveBtn, reportForm.current, closeReportDownloadPopup, rdMessage.current, props.type);
        }
    }
    function saveBtnOnClick(e) {
        const reportSaveBtn = e.target;
        const reportName = reportNameRef.current.value;

        const payload = {
            query: sessionStorage.getItem('query'),
            reportname: reportName,
            ids: [...new Set(props.ids)]
        }
        const urlList = { "risk": "/addtorisk", "lesson": "/addtolesson", "emoc": "/addtoemoc", "quality": "/addtoquality" };
        const url = urlList[props.type];
        if (props.type === "risk" || props.type === "quality") {
            payload['reportoption'] = getReportType(props.type);
        } 
        // The existing report can be checked only if user chooses new report name
        let existingReportChecked = true;
        if (newname) {
            existingReportChecked = !existingReport.flat().includes(reportName)
        }
        if (reportName !== "") {
            // Check if the user chooses new report name then check the existing list if the name exists before
            if (existingReportChecked) {
                sendRequestToAddReportRiskLessonEmoc(url, payload, reportSaveBtn);
            } else {
                toast.error("Report name is already exists");
            }
        } else {
            toast.error("Please enter the report name");
        }
    }
    return (
        <>
            <div className={openReportDownloadPopup(open)} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0 border-0">
                        <div className="modal-header">
                            <h5 className="modal-title ml-2">Generate Report</h5>
                            <div className='ml-auto align-self-center'>
                                <button type="button" className="close userpopup-close" data-dismiss="modal" aria-label="Close" onClick={closeReportDownloadPopup}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id='reportForm' ref={reportForm}>
                                <div className='p-2 report-download-wrapper small'>
                                    {
                                        getTitleList(props.type, props.titles)
                                    }

                                    <div className='pl-1'>
                                        {
                                            props.type === "risk" || props.type === "quality" ?
                                                <>
                                                    <div className='border-bottom my-3'></div>
                                                    <h6>Select the below options to generate report.</h6>
                                                    <section className={`report-option report-option-${props.type}`}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="reportoption[]" id="option1" value="Risk"
                                                                onChange={optionOnChange} defaultChecked={props.type === "risk"}  disabled={props.type === "risk"} />
                                                            <label className="form-check-label" htmlFor="option1">Risk</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="reportoption[]" id="option2" value="Action"
                                                                onChange={optionOnChange} />
                                                            <label className="form-check-label" htmlFor="option2">Action</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="reportoption[]" id="option3" value="LFE"
                                                                onChange={optionOnChange} />
                                                            <label className="form-check-label" htmlFor="option3">LFE</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="reportoption[]" id="option4" value="MOC"
                                                                onChange={optionOnChange} />
                                                            <label className="form-check-label" htmlFor="option4">MOC</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="reportoption[]" id="option5" value="Quality"
                                                                onChange={optionOnChange} defaultChecked={props.type === "quality"} disabled={props.type === "quality"}/>
                                                            <label className="form-check-label" htmlFor="option5">Quality</label>
                                                        </div>
                                                    </section>
                                                </> : null
                                        }

                                        <div className='border-bottom my-3'></div>
                                        <section className='position-relative'>
                                            <div className="form-group form-check">
                                                <input className="form-check-input" type="checkbox" id="existingReportName" name="existingReportNameOption"
                                                    onChange={onExistingReportOptionSelect} />
                                                <label className="form-check-label" htmlFor="existingReportName">Use existing report</label>
                                            </div>
                                            {
                                                newname ?
                                                    <div className='form-group newName'>
                                                        <label htmlFor='newReportInput'>Enter new report name</label>
                                                        <input ref={reportNameRef} type='text' id='newReportInput' name='reportname'
                                                            className='form-control rounded-0 report-name' placeholder='Enter new report name' />
                                                    </div>
                                                    :
                                                    <div className='form-group existingName'>
                                                        <label htmlFor='existingReportInput'>Enter existing report name</label>
                                                        <select ref={reportNameRef} className="custom-select custom-select-sm report-name" id='existingReportInput'>
                                                            <option defaultValue=""></option>
                                                            {
                                                                existingReport.map((report, _index) => {
                                                                    return <option value={report[0]} key={report[0]}>{report[0]}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                            }
                                            <span className="font-weight-bold existingReportLoaderMsg position-absolute" style={{ right: "20px", top: "36px" }}></span>
                                        </section>
                                    </div>
                                </div>
                            </form>
                            <div className='rd-message my-2 mx-3' ref={rdMessage}></div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-white border rounded-0" data-dismiss="modal" onClick={closeReportDownloadPopup}>Close</button>
                            <button type="button"
                                className={`btn btn-sm btn-red rounded-0 report-save-btn ${props.titles.length === 0 ? "disabled" : ""}`} onClick={saveBtnOnClick}>
                                <div className='Toastify__spinner mr-1 position-relative' style={{ width: "10px", height: "10px", top: "1.5px" }}></div>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </>
    );
}
export default GenerateReportPopup;
