import { createContext, useState, useMemo, useCallback, useContext, useEffect } from 'react';

const useEffectOnce = (effect) => {
    useEffect(effect, []);
}
export const useMount = (fn) => {
    useEffectOnce(() => {
        fn();
    });
};
const useSetState = (initialState = {}) => {
    const [state, set] = useState(initialState)
    const setState = useCallback(patch => {
        set(prevState =>
            Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch)
        )
    }, []);

    return [state, setState];
};

const appState = {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
    generateBtnRef: null // This is for the availability of the generate button 
};
export const AppContext = createContext({
    state: appState,
    setState: () => undefined,
});
AppContext.displayName = 'AppContext';

export function AppProvider(props) {
    const [state, setState] = useSetState(appState);

    const value = useMemo(() => ({
        state,
        setState,
    }),
        [setState, state],
    );

    return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within a AppProvider")
    }
    return context;
}
