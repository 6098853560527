import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DataGrid from 'react-data-grid';
import { EmptyRowsRenderer, FilterContext } from '../grid/user-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import * as d3 from "d3";
import Toolbar from '../grid/data-grid-toolbar';
import utils from '../app-util';
import {textfieldRenderer, handleSortingOnHeaderCellClick, togglefilterClass} from '../grid/grid-utils';

const HEADER_CLASSNAME = "filter-cell";
const timestampFormatter = ({ row }) => {
  return (
      <>{d3.timeFormat("%Y-%m-%d")(new Date(row.timestamp))}</>
  );
}
function userViewColumnFormat(_rows, _setRows, setFilters) {
  return [
    { key: 'userid', name: 'Email', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'userid') },
    { key: 'timestamp', name: 'Date', headerCellClass: HEADER_CLASSNAME, editable: false, formatter: timestampFormatter, headerRenderer: textfieldRenderer(setFilters, 'timestamp') },
    {
      key: 'views', name: 'Views', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'views'), groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { views }) => prev + views, 0)}</>;
      }
    },
    {
      key: 'downloads', name: 'Report Downloads', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'downloads'), groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { downloads }) => prev + downloads, 0)}</>;
      }
    },
    {
      key: 'thematic_bowtie_views', name: 'Thematic Bowtie Views', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'thematic_bowtie_views'), groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { thematic_bowtie_views }) => prev + thematic_bowtie_views, 0)}</>;
      }
    },
    {
      key: 'bowtie_views', name: 'Individual Bowtie Views', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'bowtie_views'), groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { bowtie_views }) => prev + bowtie_views, 0)}</>;
      }
    },
    {
      key: 'queries_searched', name: 'No.Of Queries Searched', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'queries_searched'), groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { queries_searched }) => prev + queries_searched, 0)}</>;
      }
    },
    // { key: 'last_login', name: 'Last login Timestamp', headerCellClass: HEADER_CLASSNAME, editable: false },
    // { key: 'last_session_duration', name: 'Time spent(in min)', headerCellClass: HEADER_CLASSNAME, editable: false },
    // { key: 'total_time_spent', name: 'Total Time spent(in min)', headerCellClass: HEADER_CLASSNAME, editable: false },
  ];
}

function UserView(props) {
  const [rows, setRows] = useState([]);
  const options = ['userid', 'timestamp'], labelNames = {'userid': 'Email', 'timestamp': 'Date'};
  const [selectedOptions, setSelectedOptions] = useState([options[0]])
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set([options[0]]));
  const filterColumnObj = { userid: "", timestamp: "", views:"", downloads: "", thematic_bowtie_views: "", bowtie_views: "", queries_searched: "", enabled: false };
  const [filters, setFilters] = useState(filterColumnObj);
  const [sortcolumn, setsortColumn] = useState([]);
  useEffect(() => {
    setRows(props.data);
    setTimeout(() => {
      document.querySelectorAll('.user-visit-grid .rdg-cell[role="columnheader"]').forEach((e) => {
        e.addEventListener('click', headerOnClick);
      })
    }, 2000);
  }, [props]);
  function headerOnClick(e) {
    handleSortingOnHeaderCellClick(e, setsortColumn);
  }
  const gridColumns = useMemo(() => {
    return userViewColumnFormat(rows, setRows, setFilters);
  }, [rows]);

  function toggleOption(option, _enabled) {
    setSelectedOptions([option])
    setExpandedGroupIds(new Set());
  }
  const onSortColumnsChange = useCallback((sortcolumns) => {
    setsortColumn(sortcolumns.slice(-1));
  }, []);
  const filteredRows = useMemo(() => {
    return projectRowsFilter(rows, filters);
  }, [rows, filters]);
  function projectRowsFilter(allrows, filterss) {
    return allrows.filter((r) => {
      return (
        (filterss.userid ? r.userid.toLowerCase().includes(filterss.userid.toLowerCase()) : true) &&
        (filterss.views ? r.views.toString() === filterss.views : true) &&
        (filterss.timestamp ? r.timestamp.toLowerCase().includes(filterss.timestamp.toLowerCase()) : true) &&
        (filterss.downloads ? r.downloads.toString() === filterss.downloads : true) &&
        (filterss.thematic_bowtie_views ? r.thematic_bowtie_views.toString() === filterss.thematic_bowtie_views : true) &&
        (filterss.bowtie_views ? r.bowtie_views.toString() === filterss.bowtie_views : true) &&
        (filterss.queries_searched ? r.queries_searched.toString() === filterss.queries_searched : true)
      );
    });
  }
  const griidSortedRows = useMemo(() => {
    if (sortcolumn.length === 0) {
      return filteredRows;
    }
    const { columnKey, direction } = sortcolumn[0];

    let gridSortedRows = [...filteredRows];

    switch (columnKey) {
      case 'userid':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'timestamp':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
        break;
      case 'views':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'downloads':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'thematic_bowtie_views':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'bowtie_views':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      case 'queries_searched':
        gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
        break;
      default:
    }
    return direction === 'DESC' ? gridSortedRows.reverse() : gridSortedRows;
  }, [filteredRows, sortcolumn]);
  function clearGridFilters() {
    togglefilterClass(true);
    setFilters(filterColumnObj);
  }
  function toggleGridFilters(v = true) {
    togglefilterClass()
    setFilters((filtersValues) => ({ ...filtersValues, enabled: v ? !filtersValues.enabled : filtersValues.enabled }));
  }

  function onDownloadClick(e) {
    const lastndays = document.querySelector('input[name=filteroption]:checked').value;
    const from = `${document.querySelector(".custom-date-filter input[name='daterange_from']").value}`;
    const to = `${document.querySelector(".custom-date-filter input[name='daterange_to']").value}`;
    if (from !== '' && to !== '') {
      utils.makeDownloadRequest(`/user-activity-download?lastndays=&daterange=${from} 00:00:00,${to} 23:59:59`, `individual_activity_details`, e.currentTarget, "Export to Excel");
    }else{
      utils.makeDownloadRequest(`/user-activity-download?lastndays=${lastndays}&daterange=`, `individual_activity_details`, e.currentTarget, "Export to Excel");
    }
  }
  
  const groupByComponent = (
    <fieldset className='userview-fieldset small mr-1'>
      <legend className="group-options">Group By</legend>
      {options.map((option, index) => (
        <div className='form-check form-check-inline' key={option}>
          <input className='form-check-input'
            type="radio"
            name='groupoption'
            defaultChecked={index === 0 ? true : false}
            onChange={(event) => toggleOption(option, event.target.checked)}
            id={option}
          />
          <label className='form-check-label' key={option} htmlFor={option}>{labelNames[option]}</label>
        </div>
      ))}

    </fieldset>
  );
  return (
    <div className='user-visit-grid' key={"u-v-g"}>
      <Toolbar enableFilter={true} enableGroupBy={true} enableDownloadButton={true} onToggleFilter={toggleGridFilters} onClearFilter={clearGridFilters} onDownloadClick={onDownloadClick} gropbyComponent={groupByComponent}/>
      <FilterContext.Provider value={filters}>
        <DataGrid key={"uv"}
          columns={gridColumns} rows={griidSortedRows} noRowsFallback={<EmptyRowsRenderer />}
          headerRowHeight={filters.enabled ? 70 : undefined}
          defaultColumnOptions={{
            sortable: false,
            resizable: true,
          }}
          sortColumns={sortcolumn}
          onSortColumnsChange={onSortColumnsChange}
          groupBy={selectedOptions}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
        />
      </FilterContext.Provider>
    </div>
  );
}
export default UserView
