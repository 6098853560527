import React, { useState, useEffect } from 'react';
import Search from './search';
import getCountryFlag from './countryflag';
import RaceSpinner from './spinner';
import { useHistory } from "react-router-dom";
import UserProfile from './user-profile';
import utils from './app-util';
import LogoBranding from './logo-branding';
import { toast } from 'react-toastify';
import IdleTimeTracker from './IdleTimeTrack/IdleTimeTracker';
import { useAppContext, useMount } from './context/context';

function catchError403(err, supressmessagepopup = false) {
    document.querySelector(".search-input-field").disabled = true;
    document.querySelector("#search-icon-btn").disabled = true;
    if (typeof err.json === "function") {
        err.json().then(function (jsonErr) {
            if (!supressmessagepopup) {
                toast.error(jsonErr.error);
            } else {
                console.warn("You have no permission to access Qurie");
            }
        }).catch(function (genericErr) {
            console.log(genericErr);
        })
    } else {
        console.log('Fetch Error :', err);
    }
}
function getUserInfo(setAdminInfo) {
    const interval = setInterval(() => {
        if (window.sessionStorage.getItem("_at") !== null && window.sessionStorage.getItem("_at") !== undefined) {
            fetch("/userinfo", { headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") } })
                .then(function (response) {
                    if (response.status !== 200) {
                        console.log('Error Status Code: ' + response.status);
                        catchError403(response, true);
                    } else {
                        response.json().then(function (resultResponse) {
                            if (resultResponse.success) {
                                setAdminInfo({isAdmin: resultResponse.admin, isAppOwner: resultResponse.app_owner, otheruser: resultResponse.otheruser});
                                sessionStorage.setItem('_uinfo', JSON.stringify(resultResponse));
                            }
                        });
                    }
                }).catch(function (err) {
                    catchError403(err, true);
                });
            clearInterval(interval);
        }
    }, 100);
}
function Searchpage(_props) {
    const [loading, setLoading] = useState(false);
    const isSearchPage = true;
    const history = useHistory();
    const [adminInfo, setAdminInfo] = useState({isAdmin: false, isAppOwner: false, otheruser: false});
    const { setState, state: { tourActive },} = useAppContext();
    useMount(()=>{
        if(tourActive){
            setState({ run: false});
        }
    });
    useEffect(() => {
        getUserInfo(setAdminInfo);
    }, [])
    function handleSearchOnKeyDown(inputVal) {
        sessionStorage.setItem("emocTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        if (isSearchPage && (inputVal !== "")) {
            const val = inputVal;
            setLoading(true);
            fetch("/query_results/" + val, {
                headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
            })
                .then((res) => {
                    if (res.status === 403) {
                        return Promise.reject(res);
                    } else {
                        return utils.checkSessionStatus(res);
                    }
                })
                .then(
                    (results) => {
                        if (results.risk.risk_id.length > 0 || results.lesson.ll_id.length > 0 || results.emoc.emoc_id.length > 0) {
                            const data = utils.formatData(results, getCountryFlag);
                            const indices = results.risk.risk_id;
                            sessionStorage.setItem("indices", JSON.stringify(indices));
                            sessionStorage.setItem("indicesemoc", JSON.stringify(results.emoc.emoc_id));
                            sessionStorage.setItem("indicesquality", JSON.stringify(results.quality.quality_id));
                            sessionStorage.setItem('query', inputVal);
                            fetch("/heatmap_data?risk_id=" + JSON.stringify(indices) + "&x_data=LoB&y_data=Functional_L1", {
                                headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
                            })
                                .then(res => {
                                    return utils.checkSessionStatus(res);
                                })
                                .then((resHm) => {
                                    setLoading(false);
                                    history.push({ pathname: '/dashboard', state: { query: val, results: data, heatMap: resHm, admin: adminInfo.isAdmin, appOwner:adminInfo.isAppOwner, otheruser: adminInfo.otheruser } })
                                },
                                    (_err) => {
                                            setLoading(false);
                                            toast.error("Error occured on fetching the query");
                                        }
                                    );
                            } else {
                                setLoading(false);
                                toast.error("Query doesn't fetch any results, please rephrase.");
                                document.querySelector('.search-input-field').value = "";
                            }
                        },
                        (err) => {
                            catchError403(err)
                            setLoading(false);
                            toast.error("Query doesn't fetch any results, please rephrase.");
                            document.querySelector('.search-input-field').value = "";
                        }
                ).catch((err) => {
                    console.error(err);
                    catchError403(err)
                    setLoading(false);
                });
        }
        
    }
    return (
        <div className="container-fluid">
            <div style={{ position: 'absolute', right: '25px', top: '20px', zIndex: 1 }}>
                <UserProfile admin={adminInfo.isAdmin} appOwner={adminInfo.isAppOwner} otheruser={adminInfo.otheruser}/>
            </div>
            <div className="row align-item-stretch">
                <div className="col-12 landing-page-left-split">
                    <header className="position-absolute mt-4">
                        {/* <div className="brand-wrapper">
                            <div className="brand-wrapper-inner d-flex justify-content-center align-items-center">
                                <div className="logo-wrap d-inline-block mr-2">
                                    <img className="logo" alt="" src={utils.logo}/>
                                </div>
                                <div className="brand-name-wrapper d-inline-block position-relative ml-2">
                                    <h2 className="brand-name m-0">Qurie</h2>
                                </div>
                            </div>
                        </div> */}
                        <LogoBranding showLogoText={false} />
                    </header>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="w-100 px-5 position-relative">
                            <Search isAdvancedSearch={false} isSearchPage={true} searchCallback={handleSearchOnKeyDown} />
                        </div>
                    </div>
                </div>
                {/* <div className="col landing-page-right-split"></div> */}
            </div>
            {loading ? <RaceSpinner /> : null}
            <IdleTimeTracker />
        </div>
    );
}

export default Searchpage;
