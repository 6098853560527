import React from 'react'
import VerticalBarChart from './VerticalBarChart';

export default function ProcessSafetyPlot(props) {
    return (
        <div className=''>
            <VerticalBarChart data={props.data} name={'processsafety'} dataType="quality" filterOnCall={props.filterOnCall}/>
        </div>
    );
}
