import React, { useEffect } from 'react';
import refresh from '../assets/refresh.svg';
import download from '../assets/download.svg';
import fullscreen from '../assets/fullscreen.svg';
import * as d3 from "d3";
import canvg from 'canvg'
import { toast } from 'react-toastify';
import $ from 'jquery';

export default function PlotMenu(props) {
    useEffect(()=>{
        $('[data-toggle="popover"]').popover();
    },[])
    function toggleFullScreen() {
        const fullscreenElem = document.querySelector(`#${props.fullscreenSelector}`);
        if (!document.fullscreenElement) {
            fullscreenElem.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    function fullscreenBtnOnClick(_e) {
        toggleFullScreen();
    }

    function downloadBtnOnClick(e) {
        const defaultFileName = sessionStorage.getItem('query') + " " + new Date().toDateString() + "_plot";
        const fileName = window.prompt("Name the file", defaultFileName);
        if (fileName !== null && fileName !== undefined) {
            if (fileName.search(/[/:\\*?"<>|]/g) === -1) {
                let optionalSelector = "";
                if(props.fullscreenSelectorOptional !== undefined){
                    optionalSelector = props.fullscreenSelectorOptional;
                }
                const selector = `#${props.fullscreenSelector} svg${optionalSelector}`
                const svgElement = document.querySelector(selector);
                const node = d3.select(selector).node();
                const serializer = new XMLSerializer();
                const xmlString = serializer.serializeToString(node);

                // Download as PNG
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = svgElement.getBBox().width;
                canvas.height = svgElement.getBBox().height;
                ctx.clearRect(0, 0, svgElement.getBBox().width, svgElement.getBBox().height);

                const v = canvg.fromString(ctx, xmlString);
                v.start();

                e.currentTarget.href = canvas.toDataURL('image/png');
                e.currentTarget.hreflang = "image/png";
                e.currentTarget.setAttribute('download', fileName);
                const targetAnchor = e.currentTarget;
                setTimeout(() => {
                    targetAnchor.removeAttribute('href');
                    targetAnchor.removeAttribute('download');
                    targetAnchor.removeAttribute('hreflang');
                }, 2000);
            } else {
                toast.warn("A file name can't contain any of these characters: \\:/*?\"<>|")
            }
        }
    }
    function refreshOnClick(e) {
        if (props.clearOnClick) {
            props.clearOnClick(e);
        }
    }
    return (
        <div className='plot-menu-wrapper position-relative d-flex justify-content-end'>
        <div className="plot-menu">
            <div className='refresh-plots-for-filter-cost'></div>
            <div className='refresh-plots-for-filter-schedule'></div>
            <div className='refresh-plots-for-filter-probability'></div>
            <div className='refresh-plots-for-filter-emoc'></div>
            <div className='refresh-plots-for-filter-quality'></div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="icon-wrap" tabIndex="0" title="Plot Information" data-trigger="focus" data-content={props.info} style={{color: '#ffffff'}} data-toggle="popover">i</div>
                {props.showRefreshBtn ?
                    <div className={`icon-wrap refresh ${props.customClass}`} data-classname={props.customClass} title={props.title} onClick={refreshOnClick}><img src={refresh} alt="Refresh" /></div> : null}
                <div className="icon-wrap download" title="Download Visual">
                    <a className="text-decoration-none" onClick={downloadBtnOnClick}><img src={download} alt="Download Visual" /></a></div>
                <div className="icon-wrap fullscreen" title="Fullscreen"><img src={fullscreen} alt="Fullscreen" onClick={fullscreenBtnOnClick} /></div>
            </div>
        </div>
        </div>
    );
}
