import utils from "./app-util";

function getAllReports(callBack, messageElementObject = null) {
    let loaderMessagePlaceholder = document.querySelector(".download-list-refresh");
    if (messageElementObject !== null) {
        loaderMessagePlaceholder = messageElementObject;
    }
    loaderMessagePlaceholder.textContent = "Loading...";
    fetch(`/all_reports`, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(res => {
            return utils.checkSessionStatus(res);
        })
        .then(
            (results) => {
                if (typeof callBack === 'function') {
                    callBack(results.sort());
                }
                loaderMessagePlaceholder.textContent = "";
            },
            (_err) => {
                console.error("No reports found");
                loaderMessagePlaceholder.textContent = "";
            }
        ).catch((err) => {
            console.error(err);
            loaderMessagePlaceholder.textContent = "";
        });
}

export default getAllReports;
