import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";
import utils from '../app-util';
import { createLegend } from '../stacked-bar-chart';

const TEXT_ANCHOR = "text-anchor";
function createColorScale(groups, interpolateColor) {
    const colorRange = [], groupLength = groups.length;
    const valConst = 1 / groupLength;
    let incrementedVal = 0, i = 0;
    while (i < groupLength) {
        incrementedVal = incrementedVal + valConst;
        colorRange.push(interpolateColor(incrementedVal))
        i++;
    }
    colorRange.reverse();
    const colorScale = d3.scaleOrdinal().domain(groups).range(colorRange);
    return colorScale;
}

function checkValue(values, keys) {
    let allZero = true;
    for (let i = 0; i < values.length; ++i) {
        for (let j = 0; j < keys.length; ++j) {
            if (values[i][keys[j]] > 0) {
                allZero = false;
                break;
            }
        }
    }
    return allZero;
}

function checkIfAllValueZero(values, _newData, keys) {
    let allZero = true;
    if (values !== undefined) {
        allZero = checkValue(values, keys)
    }
    return allZero;
}
class StackedBarchartNegative extends React.Component {
    constructor(props) {
        super(props);
        this.createBarChart = this.createBarChart.bind(this);
        this.node = React.createRef();
        this.msgElem = React.createRef();
        this.legendContainer = React.createRef();
        this.legendContainerNegative = React.createRef();
    }

    componentDidMount() {
        this.margin = { top: 50, right: 10, bottom: 40, left: 40 };
        this.svg = d3.select(this.node.current);
        
        this.width = utils.getFixedPlotDimension().width - this.margin.left - this.margin.right - 20;
        this.height = utils.getFixedPlotDimension().height - this.margin.left - this.margin.right;
        this.xScale = d3.scaleBand().range([0, this.width]).padding(0.1);
        this.yScale = d3.scaleLinear().range([this.height, 0]);
        this.xAxis = d3.axisBottom(this.xScale);
        this.yAxis = d3.axisLeft(this.yScale);
        
        this.center = d3.scaleLinear().range([0, this.width]);
        this.centerLine = d3.axisTop(this.center).ticks(0);
        this.svg.append("g")
                .attr("class", "centerline")
                .attr("transform", `translate(${this.margin.left}, ${this.yScale(0) + this.margin.top} )`)
        d3.select(this.node.current).select(function () {
            this.parentNode.style.overflow = "auto";
        })
        this.svg
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom);
        this.svg.append("g").attr("class", "x axis ")
            .attr('id', "xAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.height + this.margin.top})`);
        this.svg.append("g")
            .attr("class", "y axis")
            .attr('id', "yAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

        this.svg.append("text")
            .attr('class', "xlabel")
            .attr(TEXT_ANCHOR, "end")
            .attr("x", this.width / 2 + 100)
            .attr("y", this.height + this.margin.bottom + this.margin.top - 5)
            .text(this.props.data[0].x_label)
            .style("font-size", "12px");
        this.svg.append("text")
            .attr('class', "ylabel")
            .attr("x", -200)
            .attr("y", 15)
            .attr("transform", "rotate(-90)")
            .style("font-size", "12px")
            .attr(TEXT_ANCHOR, "start")
            .text(this.props.data[0].y_label);

        this.tip = d3Tip()
            .attr("class", "d3-tooltip")
            .offset([-10, 0])
            .html(function (_d) {
                const nodeData = d3.select(this).data()[0];
                const key = d3.select(this).attr("data-key");
                return `${key}: ${nodeData.data[key]}`;
            });
        this.svg.call(this.tip)
        
        this.createBarChart();

        const __this = this
        const defaultWidth = parseFloat(__this.svg.attr('width') - 50);
        const defaultHeight = parseFloat(__this.svg.attr('height'));
        function updateOnFS() {
            document.querySelector("#moccost.tab-pane.active.show.graph .f-screen").style.width = __this.width;
            document.querySelector("#moccost.tab-pane.active.show.graph .f-screen").style.height = __this.height;
            __this.svg.attr("width", __this.width + __this.margin.left + __this.margin.right)
                .attr("height", __this.height + __this.margin.top + __this.margin.bottom);
            __this.xScale.range([0, __this.width]).padding(0.1)
            __this.yScale.range([__this.height, 0])
            __this.svg.select('.x.axis').attr("transform", "translate(" + __this.margin.left + "," + (__this.height + __this.margin.top) + ")");
            __this.svg
                .transition()
                .attr("width", __this.width + __this.margin.left + __this.margin.right)
                .attr("height", __this.height + __this.margin.top + __this.margin.bottom)

            __this.svg.select(".xlabel")
                .attr("x", __this.width / 2 + 100)
                .attr("y", __this.height + __this.margin.bottom + __this.margin.top - 5);
        }
        [...document.querySelectorAll("#moccost.tab-pane.graph")].forEach(function (element) {
            element.addEventListener('fullscreenchange', (_e) => {
                if (document.fullscreenElement) {
                    __this.width = window.innerWidth - 100;
                    __this.height = window.innerHeight - 100;
                    updateOnFS()
                } else {
                    __this.width = defaultWidth;
                    __this.height = defaultHeight;
                    updateOnFS()
                }
                setTimeout(() => {
                    __this.createBarChart();
                }, 100);
            })
        })
        this.svg.attr("class", this.props.type);
    }

    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (this.props.data[0].isNew) {
            this.createBarChart();
        }
    }

    
    rectBarOnClick(_selfContext) {
        let filter = false;
        if (d3.select(_selfContext).classed("active") && this.svg.classed('clickactive')) {
            d3.select(_selfContext).classed("active", false);
            this.svg.classed('clickactive', false);

        } else {
            d3.select(_selfContext).classed("active", true);
            this.svg.classed('clickactive', true);
            filter = true;
        }
        // clear the highlighted rect on other rect clicking
        if ((this.isRectHighlighted !== null) && (!this.isRectHighlighted.isSameNode(_selfContext))) {
            this.isRectHighlighted.classList.remove('active');
        }
        
        this.isRectHighlighted = _selfContext;

        const idsOrCategory = d3.select(_selfContext).attr("data-ids");
        this.props.stackedBarChartFIlterCallback({ id: idsOrCategory, filter: filter });
    }
    createBarChart() {
        const __this = this;
        const data = this.props.data;
        
        if (!checkIfAllValueZero(data[0].data, this.props.data[0].isNew, this.props.data[0].groups)) {
            __this.svg.style('display', 'block')
            __this.msgElem.current.classList.remove('active');
            this.props.data[0].isNew = false;
            const type = ['pos', 'neg'];
            const colorInterpolateBlue = d3.scaleLinear().domain([0, 1]).range(["white", "blue"]);
            data.forEach(function(iterator, index){
                const groups = iterator.groups;
                
                if(index === 0){
                    __this.color = createColorScale(groups, d3.interpolateReds)
                }else {
                    __this.color = createColorScale(groups, colorInterpolateBlue)
                }
                if(__this.legends === undefined){
                    createLegend(__this, groups, __this.color, __this.legendContainer.current);
                    createLegend(__this, groups, createColorScale(groups, colorInterpolateBlue), __this.legendContainerNegative.current);
                }
                const dataPlot = iterator.data;

                // ========================================================================================================
                const stack = d3.stack().keys(groups).order(d3.stackOrderNone).offset(d3.stackOffsetDiverging);
                function stackMax(_stack) {
                    return d3.max(_stack, function (d) {
                        return d[1];
                    });
                }
                function stackMin(_stack) {
                    return d3.min(_stack, function (d) {
                        return d[0];
                    });
                }

                __this.xScale.domain(dataPlot.map(function (d) {
                    return d.label;
                }));
                __this.yScale.domain([d3.min(stack(data[1].data), stackMin), d3.max(stack(data[0].data), stackMax)]);
                
                //------------------------------------------------------------------------------------------------------
                const x_var = "label";
                __this.isRectHighlighted = null;
                __this.svg.selectAll(".bar"+type[index]).remove();
                groups.forEach(function (key, key_index) {
                    const bar = __this.svg.selectAll(".bar-"+ type[index] +"-" + key)
                        .data(stack(dataPlot)[key_index], function (d) {
                            return d.data[x_var] + "-" + key;
                        });

                    bar.transition()
                        .attr("x", function (d) { return __this.xScale(d.data[x_var]); })
                        .attr("y", function (d) { return __this.yScale(d[1]); })
                        .attr("height", function (d) { return __this.yScale(d[0]) - __this.yScale(d[1]); });
                    bar.enter().append("rect")
                        .attr("class", function (_d) { return "bar bar" + type[index] + " bar-" + key; })
                        .attr("data", function (d) {

                            return d.data[key];
                        })
                        .attr("data-key", key)
                        .attr("data-ids", function (d, i) {
                            return d.data[`${__this.props.dataType}_ids`][key][i];
                        })
                        .attr("x", function (d) { return __this.xScale(d.data[x_var]); })
                        .attr("y", function (d) { return __this.yScale(d[1]); })
                        .attr("height", function (d) { return __this.yScale(d[0]) - __this.yScale(d[1]); })
                        .attr("width", __this.xScale.bandwidth())
                        .attr("fill", function (_d) { return __this.color(key); }).attr("transform", `translate(${__this.margin.left}, ${__this.margin.top})`)
                        .attr("stroke", "#fff").attr("stroke-width", ".2px")
                        .on("mouseover", __this.tip.show)
                        .on("mouseout", __this.tip.hide)
                        .on("click", function (_d) {
                            __this.rectBarOnClick(this);
                        }).style("cursor", "pointer");
                });
            })

            __this.svg.select('.centerline')
            .attr("transform", `translate(${__this.margin.left}, ${__this.yScale(0) + __this.margin.top} )`)
            __this.svg.select('.centerline').call(this.centerLine);
            __this.svg.select('.x.axis').call(this.xAxis);
            __this.svg.select('.y.axis').call(this.yAxis);
        
        } else {
            __this.svg.style('display', 'none')
            __this.msgElem.current.classList.add('active');
        }
    }

    render() {
        return (
            <>
                <div className='no-data-msg-placeholder font-italic my-4 small text-center' ref={this.msgElem}>No data found</div>
                <div className='legendContainerStackedBarchartPos' ref={this.legendContainer} style={{textAlign: 'justify'}}></div>
                <div className='legendContainerStackedBarchartNeg' ref={this.legendContainerNegative} style={{textAlign: 'justify'}}></div>
                <svg ref={this.node} width="100%" height="300"></svg>
            </>
        );
    }
}
export default StackedBarchartNegative
