import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import utils from '../app-util';

function resetForm(setProjectacronym) {
    document.querySelector("#acronymsForm").reset();
    setProjectacronym([])
}
function spinnerDisableSaveBtn(value) {
    const btn = document.querySelector(".btn-acronym-save");
    if (value) {
        btn.classList.add('loading');
        btn.disabled = true;
    } else {
        btn.classList.remove('loading');
        btn.disabled = false;
    }
}
function saveUpdateRequest(url, payload, props, setProjectacronym, setOpenpopup) {
    spinnerDisableSaveBtn(true);
    fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(payload)
    })
        .then(function (response) {
            if (response.status !== 200) {
                utils.checkSessionStatus(response)
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    setProjectacronym([])
                    spinnerDisableSaveBtn(false);
                    resetForm(setProjectacronym);
                    toast.success(data.message);
                    setOpenpopup(false);
                    props.afterPopupClose(false);
                    props.afterProjectSubmit(payload, props.isEditOn);
                } else {
                    setProjectacronym([])
                    spinnerDisableSaveBtn(false);
                    setOpenpopup(false);
                    toast.warn(data.message);
                    props.afterPopupClose(false);
                }
            });
        }).catch(function (err) {
            spinnerDisableSaveBtn(false);
            console.log('Fetch Error :-S', err);
        });
}
function getFormData(props, projectacronym) {
    const projectName = document.querySelector("#projectTitle").value;
    const projectCase = document.querySelector("#projectTitleCase").checked ? 1 : 0;
    const payload = {};
    if (props.isEditOn) {
        payload["id"] = props.editData.id;
    }
    payload["mask_status"] = document.querySelector("#switchMask").checked ? 1 : 0;
    payload["project"] = {};
    payload["project"][projectName] = projectCase;
    let dict = new Map()
    projectacronym.forEach(element => {
        const key = Object.keys(element)[0];
        dict.set(key, element[key]);
    });
    dict = Object.fromEntries(dict);
    payload["acronym"] = dict;

    return payload;
}
function saveUpdateAction(projectacronym, setError, setOpenpopup, props, setProjectacronym, url) {
    const payload = getFormData(props, projectacronym);
    setError({ project: "", acronym: "" });
    if (Object.keys(payload["project"])[0] !== '') {
        saveUpdateRequest( url, payload, props, setProjectacronym, setOpenpopup)
    } else {
        setError({ project: "Enter the project title" });
    }
}

function createacronymChip(projectacronym, chipRemoveOnClick) {
    return (
        <>
            {
                projectacronym !== null && projectacronym.length > 0 ? projectacronym.map((elem, _index) => {
                    const val = Object.keys(elem)[0]
                    return (<div key={`chip${val}`} className="chip chip-acronym small d-flex justify-content-between px-2 py-1 my-1 mr-1"
                        data-chip={val} data-casesensitive={elem[val]}>
                        <span className="label col-7 px-0 align-self-center" title={val}>{val}</span>
                        <input className="align-self-center" type="checkbox" defaultChecked={elem[val]} disabled />
                        <span className="chip-colse align-self-center" onClick={chipRemoveOnClick}>&times;</span></div>);
                }) : null
            }
        </>
    )
}
function fillTheFormIfEditOn(props, setProjectacronym) {
    const acronyms = []
    for (const key in props.editData.acronym) {
        if (Object.hasOwnProperty.call(props.editData.acronym, key)) {
            const objElement = {};
            objElement[key] = props.editData.acronym[key]
            acronyms.push(objElement)
        }
    }
    document.getElementById("switchMask").checked = props.editData.mask_status;
    const project =  Object.entries(props.editData.project)[0]
    document.getElementById("projectTitle").value = project[0];
    document.getElementById("projectTitleCase").checked = project[1];
    setProjectacronym(acronyms)
}
export default function ProjectFormPopup(props) {
    const [projectacronym, setProjectacronym] = useState([]);
    const [error, setError] = useState({ project: "", acronym: "" });
    const [openpopup, setOpenpopup] = useState(props.show);
    const chipRef = useRef(null);
    useEffect(() => {
        setOpenpopup(props.show);
        if (props.isEditOn) {
            // if edit mode is on fill the form with prefilled values
            fillTheFormIfEditOn(props, setProjectacronym);
        }else{
            resetForm(setProjectacronym)
        }
    }, [props])
    function addBtnOnClick(e) {
        e.preventDefault();
        const projectAcronymVal = document.querySelector("#projectAcronym").value;
        const isCaseSensitive = document.querySelector("#projectAcronymCase").checked ? 1 : 0;
        setError({ acronym: "" });
        if (projectAcronymVal !== "") {
            if (projectacronym.findIndex((element) => element[projectAcronymVal] !== undefined) === -1) {
                const key = {};
                key[projectAcronymVal] = isCaseSensitive
                setProjectacronym(arr => [...arr, key]);
                document.querySelector("#projectAcronym").value = "";
            } else {
                setError({ acronym: "Already exists in the list" })
            }
        } else {
            setError({ acronym: "Enter the acronym" })
        }

    }
    function chipRemoveOnClick(e) {
        e.preventDefault();
        setError({ acronym: "" });
        if (document.querySelector(".chiplist").childElementCount > 0) {
            const chip = e.target.parentNode.dataset.chip;
            const projectacronyms = [...projectacronym];
            const index = projectacronyms.findIndex((element) => element[chip] !== undefined);
            e.target.parentNode.style.display = "none";
            projectacronyms.splice(index, 1);
            setProjectacronym(projectacronyms);
        }
    }
    function titleOnChange(e) {
        e.preventDefault();
        if (e.target.value === "") {
            setError({ project: "Enter the project title" });
        } else {
            setError({ project: "" });
        }
    }

    function saveOnClick(e) {
        e.preventDefault();
        if (!props.isEditOn) {
            saveUpdateAction(projectacronym, setError, setOpenpopup, props, setProjectacronym, '/mask');
        } else {
            saveUpdateAction(projectacronym, setError, setOpenpopup, props, setProjectacronym, '/update-project');
        }
    }
    function cancelOnClick() {
        setOpenpopup(false);
        props.afterPopupClose(false);
    }
    function projectPopupOnClose() {
        setOpenpopup(false);
        props.afterPopupClose(false);
    }
    return (
        <div className={`project-popup ${openpopup ? "active" : ""}`}>
            <div className='project-popup-inner d-flex justify-content-center align-items-start'>
                <div className="p-3 project-popup-modal position-relative mt-5" style={{ width: "650px" }}>
                    <div className='close-project-popup border' onClick={projectPopupOnClose}>&times;</div>
                    {
                        !props.isEditOn ? <h5>Add project title and/or acronyms</h5> : <h5>Edit project title and/or acronyms</h5>
                    }
                    
                    <form id="acronymsForm" className='mt-3'>
                        <div className="form-row">
                            <div className="form-group col-12">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="switchMask" />
                                    <label className="custom-control-label" htmlFor="switchMask">Yes, I want to mask the following</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-6">
                                <label htmlFor="projectTitle">Project Title<span className="text-danger">*</span></label>
                                <input className="form-control form-control-sm" id="projectTitle" type="text" placeholder="" onChange={titleOnChange} />
                            </div>
                            <div className="form-group col-6 align-self-end acronyms-custom">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="projectTitleCase" value="project-case-sensitive" />
                                    <label className="custom-control-label" htmlFor="projectTitleCase">Case sensitive</label>
                                </div>
                            </div>
                        </div>
                        <div className="text-danger small mb-2">{error.project}</div>
                        <div className="form-row">
                            <div className="form-group col-6">
                                <label htmlFor="projectAcronym">Project Acronyms</label>
                                <input className="form-control form-control-sm" id="projectAcronym" type="text" placeholder="" />
                            </div>
                            <div className="form-group col-3 align-self-end acronyms-custom">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="projectAcronymCase" value="acronym-case-sensitive" />
                                    <label className="custom-control-label" htmlFor="projectAcronymCase">Case sensitive</label>
                                </div>
                            </div>
                            <div className="form-group col-2 align-self-end">
                                <button type="button" className="btn btn-sm acronym-add-btn" onClick={addBtnOnClick}>Add</button>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <div className="chiplist d-flex flex-wrap" key={"chiplistparent"} ref={chipRef}
                                    style={{ maxHeight: "150px", overflowY: "auto", marginLeft: '6px' }}>
                                    {
                                        createacronymChip(projectacronym, chipRemoveOnClick)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="text-danger small mb-2">{error.acronym}</div>
                        <span className="small font-italic">Note: Input fields marked as (<span className="text-danger">*</span>) are mandatory fields.</span>
                        <div className="form-row my-2">
                            <div className="d-flex justify-content-between w-50">
                                <div className="col">
                                    <button type="button" className="btn btn-sm btn-acronym-cancel" onClick={cancelOnClick}>Close</button>
                                </div>
                                <div className="col">
                                    <button type="button" className="btn btn-sm btn-acronym-save" onClick={saveOnClick}>
                                        <span className='Toastify__spinner'></span> {props.isEditOn ? "Update" : "Save"}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
