
function summaryDescription(dataType, id, summary, description) {
    return (
        <div className="accordion" id={`${dataType}SummaryDescription${id}`}>
            <div className="card border-0 bg-transparent">
                <div className="card-header px-0 py-2 bg-transparent border-top" id={`${dataType}summaryAccordion${id}`}>
                    <h2 className="mb-0">
                        <button className="btn btn-sm btn-block btn-accordion text-left font-weight-bold px-1" type="button" data-toggle="collapse"
                            data-target={`#collapseOne${dataType}${id}`} aria-expanded="true" aria-controls={`collapseOne${dataType}${id}`}>
                            Summary
                        </button>
                    </h2>
                </div>

                <div id={`collapseOne${dataType}${id}`} className="collapse show" aria-labelledby={`${dataType}summaryAccordion${id}`}
                    data-parent={`#${dataType}SummaryDescription${id}`}>
                    <div className="card-body px-1 py-2">
                        {summary}
                    </div>
                </div>
            </div>
            <div className="card border-0 bg-transparent">
                <div className="card-header px-0 py-2 bg-transparent border-top" id={`${dataType}descriptionAccordion${id}`}>
                    <h2 className="mb-0">
                        <button className="btn btn-sm btn-block btn-accordion text-left collapsed font-weight-bold px-1" type="button" data-toggle="collapse"
                            data-target={`#collapseTwo${dataType}${id}`} aria-expanded="false" aria-controls={`collapseTwo${dataType}${id}`}>
                            Description
                        </button>
                    </h2>
                </div>
                <div id={`collapseTwo${dataType}${id}`} className="collapse" aria-labelledby={`${dataType}descriptionAccordion${id}`}
                    data-parent={`#${dataType}SummaryDescription${id}`}>
                    <div className="card-body px-1 py-2">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default summaryDescription;
