import React from 'react';
import leftArrow from '../assets/arrow-left.svg'
import RaceSpinner from './spinner';
import utils from './app-util';
import { toast } from 'react-toastify';
import summaryDescription from './summaryDescription';
import Tagging from './qualityComponent/Tagging';
import getCountryFlag from './countryflag';

const ASSOCIATED_MOC = "Associated MOC", ASSOCIATED_LEARNING = "Associated Learning", ASSOCIATED_QUALITY =  "Associated Quality";
function getURL(title, query, id, datatype) {
    let url = "";
    if(datatype === 'risk') {
        if (title === ASSOCIATED_MOC) {
            url = `/get_assoc_moc/${query}/${id}`;
        } else if (title === ASSOCIATED_LEARNING) {
            url = `/get_assoc_learnings/${query}/${id}`;
        }else if (title === ASSOCIATED_QUALITY) {
            url = `/get_assoc_quality/${query}/${id}`;
        } else {
            url = `/get_actions/${id}`;
        }
    }
    if(datatype === 'quality') {
        if (title === ASSOCIATED_MOC) {
            url = `/get_assoc_quality_moc/${query}/${id}`;
        } else if (title === ASSOCIATED_LEARNING) {
            url = `/get_assoc_quality_learnings/${query}/${id}`;
        } else {
            url = `/get_quality_assoc_risk/${query}/${id}`;
        }
    }
    return url;
}
const severityQualityData = { 1: "Slight", 2: "Minor", 3: "Moderate", 4: "Major", 5: "Severe" };
class LearnAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.data.id,
            title: props.data.title,
            datatype: props.data.datatype,
            result: null,
            query: props.data.query,
            loadSpinner: false
        };
    }
    fetchData(url, triggerPlace, titleType) {
        fetch(url, {
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(res => {
                return utils.checkSessionStatus(res);
            })
            .then(
                (results) => {
                    if (triggerPlace === "Panel") {
                        this.setState({ result: results, title: titleType, loadSpinner: false });
                    } else {
                        this.setState({ result: results, loadSpinner: false });
                    }
                },
                (_err) => {
                    toast.error("Error occured on fetching the query");
                    this.setState({ loadSpinner: false });
                }
            )
    }
    componentDidMount() {
        this.setState({ loadSpinner: true })
        const url = getURL(this.state.title, this.state.query, this.state.id, this.state.datatype);
        this.fetchData(url, "OnMount");
    }
    
    onClick(_e) {
        this.props.parentCallback({ close: true });
    }
    
    mocItems() {
        const keyList = utils.getUinqueRandomNumberList(30, 40);
        return (
            <div className="assoc-moc-items">
                {
                    this.state.result !== null ?
                        this.state.result.emoc_desc.map((item, index) => {
                            return (
                                <div key={`assocmoc${keyList[index]}`} className="card card-box-shadow p-2 mb-4">
                                    <div className="titles" data-toggle="collapse" data-target={`#assocMOC${index + 1}`}
                                        aria-expanded="false" aria-controls={`assocMOC${index + 1}`} style={{ cursor: "pointer" }}>
                                        <h6 className="font-weight-bold">
                                            {this.state.result !== null ? this.state.result.emoc_title[index] : ''}
                                        </h6>
                                        <p className="subdescription small mb-4">{item !== '' && item !== null ? item.slice(0, 160) : item}</p>
                                    </div>
                                    {/* <p id={`assocMOC${index + 1}`} className={`assoc-moc-${index + 1} m-0 collapse`}>{item}</p> */}
                                    <div id={`assocMOC${index + 1}`} className={`assoc-moc-${index + 1} m-0 collapse`}>
                                        {
                                            summaryDescription("assocMOC", index, this.state.result.emoc_desc_summary[index], item)
                                        }
                                    </div>
                                </div>
                            )
                        }) : null
                }
            </div>
        );
    }
    actionItems() {
        const keyList = utils.getUinqueRandomNumberList(30, 40);
        return (
            <div className="action-items">
                {
                    this.state.result !== null ?
                        this.state.result.action_desc.map((item, index) => {
                            return (
                                <div key={`action${keyList[index]}`} className="card card-box-shadow p-2 mb-4">
                                    <div className="titles" data-toggle="collapse" data-target={`#action${index + 1}`}
                                        aria-expanded="false" aria-controls={`action${index + 1}`} style={{ cursor: "pointer" }}>
                                        <h6 className="font-weight-bold">
                                            {this.state.result !== null ? this.state.result.action_title[index] : ''}
                                        </h6>
                                        <p className="small mb-4">{item !== '' && item !== null ? item.slice(0, 160) : item}</p>
                                    </div>
                                    <p id={`action${index + 1}`} className={`action-${index + 1} m-0 collapse`}>{item}</p>
                                </div>
                            )
                        }) : null
                }
            </div>
        );
    }

    associatedLearningItems() {
        const keyList = utils.getUinqueRandomNumberList(30, 40);
        return (
            <div className="associated-learn-items">
                {
                    this.state.result !== null ?
                        this.state.result.ll_desc.map((item, index) => {
                            return (
                                <div key={`learnings${keyList[index]}`} className="card card-box-shadow p-2 mb-4">
                                    <div className="titles" data-toggle="collapse" data-target={`#learning${index + 1}`}
                                        aria-expanded="false" aria-controls={`learning${index + 1}`} style={{ cursor: "pointer" }}>
                                        <h6 className="font-weight-bold">{this.state.result !== null ? this.state.result.ll_title[index] : ''}</h6>
                                        <p className="small mb-4">{item !== '' && item !== null ? item.slice(0, 160) : item}</p>
                                    </div>
                                    {/* <p id={`learning${index + 1}`} className={`learning-${index + 1} m-0 collapse`}>{item}</p> */}
                                    <div id={`learning${index + 1}`} className={`learning-${index + 1} m-0 collapse`}>
                                        {summaryDescription("assocLearnings", index, this.state.result.ll_desc_summary[index], item)}
                                    </div>
                                </div>
                            )
                        }) : null
                }
            </div>
        );
    }
    associatedQualityItems() {
        const keyList = utils.getUinqueRandomNumberList(30, 40);
        return (
            <div className="associated-quality-items">
                {
                    this.state.result !== null ?
                        this.state.result.quality_desc.map((item, index) => {
                            return (
                                <div key={`assocquality${keyList[index]}`} className="card card-box-shadow p-2 mb-4">
                                    <div className="titles" data-toggle="collapse" data-target={`#assocQuality${index + 1}`}
                                        aria-expanded="false" aria-controls={`assocQuality${index + 1}`} style={{ cursor: "pointer" }}>
                                        <h6 className="font-weight-bold">
                                            {this.state.result !== null ? this.state.result.quality_title[index] : ''}
                                        </h6>
                                        <p className="subdescription small mb-4">{item !== '' && item !== null ? item.slice(0, 160) : item}</p>
                                        <div className='meta-info'>
                                            <Tagging tagList={[
                                                { labelName: "Location", flag: getCountryFlag(this.state.result.quality_loc[index]), value: this.state.result.quality_loc[index].toLowerCase() },
                                                { labelName: "Project Name", value: this.state.result.quality_org[index] },
                                                { labelName: "Severity", value: severityQualityData[this.state.result.quality_severity[index]] }
                                            ]} />
                                        </div>
                                    </div>
                                    {/* <p id={`assocQuality${index + 1}`} className={`assoc-quality-${index + 1} m-0 collapse`}>{item}</p> */}
                                    <div id={`assocQuality${index + 1}`} className={`assoc-quality-${index + 1} m-0 collapse`}>
                                        {
                                            summaryDescription("assocQuality", index, this.state.result.quality_summary[index], item)
                                        }
                                    </div>
                                </div>
                            )
                        }) : null
                }
            </div>
        );
    }
    associatedQualityRiskItems() {
        const keyList = utils.getUinqueRandomNumberList(30, 40);
        return (
            <div className="associated-quality-risk-items">
                {
                    this.state.result !== null ?
                        this.state.result.risk_desc.map((item, index) => {
                            return (
                                <div key={`assocqualityrisk${keyList[index]}`} className="card card-box-shadow p-2 mb-4">
                                    <div className="titles" data-toggle="collapse" data-target={`#assocQualityRisk${index + 1}`}
                                        aria-expanded="false" aria-controls={`assocQualityRisk${index + 1}`} style={{ cursor: "pointer" }}>
                                        <h6 className="font-weight-bold">
                                            {this.state.result !== null ? this.state.result.risk_title[index] : ''}
                                        </h6>
                                        <p className="subdescription small mb-4">{item !== '' && item !== null ? item.slice(0, 160) : item}</p>
                                        <div className='meta-info'>
                                            <Tagging tagList={[
                                                { labelName: "Location", flag: getCountryFlag(this.state.result.risk_loc[index]), value: this.state.result.risk_loc[index].toLowerCase() },
                                                { labelName: "Project Name", value: this.state.result.risk_org[index] },
                                                { labelName: "Severity", value: this.state.result.risk_severity[index] }
                                            ]} />
                                        </div>
                                    </div>
                                    <p id={`assocQualityRisk${index + 1}`} className={`assoc-quality-risk-${index + 1} m-0 collapse`}>{item}</p>
                                    {/* <div id={`assocQualityRisk${index + 1}`} className={`assoc-quality-${index + 1} m-0 collapse`}>
                                        {
                                            summaryDescription("assocQualityRisk", index, this.state.result.quality_summary[index], item)
                                        }
                                    </div> */}
                                </div>
                            )
                        }) : null
                }
            </div>
        );
    }
    resultTemplate(title) {
        if (title === ASSOCIATED_MOC) {
            return this.mocItems();
        } else if (title === ASSOCIATED_LEARNING) {
            return this.associatedLearningItems();
        } else if (title === ASSOCIATED_QUALITY) {
            return this.associatedQualityItems();
        } else if (title === "Associated Risk") {
            return this.associatedQualityRiskItems();
        } else {
            return this.actionItems();
        }
    }
    render() {
        return (
            <div className="" style={{ fontFamily: "FuturaLight" }}>
                <button className="btn btn-sm my-1 text-uppercase" onClick={this.onClick.bind(this)}>
                    <img className="mr-2" alt="Back to results" src={leftArrow} />Results</button>

                <div className="card card-box-shadow p-3">
                    <div className="d-flex">
                        <div className="col p-0">
                            {this.state.loadSpinner ? <RaceSpinner /> : null}
                            <div className="d-flex justify-content-between mb-4">
                                <div className="">
                                    <p><span className='font-weight-bold'><span style={{textTransform: 'capitalize'}}>{this.state.datatype}</span> Title: </span>{this.state.result !== null ? this.state.result[`${this.state.datatype}_title`] : ''}</p>
                                    {this.state.result !== null ? <p>{this.state.result[`${this.state.datatype}_desc`]}</p> : null}
                                </div>
                                <div className="col action-learn-btns ">
                                    
                                    <span className='dummy-toalign-btn'></span>
                                    <button className="btn btn-sm rounded-0 d-block float-right"
                                        onClick={this.onClick.bind(this)} style={{ color: "#ffffff", minWidth: '120px' }}>Back To Result</button>
                                </div>
                            </div>
                            <div><h6 className="display-title" style={{ fontWeight: 'bolder' }}>{this.state.title === ASSOCIATED_QUALITY?"Associated Qualities":`${this.state.title}(s)`}:</h6></div>
                            <div className="action-learn-results">
                                {
                                    // this.state.title === "Associated MOC" ? this.mocItems() : this.associatedLearningItems()
                                    this.resultTemplate(this.state.title)
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

}
export default LearnAction;
