import React from 'react';
import GenerateReportPopup from './generate-report-popup';
function getSelectors(type) {
    const titles = [], ids = [];
    if (type === 'risk') {
        document.querySelectorAll(".search-item-report-controls .addreport:checked").forEach((element) => {
            const temp = {}
            temp["id"] = element.dataset.riskid;
            temp["title"] = element.dataset.risktitle;
            titles.push(temp);
            ids.push(element.dataset.riskid)
        });
    } else if (type === 'lesson') {
        document.querySelectorAll(".learn-item .learn-item-report-controls .addreport:checked").forEach((element) => {
            const temp = {}
            temp["id"] = element.dataset.lessonid;
            temp["title"] = element.dataset.lessontitle;
            titles.push(temp);
            ids.push(element.dataset.lessonid)
        });
    } else if (type === 'emoc'){
        document.querySelectorAll(".emoc-item .emoc-item-report-controls .addreport:checked").forEach((element) => {
            const temp = {}
            temp["id"] = element.dataset.emocid;
            temp["title"] = element.dataset.emoctitle;
            titles.push(temp);
            ids.push(element.dataset.emocid);
        });
    } else {
        document.querySelectorAll(".quality-item .report-controls .addreport:checked").forEach((element) => {
            const temp = {}
            temp["id"] = element.dataset.id;
            temp["title"] = element.dataset.title;
            titles.push(temp);
            ids.push(element.dataset.id);
        });
    }
    return [titles, ids];
}
class GenerateReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openReportDownload: false,
            dataTitles: [],
            dataIDs: []
        }
    }
    
    onClick(_e) {
        this.setState({ dataTitles: getSelectors(this.props.type)[0], dataIDs: getSelectors(this.props.type)[1],openReportDownload: true });
    }
    reportDownloadAfrerClose() {
        this.setState({ openReportDownload: false });
    }
    render() {
        const { generateBtnRef } = this.props;
        return (
            <>
                <button type='button' className='btn btn-sm btn-red rounded-0 generate-report-btn' onClick={this.onClick.bind(this)} ref={generateBtnRef}>Generate Report</button>
                <GenerateReportPopup show={this.state.openReportDownload} afterClose={this.reportDownloadAfrerClose.bind(this)}
                    ids={this.state.dataIDs} titles={this.state.dataTitles} type={this.props.type} />
            </>
        );
    }
}
export default GenerateReport;
