import React, { useState} from 'react';
export default function ApproveRejectButton(props) {
    const [approveinfo, setApproveinfo] = useState({ inProgress: false, isApproved: props.value });
    const [rejectinfo, setRejectinfo] = useState({ inProgress: false, isRejected: false });
    function onClick(e) {
        props.onApprove({ event: e, approveinfo: approveinfo, setApproveinfo: setApproveinfo });
    }
    function onRejectClick(e) {
        props.onReject({ event: e, rejectinfo: rejectinfo, setRejectinfo: setRejectinfo });
    }
    return (
        <div className='react-grid-action-btn'>
            {
                !approveinfo.isApproved ?
                    <button type='button' className='btn btn-sm btn-red mr-1' title={props.title || "Approve"} onClick={onClick}>
                        {
                            approveinfo.inProgress ?
                                <div className='Toastify__spinner position-relative' style={{ width: "10px", height: "10px" }}></div>
                                : (props.approveTextContent || "")
                        }
                    </button>
                    :
                    <div className='success-white-tickmark'>&#x2714;</div>
            }
            {
                !rejectinfo.isRejected &&  !approveinfo.isApproved ?
                <button type='button' className='btn btn-sm btn-red' title={props.title || "Reject"} onClick={onRejectClick}>
                    {
                        rejectinfo.inProgress ?
                            <div className='Toastify__spinner position-relative' style={{ width: "10px", height: "10px" }}></div>
                            : (props.rejectTextContent || "")
                    }
                </button> : null
            }
        </div>
    )
}
