import React from 'react'
import PiePlot from "./PiePlot";

export default function MRFPlot(props) {
    return (
        <div className=''>
            <PiePlot data={props.data} isDoughnut={true} name={"MRF"} filterOnCall={props.filterOnCall} dataType={"quality"}/>
        </div>
    );
}
