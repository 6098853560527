import utils from './app-util';
import { plotFilterOnDeselect } from './filter-utils';

function stackedBarChartFIlterCallbackOnCall(ids, _filterCountsAndStatus, setFilterCountsAndStatus, _OnHeatmapClick_ClearAll) {
    const allCards = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
    const risk_ids = ids.id.split(',');
    let count = 0;
    /**
     * if (filterCountsAndStatus.isAdvancedTECOPSearchOn) {
     *  OnHeatmapClick_ClearAll();//This method clears the TECOP filter, heatmapfilter, Advanced dropdown filter
     * }
     */

    if (ids.filter) {
        allCards.forEach(function (element) {
            if (risk_ids.includes(element.dataset.id)) {
                count += 1;
                element.classList.remove('d-none');
                element.classList.add('d-block');
            } else {
                element.classList.add('d-none');
                element.classList.remove('d-block');
            }
        });
    } else {
        count = plotFilterOnDeselect(allCards, 'risk');
    }
    setFilterCountsAndStatus(filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count }));
}

function stackedBarChartClearBtnOnClick(setFilterCountsAndStatus) {
    let count = 0;
    
    count = plotFilterOnDeselect(document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR), 'risk');
    setFilterCountsAndStatus(filterCountsAndStatus => ({ ...filterCountsAndStatus, searchresultcount: count }));
    utils.clearBarChartHighlight();
}

export { stackedBarChartFIlterCallbackOnCall, stackedBarChartClearBtnOnClick };
