import React, { useEffect } from 'react';
import {Autocomplete, FormControlLabel, Divider, Checkbox, Paper, Box, TextField} from '@mui/material';

const CustomPaperSelectAllComponent = (paperProps, handleToggleSelectAll, selectAll) => {
    const { children, ...restPaperProps } = paperProps;
    return (
        <Paper {...restPaperProps}>
            <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur
                pl={1.5}
                py={0.5}
            >
                <FormControlLabel
                    style={{ fontFamily: "Futura" }}
                    onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll();
                    }}
                    label="Select all"
                    control={
                        <Checkbox id="select-all-checkbox" size='small' checked={selectAll} />
                    }
                />
            </Box>
            <Divider />
            {children}
        </Paper>
    );
}

export default function QSelect(props) {
    const labels = { "characteristics": "Characteristics", "disciplinefunction": "Discipline Function", "equipmenttype": "Equipment Type" }

    const [selected, setSelected] = React.useState([]);
    

    const [selectAll, setSelectAll] = React.useState(false);
    const allOptions = props.data;
    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
            if (!prev) {
                setSelected(allOptions)
            }
            else {
                setSelected([]);
            }
            return !prev;
        });
        handleChange(null, allOptions, 'selectOption');
    };

    useEffect(() => {
        if (props.isClear) {
            setSelected([]);
        }
    }, [props]);

    const handleChange = (_event, newValue, reason) => {
        if (reason === "selectOption") {
            setSelected(newValue);
            setSelectAll(false);
        }
        if (reason === "clear" || reason === "removeOption") {
            setSelectAll(false);
            setSelected(newValue);
        }
        if (reason === "selectOption" && newValue.length === allOptions.length) {
            setSelectAll(true);
            setSelected(newValue);
        }
        props.onChange(newValue, props.filterType);
    }

    return (
        <div className={`select-container my-2`}>
            <Autocomplete
                data-selectedvalue = {JSON.stringify(selected)}
                className={`quality-custom-autocomplete ${props.filterType}`}
                multiple
                limitTags={2}
                size="small"
                id="checkboxes-tags-demo"
                options={props.data}
                disableCloseOnSelect
                getOptionLabel={(option) => {
                    return option === "" ? "Legacy data(Not Mandatory)" : option;
                }}
                value={selected}
                onChange={handleChange}
                renderOption={(autoProps, option, { selected }) => (
                    <li {...autoProps}>
                        <Checkbox
                            
                            style={{ padding: '2px' }}
                            size="small"
                            checked={selected}
                        />
                        <div className='d-flex justify-content-between' style={{ fontSize: '10px' }}><div className='col-10'>{option === "" ? "Legacy data(Not Mandatory)" : option}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><div className='col-2'>({props.count[option]})</div></div>
                    </li>
                )}
                style={{ width: 205, marginLeft: '2px' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={labels[props.filterType]} placeholder="Please Select" />
                )}
                PaperComponent={(paperProps)=>{
                    return CustomPaperSelectAllComponent(paperProps, handleToggleSelectAll, selectAll)
                }}
                openOnFocus={true}
                disablePortal={true}
            />
        </div>
    );
}
