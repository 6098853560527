import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import utils from '../app-util';
function template(data, index) {
    if (data.labelName === 'Location') {
        return (<div key={`tag${index}`} className="d-inline-block">{(data.value !== "" && data.value !== "-" ? <ReactCountryFlag countryCode={data.flag} svg /> : <img alt="" src={utils.logo}
                                    style={{ backgroundColor: "transparent", width: "18px" }} />)}<span>&nbsp;{data.value}</span><span className="verticalbar mx-2"></span></div>);
    } else {
        return <div key={`tag${index}`} className="d-inline-block"><span className='project-tag'>{data.labelName}: {data.value !== "" ? data.value: "--"}</span><span className="verticalbar mx-2"></span></div>;
    }
}
export default function Tagging({tagList=[]}) {
    return (
        <div>
            {
                tagList.map((data, index) => {
                    return template(data, index);
                })
            }
        </div>
    );
}
