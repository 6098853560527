import React from 'react';
import utils from './app-util';
import { toast } from 'react-toastify';
class PrivacyTAndC extends React.Component {
    state = {
        showSubmitBtn: false,
        accept: false
    }
    componentDidMount() {
        const observeElement = document.querySelector(".tc-checkbox");
        const observer = new IntersectionObserver(function (entries, _observer) {
            entries.forEach((entry) => {
                if (entry.intersectionRatio === 1) {
                    setTimeout(() => {
                        observeElement.disabled = false;
                    }, 1000);
                }
            });
        }, {
            root: document.getElementById("tc-scroll-body"),
            rootMargin: '0px',
            threshold: 1.0
        });
        observer.observe(observeElement);
    }
    onCheck(e) {
        if (e.target.checked) {
            this.setState({ showSubmitBtn: true })
        } else {
            this.setState({ showSubmitBtn: false })
        }
    }

    requestToAccept(url, payload) {
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json", "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
            body: JSON.stringify(payload)
        })
            .then(res => {
                return utils.checkSessionStatus(res);
            })
            .then(
                (results) => {
                    if (results.success) {
                        toast.success(`Thank You for accepting the "Access Request & Data Confidentiality Acceptance Form". 
                    The "PTIN-Global-Risk-Support" team will provide you the access shortly.`, { autoClose: false })
                        this.props.history.push({ pathname: "/search" })
                    }
                },
                (_err) => {
                    toast.error("Error occured on fetching the query");
                    this.setState({ loadSpinner: false });
                }
            ).catch(function (error) {
                console.error(error);
            })
    }
    isEmpty(payloadData) {
        let isempty = false;
        for (const key in payloadData) {
            if ((Object.hasOwnProperty.call(payloadData, key)) && (payloadData[key] === "")) {
                isempty = true;
                break;
            }
            
        }

        return isempty;
    }
    isValid(payloadData) {
        let isValid = true;
        if (!this.isEmpty(payloadData)) {
            if (!payloadData["lmemail"].toLowerCase().includes("@shell.com")) {
                isValid = false;
                toast.error("Please use Shell official email ID of your LM");
            }
        } else {
            toast.error("Please select/enter the required field.");
            isValid = false;
        }
        return isValid;
    }
    getDataAccessSelection(elements) {
        const selectedData = [];
        elements.forEach(function (cbox) {
            if (cbox.checked) {
                selectedData.push(cbox.value)
            }
        });
        return selectedData;
    }
    acceptOnSubmit(e) {
        e.preventDefault();
        this.setState({ accept: true });
        const payload = {
            isaccepted: e.target.elements.isaccepted.checked,
            lmemail: e.target.elements.lmemail.value.toLowerCase(),
            staffoption: e.target.elements.staffoption.value,
            belongto: e.target.elements.belongto.value,
            dataaccess: this.getDataAccessSelection(e.target.elements['dataaccess']).join(),
            projectsupport: this.getDataAccessSelection(e.target.elements['projectsupport']).join(),
            bizjustification: e.target.elements.bizjustification.value
        }

        if (this.isValid(payload)) {
            this.requestToAccept(`/request-access`, payload)
        }
    }

    render() {
        return (
            <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
                <div className="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content" tabIndex="-1">
                        <div className="modal-header justify-content-start">
                            <img className='mr-2' width={30} src={utils.logo} alt="Shell Pecten" />
                            <h5 className="modal-title">Qurie Access Request &#38; Data Confidentiality Acceptance Form</h5>
                        </div>
                        <div className="modal-body" id='tc-scroll-body'>
                            <div id='tc-content-scroll-body'>
                                <section>
                                    <h5 className='text-danger mb-4'>Welcome to Qurie</h5>
                                    <p className='font-weight-bold'>Objective:</p>
                                    <p>
                                        Qurie is an Artificail Intelligence(AI) tool in project services, an intent based textual search engine that is programmed to search
                                        across the EasyRisk &#38; LFE data (expandable in future) using keywords
                                        to generate relevant insights from past and present risk information and associated learnings, helping the projects towards the data
                                        driven high-quality Risk Registers and responses across portfolios etc.
                                        This helps in risk workshop for easy identification of risk, required actions, reducing biases and assurance activities with leveraged
                                        learnings.
                                    </p>
                                    <p className='font-weight-bold'>Users:</p>
                                    <p>
                                        Qurie access is controlled with primary intended users will be Risk Coordinators/Managers, CSRA Analysts, Functional
                                        Experts and Project Leaderships(PM/FEDM, PSMs).
                                    </p>
                                    <p className='font-weight-bold'>Information Sharing:</p>
                                    <p>
                                        Qurie primary data source is EasyRisk &#38; LFE from projects. Consult the LFE site or Knowledge Management Services,
                                        prior to sharing LFE data to review restrictions of data sharing.
                                        EasyRisk data is classified as CONFIDENTIAL, information and detailed data cannot be shared outside of Shell. If
                                        you are in doubt regarding what EasyRisk information can or cannot be shared internally, and under which conditions,
                                        contact the Project Service leadership for the project or country of interest. As a Shell employee or contractor, you
                                        agree to comply with all applicable standards and data confidentiality.
                                    </p>
                                    <p className='font-weight-bold'>Trade Controls/Antitrust/Disclaimer:</p>
                                    <p>
                                        Exchanges of Project Risk data may constitute a violation of antitrust law unless carried out correctly.
                                        Such an exchange must be within the framework of legal &#38; anti-trust compliance and considerable care
                                        is needed while sharing of information/data.
                                        While the endeavor is to make data accessible, it is important that the User is aware of and comply with
                                        Shell's "anti-trust compliance rules" while making use of the data/this tool.
                                    </p>
                                    <p>
                                        As with any other electronic communication, disclosure of technology and technical information, users
                                        must be in compliance with the Shell Trade Controls and Export Adminstration Regultions(EAR).
                                        Shell employees must not use Qurie to exchange CSI(Competitive Sensitive Information) with competitors
                                        without prior approval from Shell Legal.
                                        If a Shell user is uncertain whether information is competitively sensitive or whether a counterpart can
                                        be considered a competitor they should contact Shell Legal before sharing information.
                                    </p>
                                </section>
                                <form id="acceptanceForm" onSubmit={this.acceptOnSubmit.bind(this)}>
                                    <p>Kindly acknowledge by accepting the above statements.</p>
                                    <div className='form-group'>
                                        <div className="form-check">
                                            <input className='tc-checkbox form-check-input' name="isaccepted" id="iAccept" type='checkbox'
                                                onChange={this.onCheck.bind(this)} disabled />
                                            <label className="form-check-label" htmlFor='iAccept'>I Accept*</label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        Are you*
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="staffoption" value={"Shell Staff"} id="staffoption1" />
                                            <label className="form-check-label" htmlFor="staffoption1">
                                                Shell Staff
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="staffoption" value={"Shell Secondee"} id="staffoption2" />
                                            <label className="form-check-label" htmlFor="staffoption2">
                                                Shell Secondee
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="staffoption" value={"Contractor"} id="staffoption3" />
                                            <label className="form-check-label" htmlFor="staffoption3">
                                                Contractor
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lmEmail">Line Manager Email ID*</label>
                                        <input type="email" className="form-control" name="lmemail" id="lmEmail" placeholder="Enter your line manager email" />
                                    </div>
                                    <div className='form-group'>
                                        Organization/Function/Business you belong to*
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="belongto" value={"Upstream"} id="belongto1" />
                                            <label className="form-check-label" htmlFor="belongto1">
                                                Upstream
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="belongto" value={"Downstream"} id="belongto2" />
                                            <label className="form-check-label" htmlFor="belongto2">
                                                Downstream
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="belongto" value={"Integrated Gas & New Energies"} id="belongto3" />
                                            <label className="form-check-label" htmlFor="belongto3">
                                                Integrated Gas &#38; New Energies
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="belongto" value={"Projects & Technology"} id="belongto4" />
                                            <label className="form-check-label" htmlFor="belongto4">
                                                Projects &#38; Technology
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="belongto" value={"Other"} id="belongto5" />
                                            <label className="form-check-label" htmlFor="belongto5">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        Which Line of Business data access you need?*
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="dataaccess" value={"Upstream"} id="dataaccess1" />
                                            <label className="form-check-label" htmlFor="dataaccess1">
                                                Upstream
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="dataaccess" value={"Downstream"} id="dataaccess2" />
                                            <label className="form-check-label" htmlFor="dataaccess2">
                                                Downstream
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="dataaccess" value={"Integrated Gas"} id="dataaccess3" />
                                            <label className="form-check-label" htmlFor="dataaccess3">
                                                Integrated Gas
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="dataaccess" value={"New Energies"} id="dataaccess4" />
                                            <label className="form-check-label" htmlFor="dataaccess4">
                                                New Energies
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        Types of project(s) you are supporting?*
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="projectsupport" value={"SOV"} id="projectsupport1" />
                                            <label className="form-check-label" htmlFor="projectsupport1">
                                                SOV
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="projectsupport" value={"NOV"} id="projectsupport2" />
                                            <label className="form-check-label" htmlFor="projectsupport2">
                                                NOV
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="projectsupport" value={"JV"} id="projectsupport3" />
                                            <label className="form-check-label" htmlFor="projectsupport3">
                                                JV
                                            </label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="bizjustification">Business Justification*</label>
                                        <textarea className='form-control' name='bizjustification'
                                            id="bizjustification" placeholder='Provide the business justification here' maxLength="600"></textarea>
                                    </div>
                                    {
                                        this.state.showSubmitBtn ?
                                            <div className="border-top d-flex justify-content-between align-items-center">
                                                <div></div>
                                                <div><button type="submit" className="btn accept-btn btn-red rounded-0 my-2">Submit</button></div>
                                            </div>
                                            :
                                            null
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PrivacyTAndC;
