import { toast } from 'react-toastify';
import utils from './app-util';

const CONTENT_TYPE = 'application/json';

export default function mostRelevantNotRelevantOperation(e, dataType, addUrl, deleteUrl, datasetName, relevantType) {
    const currentElement = e.currentTarget;
    const riskOrLessonID = e.target.dataset[datasetName];
    const query = sessionStorage.getItem('query');
    const requestOption = {
        method: "POST",
        headers: { 'Content-Type': CONTENT_TYPE, "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify({ query: query, data_type: dataType, data_id: riskOrLessonID })
    };
    utils.showCBoxLoader(currentElement);
    if (currentElement.checked) {
        fetch(addUrl, requestOption)
            .then(res => {
                return utils.checkSessionStatus(res);
            }).then((results) => {
                utils.hideCBoxLoader(currentElement);
                if (!results.status) {
                    currentElement.checked = false;
                    toast.warn(`Failed to add in ${relevantType}`);
                } else {
                    currentElement.checked = true;
                    toast.success(`Successfully added to ${relevantType}`);
                }
            }, (_err) => {
                utils.hideCBoxLoader(currentElement);
                currentElement.checked = false;
                toast.error(`Error occured when added to ${relevantType}`);
            });
    } else {
        fetch(deleteUrl, requestOption)
            .then(res => {
                return utils.checkSessionStatus(res);
            }).then((results) => {
                utils.hideCBoxLoader(currentElement);
                if (!results.status) {
                    currentElement.checked = true;
                    toast.warn(`Failed to delete from ${relevantType}`);
                } else {
                    currentElement.checked = false;
                    toast.success(`Successfully deleted from ${relevantType}`);
                }
            }, (_err) => {
                utils.hideCBoxLoader(currentElement);
                currentElement.checked = true;
                toast.error(`Error occured on delete from ${relevantType}`);
            });
    }
}
