import React from 'react';
import chevronDown from '../assets/chevron_down.svg';
import filter from '../assets/filter.svg';
import utils from './app-util';
import DateFilter from './date-filter';
import QulityFilters from './qualityComponent/QualityFilters';

function checkAllSiblingsIfNotChecked(element) {
    const list = element.children;
    let allChecked = true;
    for (let i = 0; i < list.length; ++i) {
        if (list[i].children[0].children[0].children[0].checked) {
            allChecked = false;
            break;
        }
    }
    return allChecked;
}
function checkAllSiblingsIfChecked(element) {
    const list = element.children;
    let allChecked = true;
    for (let i = 0; i < list.length; ++i) {
        if (!list[i].children[0].children[0].children[0].checked) {
            allChecked = false;
            break;
        }
    }
    return allChecked;
}
function decideCheckedOrIndeterminateMenuItems(cbElem, checked, isLevelZero = false) {
    if (cbElem.parentNode.parentNode.parentNode.parentNode.tagName === 'UL') {
        const liAsParent = cbElem.parentNode.parentNode.parentNode.parentNode.previousSibling;
        const liParentInputCb = isLevelZero ? liAsParent.children[0].children[0] : liAsParent.children[0].children[0].children[0];
        if (checked) {
            if (checkAllSiblingsIfChecked(cbElem.parentNode.parentNode.parentNode.parentNode)) {
                liParentInputCb.indeterminate = false;
                liParentInputCb.checked = true;
            } else {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = true;
            }
        } else {
            if (checkAllSiblingsIfNotChecked(cbElem.parentNode.parentNode.parentNode.parentNode)) {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = false;
            } else {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = true;
            }
        }
    }
}
function checkIfMenuItemInL0(cbElem) {
    if (cbElem.parentNode.parentNode.parentNode.classList.contains('L0')) {
        return true;
    } else if (cbElem.parentNode.parentNode.parentNode.classList.contains('L2')) {
        return false;
    } else {
        return false;
    }
}

function childrenCounthNotZero(cbElem, checked) {
    const ul = cbElem.parentNode.parentNode.parentNode.nextSibling;
    if (ul.childElementCount > 0) {
        for (let i = 0; i < ul.children.length; ++i) {
            if (!ul.children[i].classList.contains('disabled')) {
                ul.children[i].children[0].children[0].children[0].indeterminate = false;
                ul.children[i].children[0].children[0].children[0].checked = checked;
            }
        }
    }
}

function checkChildrenCheckBoxesMenuItems(cbElem, checked) {
    if (cbElem.parentNode.parentNode.parentNode.nextSibling !== null) {
        if (cbElem.parentNode.parentNode.parentNode.nextSibling.tagName === 'UL') {
            childrenCounthNotZero(cbElem, checked);
            decideCheckedOrIndeterminateMenuItems(cbElem, checked, true);
        } else {
            if (cbElem.parentNode.parentNode.parentNode.nextSibling.tagName === 'LI') {
                decideCheckedOrIndeterminateMenuItems(
                    cbElem, checked,
                    true
                );
            } else {
                decideCheckedOrIndeterminateMenuItems(
                    cbElem, checked,
                    checkIfMenuItemInL0(cbElem)
                );
            }
        }
    } else {
        decideCheckedOrIndeterminateMenuItems(cbElem, checked);
    }
}


function SidebarMenuItem(props) {
    function onClick(e) {
        utils.clearBarChartHighlight();
        if (e.target.checked) {
            checkChildrenCheckBoxesMenuItems(e.target, true);
            props.setFilterValue({ data: e.target.value, checked: true });
        } else {
            checkChildrenCheckBoxesMenuItems(e.target, false);
            props.setFilterValue({ data: e.target.value, checked: false });
        }
    }

    return (
        <>
            <li className={`list-group-item px-2 
            ${props.count[props.label] !== undefined && props.count[props.label].toString() === "0" ? "disabled" : ""} ${Array.isArray(props.items) ? "L1" : "L2"} `}>
                <div className="d-flex align-items-center">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="mr-2 custom-control-input" id={props.label} value={props.label} onClick={onClick}
                            data-number={props.count[props.label]} />
                        <label className="custom-control-label" htmlFor={props.label}></label>
                    </div>
                    <div className="flex-fill" data-target={`#${props.label.replace(/\s|,|\//g, '_')}`} data-toggle="collapse" aria-expanded="false">
                        <div className="d-flex justify-content-between">
                            <div className="m-0 flex-fill label-wrap" title={props.label}><span className="label">{props.label}</span>
                                <span className="ml-2">({props.count[props.label]})</span></div>
                            {
                                (Array.isArray(props.items) ? <div><img className="filter-chevron" alt="" src={chevronDown} /></div> : null)
                            }
                        </div>
                    </div>
                </div>
            </li>
            {Array.isArray(props.items) ? (
                <ul className="list-group list-group-flush collapse" id={props.label.replace(/\s|,|\//g, '_')}>
                    {/* <ul className="list-group list-group-flush"> */}
                    {props.items.map((subItem) => {
                        return (
                            <SidebarMenuItem key={subItem.name} items={subItem} label={props.label}
                                number={props.count[props.label]} count={props.count} step={props.step + 1} {...subItem} setFilterValue={props.setFilterValue} />
                        )
                    })}
                </ul>
            ) : null}
        </>
    );
}

function inlineFunction(cbElem, checked) {
    let ul = null;
    if (cbElem.tagName === 'UL') {
        ul = cbElem;
    } else {
        ul = cbElem.parentNode.parentNode.parentNode.children[1];
    }
    if (ul.childElementCount > 0) {
        for (let i = 0; i < ul.children.length; ++i) {
            if ((ul.children[i].tagName === 'LI') && !ul.children[i].classList.contains('disabled')) {
                ul.children[i].children[0].children[0].children[0].indeterminate = false;
                ul.children[i].children[0].children[0].children[0].checked = checked;
            }

            if (ul.children[i].tagName === 'UL') {
                inlineFunction(ul.children[i], checked);
            }
        }
    }
}

function decideCheckedOrIndeterminate(cbElem, checked) {
    if (cbElem.parentNode.parentNode.parentNode.parentNode.tagName === 'UL') {
        const liAsParent = cbElem.parentNode.parentNode.parentNode;
        const liParentInputCb = liAsParent.children[0].children[0].children[0]
        if (checked) {
            if (checkAllSiblingsIfChecked(cbElem.parentNode.parentNode.parentNode.parentNode)) {
                liParentInputCb.indeterminate = false;
                liParentInputCb.checked = true;
            } else {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = true;
            }
        } else {
            if (checkAllSiblingsIfNotChecked(cbElem.parentNode.parentNode.parentNode.parentNode)) {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = false;
            } else {
                liParentInputCb.checked = false;
                liParentInputCb.indeterminate = true;
            }
        }
    }
}
function SidebarMenu(props) {
    const items = props.items;
    const count = props.count;
    function clearAllOnClick() {
        if (props.dataType === 'risk') {
            utils.clearBarChartHighlight();
            props.functionalTagClearFilter();
        } else if(props.dataType === 'quality'){
            props.qualitySidebarFilterOnClear();
        }else {
            props.emocFilter(null);
        }
    }

    function onClick(e) {
        utils.clearBarChartHighlight();
        if (e.target.checked) {
            if (e.target.dataset.type === 'risk') {
                checkChildrenCheckBoxes(e.target, true);
                props.setFilterValue({ data: e.target.value, checked: true });
            } else {
                props.emocFilter({ data: e.target.value, checked: true })
            }

        } else {
            if (e.target.dataset.type === 'risk') {
                checkChildrenCheckBoxes(e.target, false);
                props.setFilterValue({ data: e.target.value, checked: false });
            } else {
                props.emocFilter({ data: e.target.value, checked: false })
            }
        }
    }
    function checkChildrenCheckBoxes(cbElem, checked) {
        if (cbElem.parentNode.parentNode.parentNode.children[0].tagName === 'DIV') {
            inlineFunction(cbElem, checked);
        } else {
            decideCheckedOrIndeterminate(cbElem, checked);
        }
    }
    function checkIfEmocDataEmpty(dataType, modifiedList) {
        let empty = false;
        if ((dataType === 'emoc') && modifiedList.length === 0) {
            empty = true;
        }
        return empty;
    }
    function countLable(counts, labels) {
        if (counts[labels] !== undefined) {
            return counts[labels];
        }
        else {
            return "";
        }
    }
    return (
        <div className={`app-bg-color sidebar ${props.dataType}-sidebar`}>
            {/* <header>
                <div className="d-flex justify-content-start align-items-center p-3">
                    <div className="d-inline-block">
                        <img className="logo" alt="" src={utils.logo}/>
                    </div>
                    <div className="d-inline-block">
                        <h3 className="brand-name pt-3 ml-2">Qurie</h3>
                    </div>
                </div>
            </header> */}
            <div>
                <div className="d-flex justify-content-between p-1">
                    <span className="filter"><img alt="" src={filter} />&nbsp;Filter By</span>
                    <span className={`clearall tour-clearall clearall-${props.dataType}`} onClick={clearAllOnClick}
                        style={{ cursor: "pointer" }} title="Clear Filters">Clear All</span>
                </div>
                <div className='datefilter-container'>
                    {
                        props.dataType === 'risk' ? <DateFilter type={"risk"} modifiedList={props.modifiedList} dateFilterOnCall={props.dateFilterOnCall} /> : null
                    }
                    {
                        props.dataType === 'emoc' ? <DateFilter type={"emoc"} modifiedList={props.modifiedList} dateFilterOnCall={props.dateFilterOnCall} /> : null
                    }
                    {
                        props.dataType === 'quality' ? <DateFilter type={"quality"} modifiedList={props.modifiedList} dateFilterOnCall={props.dateFilterOnCall} /> : null
                    }
                </div>
                {
                    props.dataType === 'risk' || props.dataType === 'emoc' ?
                <div className='px-2 pt-2'>Functional Category</div> : null
                }
                <ul className={`list-group list-group-flush filter-list ${checkIfEmocDataEmpty(props.dataType, props.modifiedList) ? "disabled" : ""}`}
                    style={{ maxHeight: window.innerHeight - 122, overflowY: "auto" }}>
                    {items.map(({ label, name, items: subItems }) => (
                        <React.Fragment key={name}>

                            <li className={`list-group-item p-0 L0 ${count[label] !== undefined && count[label].toString() === "0" ? "disabled" : ""}`}>


                                <div className="d-flex align-items-center" style={{ padding: ".75rem .5rem" }}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="mr-2 custom-control-input" value={label} onClick={onClick}
                                            id={`${props.dataType}${label}`} data-type={props.dataType} />
                                        <label className="custom-control-label" htmlFor={`${props.dataType}${label}`}></label>
                                    </div>
                                    <div className="flex-fill" data-target={`#${label}`} data-toggle="collapse" aria-expanded="false">
                                        <div className="d-flex justify-content-between">
                                            <div className="m-0 flex-fill label-wrap" title={label}>
                                                <span className="label">{label}</span>
                                                <span className="ml-2">{props.dataType === 'risk' ? `(${countLable(count, label)})` : ""}</span></div>
                                            <div>{count[label] > 0 ? <img className="filter-chevron" alt="" src={chevronDown} /> : null}</div>
                                        </div>
                                    </div>
                                </div>
                                {Array.isArray(subItems) ? (
                                    <ul className="list-group list-group-flush collapse" id={label}>
                                        {subItems.map(({ label, name, items: subsubItems }) => {
                                            return (
                                                <SidebarMenuItem key={name} items={subsubItems} label={label} count={count} setFilterValue={props.setFilterValue} />
                                            )
                                        })}
                                    </ul>
                                ) : null}
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
                {
                    props.dataType === 'quality' ? 
                    <QulityFilters data={props.dataFilterValues}
                    characteristicsOnChange={props.characteristicsOnChange}
                    disciplineFunctionOnChange={props.disciplineFunctionOnChange}
                    equipmentTypeOnChange={props.equipmentTypeOnChange}
                    />
                    :
                    null
                }
            </div>
        </div>
    );
}

export default SidebarMenu
