import React from 'react';

class Dropdown extends React.Component {
    CHIP_SELECTOR = '.advanced-search-wrapper .dropdown-filtering.active .chiplist .chip';
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            chips: [],
        };
        this.selectedDropdown = "";
    }

    componentDidMount() {
        this.chips = [];
    }

    chipRemoveOnClick(e) {
        e.target.parentNode.remove();
        const filterItems = { projects: [], countries: [], lobs: [] };
        const lists = document.querySelectorAll(this.CHIP_SELECTOR);
        lists.forEach(element => {
            filterItems[element.dataset.type].push(element.dataset.item);
        });
        filterItems["selectedDropdown"] = e.target.parentNode.dataset.type;
        this.props.parentCallback(filterItems);
    }
    onKeyPress(e) {
        const data = this.props.data;
        const value = e.target.value;

        const keyBackspaceDeleteValue = e.key !== "Backspace" && e.key !== 'delete' && value !== '';
        if (keyBackspaceDeleteValue && data.includes(value) && !this.getChipList().includes(value)) {
            this.chips.push(value)
            this.setState({ chips: this.chips });
            this.selectedDropdown = e.target.dataset.type;
            e.target.value = "";

            const filterItems = { projects: [], countries: [], lobs: [], selectedDropdown: "" };
            const lists = document.querySelectorAll(this.CHIP_SELECTOR);
            lists.forEach(element => {
                filterItems[element.dataset.type].push(element.dataset.item);
            });
            filterItems["selectedDropdown"] = this.selectedDropdown;
            filterItems[this.selectedDropdown].push(value)
            this.props.parentCallback(filterItems);

        }
    }
    getChipList() {
        const cList = [];
        const lists = document.querySelectorAll(this.CHIP_SELECTOR);
        lists.forEach(element => {
            cList.push(element.dataset.item);
        });
        return cList;
    }
    render() {
        return (
            <div>
                <div className="dropdown-wrapper">
                    <input className="form-datalist" list={this.props.id} placeholder={this.props.name} onKeyUp={this.onKeyPress.bind(this)} data-type={this.props.id} title={this.props.name} autoComplete='off'/>
                    <datalist id={this.props.id}>
                        {
                            this.props.data.sort().map((item, _index) => {
                                return (<option key={item} value={item} />);
                            })
                        }
                    </datalist>
                    <div className="chiplist" style={{ maxHeight: "150px", overflowY: "auto" }}>
                        {
                            this.state.chips.map((item, _index) => {
                                return (<div key={item} className="chip small d-flex justify-content-between px-2 py-1 my-1" data-type={this.props.id} data-item={item}>
                                    <span className="label" title={item}>{item}</span>
                                    <span className="chip-colse" onClick={this.chipRemoveOnClick.bind(this)}>&times;</span></div>);
                            })
                        }
                    </div>
                </div>

            </div>
        );
    }

}
export default Dropdown;
