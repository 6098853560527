import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DataGrid from 'react-data-grid';
import { EmptyRowsRenderer, FilterContext } from '../grid/user-data-grid';
import ApproveRejectButton from '../grid/approve-reject';
import utils from '../app-util';
import summaryDescription from '../summaryDescription';
import Toolbar from '../grid/data-grid-toolbar';
import { textfieldRenderer, textfieldSelectRenderer, togglefilterClass,handleSortingOnHeaderCellClick } from '../grid/grid-utils';
import { toast } from 'react-toastify';

const HEADER_CLASSNAME = "filter-cell";
function TitleFormatter(props) {
    function onClick(e) {
        props.onClick({ event: e });
    }
    return (
        <div style={{ cursor: 'pointer', overflow: 'clip', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={onClick}>{props.textContent}</div>
    );
}
const mailFormatter = ({ row }) => {
    return (
        <>{row.userid}</>
    );
}
const datatTypeFormatter = ({ row }) => {
    return (
        <span style={{textTransform: 'capitalize'}}>{row.datatype}</span>
    );
}
function relevantColumnFormat(rows, setRows, popup, setPopup, setFilters) {
    return [
        { key: 'slno', name: 'Sl. No.', width: 60, headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'slno') },
        { key: 'userid', name: 'Email', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'userid'), formatter: mailFormatter },
        { key: 'searchquery', name: 'Search Query', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'searchquery') },
        { key: 'id', name: 'Global ID', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'id') },
        {
            key: 'title', name: 'Title', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldRenderer(setFilters, 'title'),
            formatter(objProp) {
                return <TitleFormatter textContent={objProp.row.title}
                    onClick={(_arg) => {
                        setPopup({...popup, open: true, data: objProp.row});
                    }}
                />
            }
        },
        { key: 'datatype', name: 'Data Type', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldSelectRenderer(setFilters, 'datatype', ['risk', 'lesson', 'emoc']), formatter: datatTypeFormatter },
        { key: 'activity', name: 'Activity', headerCellClass: HEADER_CLASSNAME, editable: false, headerRenderer: textfieldSelectRenderer(setFilters, 'activity', ['Most Relevant', 'Not Relevant']) },
        {
            key: 'action', name: 'Actions', width: 150, sortable: false,
            formatter(objProp) {
                return (
                    <ApproveRejectButton approveTextContent="Approve" rejectTextContent="Reject" value={objProp.row.isapproved} row={objProp}
                        onApprove={(args) => {
                            approve(rows, setRows, objProp, args);
                        }}
                        onReject={(args) => {
                            reject(rows, setRows, objProp, args);
                        }}
                    />
                );
            }
        }
    ];
}

function approve(rows, setRows, objProp, argsObj) {
    const selectedIndexForApprove = rows.findIndex(function (item) {
        return item.slno === objProp.row.slno;
    });
    const dataRow = {
        dataid: rows[selectedIndexForApprove].dataid,
        userid: rows[selectedIndexForApprove].userid,
        search_query: rows[selectedIndexForApprove].searchquery,
        datatype: rows[selectedIndexForApprove].datatype,
        activity: rows[selectedIndexForApprove].activity
    }
    argsObj.setApproveinfo({ ...argsObj.approveinfo, inProgress: true });
    fetch('/approve-most-not-relevant', {
        method: "POST",
        headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(dataRow)
    })
        .then(function (response) {
            if (response.status !== 200) {
                argsObj.setApproveinfo({ isApproved: false, inProgress: false });
                utils.checkSessionStatus(response);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    argsObj.setApproveinfo({ isApproved: true, inProgress: false });
                    rows[selectedIndexForApprove].isapproved = true;
                    setRows(rows);
                } else {
                    argsObj.setApproveinfo({ isApproved: false, inProgress: false });
                    toast.warn(data.message);
                }
            });
        }).catch(function (err) {
            argsObj.setApproveinfo({ isApproved: false, inProgress: false });
            console.log('Fetch Error :', err);
        });
}
function reject(rows, setRows, objProp, argObjReject) {
    const selectedIndex = rows.findIndex(function (item) {
        return item.slno === objProp.row.slno;
    });

    const selectedData = {
        dataid: rows[selectedIndex].dataid,
        userid: rows[selectedIndex].userid,
        search_query: rows[selectedIndex].searchquery,
        datatype: rows[selectedIndex].datatype,
        activity: rows[selectedIndex].activity
    }
    argObjReject.setRejectinfo({ ...argObjReject.rejectinfo, inProgress: true });
    fetch('/reject-most-not-relevant', {
        method: "DELETE",
        headers: { "X-CSRFToken": utils.csrf(), 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: JSON.stringify(selectedData)
    })
        .then(function (response) {
            if (response.status !== 200) {
                argObjReject.setRejectinfo({ isRejected: false, inProgress: false });
                utils.checkSessionStatus(response);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    argObjReject.setRejectinfo({ isRejected: true, inProgress: false });
                    setRows([...rows.slice(0, selectedIndex), ...rows.slice(selectedIndex + 1)]);
                } else {
                    argObjReject.setRejectinfo({ isRejected: false, inProgress: false });
                    toast.warn(data.message);
                }
            });
        }).catch(function (err) {
            argObjReject.setRejectinfo({ isRejected: false, inProgress: false });
            console.log('Fetch Error :', err);
        });
}
function projectRowsFilter(rows, filters) {
    return rows.filter((r) => {
        return (
            (filters.slno ? r.slno.toString() === filters.slno : true) &&
            (filters.userid ? r.userid.toLowerCase().includes(filters.userid.toLowerCase()) : true) &&
            (filters.id ? r.id.toLowerCase().includes(filters.id.toLowerCase()) : true) &&
            (filters.searchquery ? r.searchquery.toLowerCase().includes(filters.searchquery.toLowerCase()) : true) &&
            (filters.title ? r.title.toLowerCase().includes(filters.title.toLowerCase()) : true) &&
            (filters.datatype !== 'All' ? r.datatype.toString() === filters.datatype : true) &&
            (filters.activity !== 'All' ? r.activity.toString() === filters.activity : true)
        );
    });
}
function onDownloadClick(e){
    utils.makeDownloadRequest(`/mostnotrelevant-download`, `Most_Not_relevant`, e.currentTarget, "Export to Excel" );
}
function onQualityDownloadClick(e) {
    utils.makeDownloadRequest(`/mostnotrelevant-quality-download`, `Most_Not_relevant`, e.currentTarget, "Download Quality Most/Not relevant Data" );
}
function Mostnotrelevantgrid(props) {
    const [rows, setRows] = useState([]);
    const filterColumnObj = { userid: "", searchquery: "", title:"", datatype: "All", activity: "All", enabled: false };
    const [filters, setFilters] = useState(filterColumnObj);
    const [sortcolumn, setsortColumn] = useState([]);
    const [popup, setPopup] = useState({ open: false, data: {} });
    useEffect(() => {
        setRows(props.data);
        setTimeout(() => {
            document.querySelectorAll('.rdg-cell[role="columnheader"]').forEach((e) => {
                e.addEventListener('click', headerOnClick);
            })
        }, 2000);
    }, [props]);
    function headerOnClick(e) {
        handleSortingOnHeaderCellClick(e, setsortColumn);
    }
    const gridColumnsMostNotRelevant = useMemo(() => {
        return relevantColumnFormat(rows, setRows, popup, setPopup, setFilters);
    }, [rows, popup]);
    function popupOnClose() {
        setPopup({ ...popup, open: false });
    }
    const onSortColumnsChange = useCallback((sortcolumns) => {
        setsortColumn(sortcolumns.slice(-1));
    }, []);
    const filteredRows = useMemo(() => {
        return projectRowsFilter(rows, filters);
    }, [rows, filters]);
    const gdSortedRows = useMemo(() => {
        if (sortcolumn.length === 0) {
            return filteredRows;
        }
        const { columnKey, direction } = sortcolumn[0];

        let gridSortedRows = [...filteredRows];

        switch (columnKey) {
            case 'slno':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'userid':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'id':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'searchquery':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'title':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'datatype':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'activity':
                gridSortedRows = gridSortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            default:
        }
        return direction === 'DESC' ? gridSortedRows.reverse() : gridSortedRows;
    }, [filteredRows, sortcolumn]);
    function clearGridFilters(){
        togglefilterClass(true);
        setFilters(filterColumnObj);
    }
    function toggleGridFilters(v = true) {
        togglefilterClass()
        setFilters((_filters) => ({ ..._filters, enabled: v ? !_filters.enabled : _filters.enabled }));
    }
    return (
        <div className='most-not-relevant-grid' key="mnrg">
            <Toolbar enableFilter={true} onToggleFilter={toggleGridFilters} onClearFilter={clearGridFilters} enableDownloadButton={true} onDownloadClick={onDownloadClick}
                enableQualityDownloadButton={true} onQualityDownloadClick={onQualityDownloadClick}/>
            <FilterContext.Provider value={filters}>
                <DataGrid key={"mnrdg"}
                    columns={gridColumnsMostNotRelevant} rows={gdSortedRows} noRowsFallback={<EmptyRowsRenderer />}
                    headerRowHeight={filters.enabled ? 70 : undefined}
                    defaultColumnOptions={{
                        sortable: false,
                        resizable: true,
                    }}
                    sortColumns={sortcolumn}
                    onSortColumnsChange={onSortColumnsChange}
                />
            </FilterContext.Provider>
            <div className={`modal popupmostnotrelevant-modal ${popup.open ? "show" : ""}`}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content rounded-0 border-0">
                        <div className="modal-header">
                            <h5 className="modal-title ml-2">{popup.data.title}</h5>
                            <div className='ml-auto align-self-center'>
                                <button type="button" className="close mostnotpopup-close" data-dismiss="modal" aria-label="Close" onClick={popupOnClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <h6>Project Name:</h6>
                            <p>{popup.data.project}</p>
                            <h6>Country:</h6>
                            <p>{popup.data.country}</p>
                            {
                                popup.data.datatype === 'risk' ?
                                    <>
                                        <h6>Event:</h6><p>{popup.data.event}</p>
                                        <h6>Risk Owner/Initiator:</h6><p>{popup.data.owner_initiator}</p>
                                        <h6>Cause:</h6><p>{popup.data.cause}</p>
                                        <h6>Consequence:</h6><p>{popup.data.consequence}</p>
                                    </>
                                    :
                                    <>
                                        {
                                            summaryDescription("mostnotrelevant", `mnr${popup.data.id}`, popup.data.desc_summary, popup.data.desc)
                                        }
                                    </>

                            }
                        </div>
                    </div>
                </div>
            </div>
            {popup.open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </div>
    );
}
export default Mostnotrelevantgrid
