import React from 'react';
import utils from './app-util';
import { toast } from 'react-toastify';
function onRemove(e) {
    const targetParentElement = e.target.parentElement.parentElement.parentElement;
    targetParentElement.classList.add("d-none");
}
function cause(data, datatype) {
    return data[`${datatype}_cause`] !== undefined && data[`${datatype}_cause`] !== "" ?
        <li className="list-group-item d-flex align-items-center justify-content-between">
            <div className="pr-2">{data[`${datatype}_cause`]}</div>
        </li> : <li className="list-group-item">No causes recorded</li>
}

function summaryCause(data) {
    const displayCause =
        Object.keys(data).length !== 0 ? data['causes'].map((cause, index) =>
        (<li className="list-group-item" key={index}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className="pr-1">{cause}</div>
                <div><button className="trash" title="Remove" onClick={onRemove}>x</button></div>
            </div>
        </li>)
        )
            : <li className="list-group-item">No causes recorded</li>

    return displayCause;

}
function summaryPreventiveAction(data) {
    return Object.keys(data).length !== 0 ? data['preventive_actions'].map((cause, index) =>
    (<li className="list-group-item" key={index}>
        <div className='d-flex align-items-center justify-content-between'>
            <div className="pr-1">{cause}</div>
            <div><button className="trash" title="Remove" onClick={onRemove}>x</button></div>
        </div>
    </li>)
    )
        : <li className="list-group-item">No preventive actions recorded</li>
}
function summaryRecoveryAction(data) {
    return Object.keys(data).length !== 0 ? data['recovery_actions'].map((cause, index) =>
    (<li className="list-group-item" key={index}>
        <div className='d-flex align-items-center justify-content-between'>
            <div className="pr-1">{cause}</div>
            <div><button className="trash" title="Remove" onClick={onRemove}>x</button></div>
        </div>
    </li>)
    )
        : <li className="list-group-item">No recovery actions recorded</li>
}
function summaryConsequence(data) {
    return Object.keys(data).length !== 0 ? data['consequences'].map((cause, index) =>
    (<li className="list-group-item" key={index}>
        <div className='d-flex align-items-center justify-content-between'>
            <div className="pr-1">{cause}</div>
            <div><button className="trash" title="Remove" onClick={onRemove}>x</button></div>
        </div>
    </li>)
    )
        : <li className="list-group-item">No  consequence recorded</li>
}
function preventiveAction(data) {
    const randomNumList = utils.getUinqueRandomNumberList(20, 30);
    return data.action_info.Preventive !== undefined &&
        Object.keys(data.action_info).length > 0 &&
        data.action_info.Preventive.action_title.length > 0 ? data.action_info.Preventive.action_title.map((action, index) => {
            return (<li className="list-group-item" key={randomNumList[index]}>{action}</li>);
        }) : <li className="list-group-item">No actions recorded</li>
}
function recoveryAction(data) {
    const randomNumList = utils.getUinqueRandomNumberList(20, 30);
    return data.action_info.Recovery !== undefined && Object.keys(data.action_info).length > 0
        && data.action_info.Recovery.action_title.length > 0 ? data.action_info.Recovery.action_title.map((rAction, index) => {
            return (<li className="list-group-item" key={randomNumList[index]}>{rAction}</li>);
        }) : <li className="list-group-item">No actions recorded</li>
}
function consequence(data, datatype) {
    return data[`${datatype}_consequence`] !== undefined && data[`${datatype}_consequence`] !== "" ?
        <li className="list-group-item">{data[`${datatype}_consequence`]}</li>
        : <li className="list-group-item">No consequences recorded</li>
}
let isHighlighted = null;
function onClickHighlight(e){
    if((isHighlighted !== null) && (!isHighlighted.isSameNode(e.currentTarget))){
        document.querySelectorAll(`.list-group-item[data-riskid='${isHighlighted.parentElement.dataset.riskid}']`).forEach(function(el){
            el.classList.remove('highlight');
        });
    }
    
    isHighlighted = e.currentTarget;
    if(e.currentTarget.parentElement.classList.contains('highlight')){
        e.currentTarget.parentElement.classList.remove('highlight')
        document.querySelectorAll(`.list-group-item[data-riskid='${e.currentTarget.parentElement.dataset.riskid}']`).forEach(function(el){
            el.classList.remove('highlight');
        });
    }else{
        e.currentTarget.parentElement.classList.add('highlight')
        document.querySelectorAll(`.list-group-item[data-riskid='${e.currentTarget.parentElement.dataset.riskid}']`).forEach(function(el){
            el.classList.add('highlight');
        });
    }
}
function tbCause(riskCauses) {
    return riskCauses.map((risk, _index) => {
        return (
            risk.cause !== "" ?
                <li className="list-group-item" key={`cause${risk.risk_id}`} data-riskid={risk.risk_id}><div className='d-flex align-items-center justify-content-between' onClick={onClickHighlight}>
                    <div className="pr-1">{risk.cause}</div>
                    <div><button className="trash" data-riskid={risk.risk_id} data-cause={1} title="Remove" onClick={onRemove}>x</button></div></div>
                </li> : null
        );
    });
}
function tbConsequence(riskConsequences) {
    return riskConsequences.map((risk, _index) => {
        return (
            risk.consequence !== "" ?
                <li className="list-group-item" key={`consequence${risk.risk_id}`} data-riskid={risk.risk_id}><div className='d-flex align-items-center justify-content-between'>
                    <div className="pr-1">{risk.consequence}</div>
                    <div><button className="trash" data-riskid={risk.risk_id} data-consequence={1} title="Remove" onClick={onRemove}>x</button></div></div>
                </li>
                : null
        );
    });
}

function tbPreventiveAction(risks) {
    return risks.map((risk) => {
        return risk.actions.Preventive.map((action, _index) => {
            return <li className="list-group-item" key={action.action_id} data-riskid={risk.risk_id}><div className='d-flex align-items-center justify-content-between'>
                <div className="pr-1">{action.action_title}</div>
                <div><button className="trash" data-riskid={risk.risk_id} data-actionid={action.action_id} title="Remove" onClick={onRemove}>x</button></div></div>
            </li>
        });
    });
}
function tbRecoveryAction(risks) {
    return risks.map((risk) => {
        return risk.actions.Recovery.map((recoveryActionTB, _index) => {
            return <li className="list-group-item" key={recoveryActionTB.action_id} data-riskid={risk.risk_id}><div className='d-flex align-items-center justify-content-between'>
                <div className="pr-1">{recoveryActionTB.action_title}</div>
                <div><button className="trash" data-riskid={risk.risk_id} data-actionid={recoveryActionTB.action_id} title="Remove" onClick={onRemove}>x</button></div></div>
            </li>
        });
    });
}
function disclaimer(isthematic, data) {
    const commonDisclaimer = (<><p className='font-weight-bold mb-1 small font-italic text-danger'>Notes: </p>
        <p className='mb-1 small font-italic text-danger'><span>1. </span>
            Classification of actions into Preventive or Recovery has been done using ML algorithms in cases where EasyRisk data was not available.</p></>);
    const legacyDisclaimer = (<><p className='mb-1 small font-italic text-danger'><span>2. </span> Three part statement not available for legacy data.</p></>);
    const isRiskConsequence = !data.risk_consequence;
    const isRiskCause = !data.risk_cause;
    if (!isthematic && isRiskConsequence && isRiskCause) {
        return (
            <>
                {commonDisclaimer}
                {legacyDisclaimer}
            </>
        );
    }

    return commonDisclaimer;
}
function summaryDisclaimer() {
    return <p className='mb-1 small font-italic text-danger'>Note: The data presented herein is generated through Generative AI. While efforts have been made to ensure accuracy, the information may not be entirely reliable. Users are advised to exercise discretion and verify the data independently before making any decisions based on it.</p>
}
function Bowtie({ thematicBowtie = false, inputData, datatype, summaryBowtie = false, isConsolidatedSummary = false }) {
    let data = inputData;

    if (thematicBowtie) {
        data = data === null || data === undefined ? [
            {
                'risk_id': '',
                'functional_l0': '',
                'risk_description': '',
                'cause': '',
                'event': '',
                'consequence': '',
                'actions': {
                    'Preventive': [
                        {
                            'risk_id': '',
                            'action_id': '',
                            'action_title': '',
                            'action_description': ''
                        }
                    ],
                    'Recovery': []
                }
            }
        ] : data
    } else {
        data = Object.keys(data).length === 0 && !summaryBowtie ? {
            "risk_title": "",
            "action_info": {
                "Preventive": { "action_title": [], "action_desc": [] },
                "Recovery": { "action_title": [], "action_desc": [] }
            },
            "risk_event": "",
            "risk_cause": "",
            "risk_consequence": ""
        } : data;
    }
    return (
        <div className="bowtie-wrapper">
            <div className="bowtie-inner-wrapper">
                <div className="bowtie-container container-fluid">
                    <div className={`${isConsolidatedSummary ? "row align-items-stretch bowtie-row-container border consolidated-summary" : "row align-items-stretch bowtie-row-container border"}`}>
                        <div className="col-sm-2 border cause">
                            <h6 className="bowtie-column-header pt-1">Cause</h6>
                            <div className="border-light border-top"></div>
                            <div className="bowtie-cause-content">
                                <ul className="list-group list-group-flush causes">
                                    {
                                        summaryBowtie ? summaryCause(data) : (thematicBowtie ? tbCause(data) : cause(data, datatype))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3 preventive-actions">
                            <h6 className="bowtie-column-header pt-1">Preventive Actions</h6>
                            <div className="bowtie-preventive-actions-content border">
                                {/* <h6 className="bowtie-column-sub-header px-2 pt-1">Action Name</h6> */}
                                <ul className="list-group list-group-flush preventive_actions">
                                    {
                                        summaryBowtie ? summaryPreventiveAction(data) : (thematicBowtie ? tbPreventiveAction(data) : preventiveAction(data))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2 event d-flex justify-content-center align-item-center">
                            <div className="ellipse-shape border align-self-center">
                                <h6 className="bowtie-column-header py-1 border-light border-bottom">Event</h6>
                                <div className="bowtie-event-content text-capitalize" style={{ overflow: 'auto', maxHeight: '125px' }}>
                                    {
                                        (thematicBowtie || summaryBowtie) ? sessionStorage.getItem('query') :
                                            <div className="text-center">{data[`${datatype}_event`]}</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 recovery-actions">
                            <h6 className="bowtie-column-header  pt-1">Recovery Actions</h6>
                            <div className="bowtie-recovery-actions-content border">
                                {/* <h6 className="bowtie-column-sub-header px-2 pt-1">Action Name</h6> */}
                                <ul className="list-group list-group-flush recovery_actions">
                                    {
                                        summaryBowtie ? summaryRecoveryAction(data) : (thematicBowtie ? tbRecoveryAction(data) : recoveryAction(data))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2 border consequence">
                            <h6 className="bowtie-column-header  pt-1">Consequence</h6>
                            <div className="border-light border-top"></div>
                            <div className="bowtie-consequence-content">
                                <ul className="list-group list-group-flush consequences">
                                    {
                                        summaryBowtie ? summaryConsequence(data) : (thematicBowtie ? tbConsequence(data) : consequence(data, datatype))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='disclaimer pt-2'>
                    {
                        summaryBowtie ? summaryDisclaimer() : disclaimer(thematicBowtie, data)
                    }
                </div>
            </div>
        </div>
    );
}
function loadThematicBowtieData(setBowtieoption, query, setLoading) {
    const riskIDs = utils.getVisibleRiskResultIDs();
    setLoading(true)
    fetch(`/thematic-bt/${query}/${riskIDs}`, {
        method: "GET",
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        setBowtieoption({ show: true, thematic: true, data: results, dataid: "" })
        setLoading(false);
    }, (_err) => {
        setLoading(false);
        toast.warn("Error occured on fetching the result");
    });
}

export { Bowtie, loadThematicBowtieData }
