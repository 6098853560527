import React, { useEffect, useState, useMemo } from 'react';
import DataGrid from 'react-data-grid';
import { EmptyRowsRenderer } from '../grid/user-data-grid';

const HEADER_CLASSNAME = "filter-cell";
function downloadReportColumns() {
    return [
        { key: 'discipline', name: 'Discipline', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 't20', name: 'Top 20', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 't30', name: 'Top 30', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 't50', name: 'Top 50', headerCellClass: HEADER_CLASSNAME, editable: false },
        { key: 'custom', name: 'Custom', headerCellClass: HEADER_CLASSNAME, editable: false }
    ];
}

export default function DownloadReportGrid(props) {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setRows(props.data)
    }, [props])
    const gridColumns = useMemo(() => {
        return downloadReportColumns();
    }, []);
    return (
        <div className='download-report-grid' key={"download-rg"}>
            <DataGrid rowKeyGetter={(row) => row.id} columns={gridColumns} rows={rows} noRowsFallback={<EmptyRowsRenderer />}
                defaultColumnOptions={{
                    sortable: false,
                    resizable: true,
                }}
            />
        </div>
    );
}
