import Chatbot from "react-chatbot-kit";
import config, { CustomHeader } from "./config";
import MessageParser from "./messageParser";
import ActionProvider from "./actionProvider";
import { useEffect, useState } from "react";
import CloseButtonWidget from "./CloseButtonWidget";
import { createChatBotMessage } from "react-chatbot-kit";

const ChatbotWrapper = () => {
    const [minimize, setMinimize] = useState(false);
    const [maxScreen, setMaxScreen] = useState(false);
    const toggleChatbot = () => {
        setMaxScreen(false);
        setMinimize(prev => !prev);
    }
    useEffect(()=> {
        if (minimize){
            document.getElementsByClassName('chatbot-wrapper')[0]?.classList.add('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0]?.classList.add('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0]?.classList.add('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-inner-container')[0]?.classList.add('chatbot-container-minimize');
        } else {
            document.getElementsByClassName('chatbot-wrapper')[0]?.classList.remove('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0]?.classList.remove('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0]?.classList.remove('chatbot-container-minimize');
            document.getElementsByClassName('react-chatbot-kit-chat-inner-container')[0]?.classList.remove('chatbot-container-minimize');
        }
    }, [minimize]);

    const validateInput = (input) =>{
        return input.length > 0 && input.trim().length !== 0;
    }
    const maximizeScreen  = () => {
        setMaxScreen(prev => !prev);
    }

    return (
        <>
        <div className={`${maxScreen? 'maxScreen': ''} chatbot-wrapper`} > 
            <Chatbot 
            config={{
                ...config, 
                initialMessages: [createChatBotMessage(`Hello, Please type your query on Risks.`, {widget: "closeButton"})],
                customComponents: {
                    ...config.customComponents, 
                    header: () => <CustomHeader toggleChatbot={toggleChatbot} minimize={minimize} maximizeScreen={maximizeScreen} />,    
                },
                customStyles: {
                    chatButton: {  display: minimize? 'none': 'block'}
                },
                widgets: [
                    {
                        widgetName: 'closeButton',
                        widgetFunc: (props) => <CloseButtonWidget {...props} />,
                    },
                    ],
            }} 
            messageParser={MessageParser} 
            actionProvider={ActionProvider} 
            validator={validateInput}
            placeholderText='Type your message..'
            />
            </div>
        </>
        
    )
}
export default ChatbotWrapper;