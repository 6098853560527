import React from 'react';
import clear from '../../assets/x-circle.svg';
import { clearChatbot } from './services';

const CloseButtonWidget = (props) => {
    const { state, children, actions } = props;

    const handleClose = () => {
        if (state.loading !== true) {
                clearChatbot();
                actions?.clearChatMessages();    
        }
    };
    if (state?.messages?.length <= 1) {
        return;
    }

    return (

        <div className={`clear-icon-container ${state.loading ? 'clear-icon-data-load' : ''}`}>
            <button className="chatbot-button chatbot-button-clear dancing-icon" disabled={state.loading} onClick={handleClose}><img src={clear} alt="Clear Chat" />
                <span className="hover-text">{state.loading? `loading..`:`Refresh`}</span>
            </button>
            {children}
        </div>
    );
};

export default CloseButtonWidget;