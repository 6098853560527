import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import utils from './app-util';

function getYears(minmax, dateList, useMinMax = false) {
    let years = [];
    if (useMinMax) {
        for (let i = minmax[1].getFullYear(); i >= minmax[0].getFullYear(); --i) {
            years.push(i);
        }
        return years;
    } else {
        dateList = dateList.filter(function (dateVal) {
            return dateVal !== "";
        });
        years = dateList.map(function (date) {
            return new Date(date).getFullYear();
        })
        years = [...new Set(years)].sort((y1, y2) => y1 - y2);
        return years;
    }
}
export default function DateFilter({ type, modifiedList, dateFilterOnCall }) {
    const minmax = utils.getMinMaxFromDateList(modifiedList)
    const [daterangevalue, setDaterangevalue] = useState(null);
    const years = getYears(minmax, modifiedList, false);
    
    function dataRangePickrOnChange(val) {
        document.querySelector("#sinceyear" + type).value = "";
        setDaterangevalue(val);
        if (val !== null) {
            utils.clearBarChartHighlight();
            dateFilterOnCall(val);
        }
    }
    function onYearChange(e) {
        setDaterangevalue(null);
        if (e.target.value !== "" && e.target.value.length === 4) {
            utils.clearBarChartHighlight();
            dateFilterOnCall(e.target.value);
        }
    }

    return (
        <div className={`date-filter-wrapper p-2 small ${type}`} style={{ fontSize: '0.875rem' }}>
            <h6 className=''> </h6>
            <div className='yearly-filter'>
                <div className='month-year-picker'>
                    <div className='my-1'>Year</div>
                    <select id={`sinceyear${type}`} className='form-control-sm rounded-0 w-100 sinceyear' onChange={onYearChange}
                        style={{ border: "2px solid var(--dark-grey-color)" }} defaultValue={""}>
                        <option value={""} disabled>Select Year</option>
                        {
                            years.map((year) => {
                                return <option value={year} key={year}>{year}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='my-1'>Custom Dates</div>
                <DateRangePicker value={daterangevalue} onChange={dataRangePickrOnChange} minDate={minmax[0]} maxDate={minmax[1]} />
            </div>
        </div>
    );
}
/**
 * This function return the date range outside of this DateFilter(Date Range Calender) component scope
 * @param {String} dataType  Use the type of data for the date range calender component
 * @returns {Array} returns the date range{From Date, To Date}
 */
function getSelectedDateRange(dataType) {
    const dateRangeFrom = document.querySelector(`.date-filter-wrapper.${dataType} .react-daterange-picker input[name='daterange_from']`).value,
        dateRangeTo = document.querySelector(`.date-filter-wrapper.${dataType} .react-daterange-picker input[name='daterange_to']`).value;
    if (dateRangeFrom !== "" && dateRangeTo !== "") {
        return [new Date(dateRangeFrom), new Date(dateRangeTo)];
    } else {
        return [];
    }
}

/**
 * This function return the selected year value outside of this DateFilter(Year Dropdown) component scope
 * @param {String} dataType  Use the type of data for the year dropdown component
 * @returns {String} return the selected year value
 */
function getSelectedYearValue(dataType) {
    return document.querySelector(`.date-filter-wrapper.${dataType} .sinceyear`).value;
}
export { getSelectedDateRange, getSelectedYearValue };
