import React from "react";
function showLoader() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-ring"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#000"
          strokeWidth="10"
          r="40"
          strokeDasharray="188.49555921538757 64.83185307179586"
          transform="rotate(0 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
}
export default showLoader;
