import utils from "../app-util";
import { getSelectedDateRange, getSelectedYearValue } from "../date-filter";
import { getTheListIfAdvanceSearchOn, getTheListIfYearOn, getTheListIfDateRangeOn, plotFilterOnDeselect } from "../filter-utils";

const QUALITY_ITEM_SELECTOR = '.quality-item';

function displyCard(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
}
function hideCard(element) {
    element.classList.add('d-none');
    element.classList.remove('d-block');
}
/**
 * The function filters the search results based on the tagname passed as argument
 * @param {String} tagName 
 * @param {String} filterType 
 * @param {NodeList} cardElementLists -
 * @returns return the count of filtered results
 */
function getFilterResult(tagNames, filterType, cardElementLists) {
    let count = 0;
    const advSearchElems = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
    const selectedYearValue = getSelectedYearValue("quality");
    const selectedDateRangeValue = getSelectedDateRange("quality");
    if (cardElementLists === undefined) {
        cardElementLists = document.querySelectorAll(QUALITY_ITEM_SELECTOR);
    }
    if (advSearchElems.length > 0) {
        cardElementLists = getTheListIfAdvanceSearchOn(advSearchElems, cardElementLists);
    } else if (selectedYearValue !== "") {
        cardElementLists = getTheListIfYearOn(selectedYearValue, cardElementLists);
    } else if (selectedDateRangeValue.length > 0) {
        cardElementLists = getTheListIfDateRangeOn(selectedDateRangeValue, cardElementLists);
    }

    const filteredResultsIds = [];
    if(typeof tagNames === 'object') {
        if (tagNames['characteristics'].length === 0 && tagNames['disciplinefunction'].length === 0 && tagNames['equipmenttype'].length === 0) {
            cardElementLists.forEach(function (element) {
                count += 1;
                displyCard(element);
            });
        } else {
            tagNames['characteristics'].forEach(function (params) {
                cardElementLists.forEach(function (element) {
                    if (element.dataset['characteristics'].split('@#').includes(params)) {
                        filteredResultsIds.push(element.dataset.id);
                    }
                });
            });
            tagNames['disciplinefunction'].forEach(function (params) {
                cardElementLists.forEach(function (element) {
                    if (element.dataset['disciplinefunction'].split('@#').includes(params)) {
                        filteredResultsIds.push(element.dataset.id);
                    }
                });
            });
            tagNames['equipmenttype'].forEach(function (params) {
                if(params === 'Legacy data(Not Mandatory)' || params === '') {
                    cardElementLists.forEach(function (element) {
                        if ((element.dataset['equipmenttype'] === 'Legacy data(Not Mandatory)') || (element.dataset['equipmenttype'] === '')) {
                            filteredResultsIds.push(element.dataset.id);
                        }
                    });
                } else {
                    cardElementLists.forEach(function (element) {
                        if (element.dataset['equipmenttype'] === params) {
                            filteredResultsIds.push(element.dataset.id);
                        }
                    });
                }
            })
            count = filterUsingID(cardElementLists, filteredResultsIds)
        }
    } else{
        cardElementLists.forEach(function (element) {
            if (element.dataset[filterType].includes(tagNames)) {
                count += 1;
                displyCard(element);
            } else {
                hideCard(element);
            }
    
        });
    }
    
    return count;
}

function getTheListIfDropDownFilterOn(_selectedTagElem, allCards) {
    const filteredElements = [];

    const characteristics = JSON.parse(document.querySelector(".quality-custom-autocomplete.characteristics").dataset.selectedvalue);
    const disciplinefunction = JSON.parse(document.querySelector(".quality-custom-autocomplete.disciplinefunction").dataset.selectedvalue);
    const equipmenttype = JSON.parse(document.querySelector(".quality-custom-autocomplete.equipmenttype").dataset.selectedvalue);
    
    const tagLists = {
        'characteristics': characteristics,
        'disciplinefunction': disciplinefunction,
        'equipmenttype': equipmenttype
    }

    allCards.forEach(function (element) {
        tagLists['characteristics'].forEach((tag)=>{
            if (element.dataset['characteristics'].split('@#').includes(tag)) {
                filteredElements.push(element);
            }
        });
        tagLists['disciplinefunction'].forEach((tag)=>{
            if (element.dataset['disciplinefunction'].split('@#').includes(tag)) {
                filteredElements.push(element);
            }
        });
        tagLists['equipmenttype'].forEach((tag)=>{
            if (tag === 'Legacy data(Not Mandatory)' || tag === '') {
                if ((element.dataset['equipmenttype'] === 'Legacy data(Not Mandatory)') || (element.dataset['equipmenttype'] === '')) {
                    filteredElements.push(element);
                }
            } else if (element.dataset['equipmenttype'] === tag) {
                filteredElements.push(element);
            }
        });
    });

    return [...new Set(filteredElements)];
}

function filterUsingID(allCards, ids) {
    let count = 0;
    const uniqueIDs = [...new Set(ids)];
    allCards.forEach(function (element) {
        if (uniqueIDs.includes(element.dataset.id)) {
            count += 1;
            displyCard(element);
        } else {
            hideCard(element);
        }
    });
    return count;
}
/**
 * Display all the result cards by removing the class 'd-none'
 * @returns total count of search results
 */
function displayAllQualityCard() {
    let count = 0;
    document.querySelectorAll(QUALITY_ITEM_SELECTOR).forEach(function (element) {
        displyCard(element);
        count += 1;
    });
    return count;
}
/**
 * Clear the filters on leftsidebar dropdown filters
 */
function qualityFilterOnClear() {
    document.querySelectorAll('.quality-filter-combobox input').forEach(function (element) {
        element.value = '';
    });
    document.querySelector(".date-filter-wrapper.quality #sinceyearquality").value = '';
    document.querySelector(".date-filter-wrapper.quality .react-daterange-picker__clear-button").click();
}
/**
 * Return the number of result count after filtering using Ids.
 * @param {Object} data 
 */
function qualityPlotFilterOnCall(data) {
    const allCards = document.querySelectorAll(QUALITY_ITEM_SELECTOR);
    if(data.filter) {
        return filterUsingID(allCards, data.id.split(','));
    } else {
        return plotFilterOnDeselect(allCards, 'quality');
    }
}

function qualityHeatmapFilterOnCall(data) {
    const allCards = document.querySelectorAll(QUALITY_ITEM_SELECTOR);
    let count = 0;
    if (data !== null) {
        allCards.forEach(function (element) {
            if (data.data.data_ids.includes(element.dataset.id)) {
                count += 1;
                displyCard(element);
            } else {
                hideCard(element);
            }
        });
    } else {
        count = plotFilterOnDeselect(allCards, 'quality');
    }

    return count;
}
function getVisibleElementsFilterData() {
    const quality_characteristics = [], quality_discipline_function = [], quality_equipment_type = [];
    const originalElements = document.querySelectorAll('.quality-item');
    const visibleElements = document.querySelectorAll('.quality-item.d-block'),
        hiddenElements = document.querySelectorAll('.quality-item.d-none');
    if (visibleElements.length === 0 && hiddenElements.length === 0) {
        originalElements.forEach(function (elem) {
            quality_characteristics.push(elem.dataset.characteristics.split('@#'));
            quality_discipline_function.push(elem.dataset.disciplinefunction.split('@#'));
            quality_equipment_type.push(elem.dataset.equipmenttype);
        });
    } else {
        visibleElements.forEach(function (elem) {
            quality_characteristics.push(elem.dataset.characteristics.split('@#'));
            quality_discipline_function.push(elem.dataset.disciplinefunction.split('@#'));
            quality_equipment_type.push(elem.dataset.equipmenttype);
        });
    }
    return {
        quality_characteristics: quality_characteristics,
        quality_discipline_function: quality_discipline_function,
        quality_equipment_type: quality_equipment_type
    };
}

export { getFilterResult, displayAllQualityCard, qualityFilterOnClear, qualityPlotFilterOnCall, qualityHeatmapFilterOnCall, getTheListIfDropDownFilterOn, getVisibleElementsFilterData }
