import React from 'react';
class ReportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Add to report",
            show: props.show,
            reports: props.reports,
            valid: true,
            message: "Please enter a valid report name"
        };
    }

    onCancelBtnClick(_e) {
        this.props.onReportModalCancelClick({ reportName: "", close: false });
    }

    onOkBtnClick(_e) {
        const existingName = document.querySelector('#existingname');
        const newName = document.querySelector('#newReportName');
        if (!existingName.disabled) {
            if (existingName.value !== "") {
                this.setState({ valid: true });
                this.props.onReportModalOkClick({ reportName: existingName.value, close: true });
            } else {
                this.setState({ valid: false });
            }
        }
        if (!newName.disabled) {
            if (newName.value !== "") {
                this.setState({ valid: true });
                this.props.onReportModalOkClick({ reportName: newName.value, close: true });
            } else {
                this.setState({ valid: false });
            }
        }

    }
    onExistingReportDropdownChange(e) {
        const newReportElem = document.querySelector('#newReportName');
        if (e.currentTarget.value !== '') {
            this.setState({ valid: true });
            newReportElem.value = ""
            newReportElem.disabled = true;
        } else {
            this.setState({ valid: false });
            newReportElem.disabled = false;
        }
    }
    onNewReportChange(e) {
        const existReportElem = document.querySelector('#existingname');
        if (e.currentTarget.value !== '') {
            this.setState({ valid: true });
            existReportElem.selectedIndex = 0;
            existReportElem.disabled = true;
        } else {
            this.setState({ valid: false });
            existReportElem.disabled = false;
        }
    }
    render() {
        return (
            <div>
                <div className={`modal-wrapper ${this.props.show ? "visible" : ''}`}>
                    <div className='custom-modal shadow-lg border'>
                        <h6 className='title'>{this.state.title}</h6>
                        <div className='content my-4'>
                            <div className="form-group">
                                <label>Select existing report name</label>
                                <select className="custom-select custom-select-sm" id="existingname" onChange={this.onExistingReportDropdownChange.bind(this)}>
                                    <option defaultValue=""></option>
                                    {
                                        (Array.isArray(this.props.reports) ?
                                            this.props.reports.map((name, _index) => {
                                                return <option key={name} value={name}>{name}</option>
                                            }) : null
                                        )
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="newReportName">Enter new report name</label>
                                <input type="text" id="newReportName" className="form-control"
                                    placeholder="Enter new report name" onChange={this.onNewReportChange.bind(this)} />
                            </div>
                        </div>
                        {
                            this.state.valid ? null : (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <span>{this.state.message}</span>
                                    {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                                </div>
                            )
                        }
                        <div className='button-container'>
                            <button className='cancel mr-2' onClick={this.onCancelBtnClick.bind(this)}>Cancel</button>
                            <button className='ok' onClick={this.onOkBtnClick.bind(this)}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportModal;
