// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";
import chatbotMsg from '../../assets/chatbotMsg.svg';
import chatbotIcon from '../../assets/chatbotIcon.svg';
import chevronUp from '../../assets/chevron_up.svg';
import minus from '../../assets/minus.svg';
import maximize from '../../assets/maximize.svg';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CloseButtonWidget from './CloseButtonWidget'

export const CustomHeader = ({toggleChatbot, minimize, maximizeScreen}) => {
    return( 
    <div style={{ backgroundColor: '#FBCF09', padding: '10px', color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <img style={{marginRight: '5px', marginBottom:'5px'}} src={chatbotMsg} alt='Definitions'  title='chat bot msg'/> 
        <span>Risk Chatbot</span>
      </div>
      <div> 
        {!minimize && <button onClick={maximizeScreen}  className="chatbot-button" >
          <img src={maximize} alt='maximize' title="Maximumize screen"/>
        </button>}
        <button onClick={toggleChatbot} className="chatbot-button">
          <img src={minimize? chevronUp: minus} alt={minimize? 'expand': 'minimize'} title="Show/Hide"/>
        </button>
      </div>
    </div>
    );
}
const UserInitials = () => {
  const [userFirstname = '', userSecondname = ''] = sessionStorage.getItem("displayName").split(' ');
  const firstInitial = userFirstname? userFirstname[0]?.toUpperCase() : 'U';
  const secondInitial = userSecondname? userSecondname[0]?.toUpperCase():  '';
  return `${firstInitial}${secondInitial}`;
}
const config = {
  initialMessages: [createChatBotMessage(`Hello, Please type your query on Risks.`, {widget: "closeButton"})],
  botname: 'QurieBot',
  customStyles:{
    botMessageBox: {
      backgroundColor: "#FEFEFE"
    },
    userMessageBox: {
      backgroundColor: "#FEFEFE"
    }
  },
  customComponents: {
    botAvatar: () => <div style={{ padding: '10px' }}>
      <span><img src={chatbotIcon} alt="chatbot icon" title="chatbot icon"/></span>
    </div>,
    userAvatar: () => <div style={{ minWidth:'34px', height: '34px', display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
      <div style={{ backgroundColor: '#003c8a', color: 'white', verticalAlign: 'middle',  padding: '4px', borderRadius: '50%', textAlign: 'center', width: '75%', height: '75%', fontSize: '0.8rem'}}>{UserInitials()}</div>
      </div>,
    botChatMessage: (props) => {
      if (typeof props?.message === 'string') {
        return (<div className="react-chatbot-kit-chat-bot-message">
          <Markdown remarkPlugins={[remarkGfm]}>{props?.message}</Markdown> 
          <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
          </div>)
      } else {
        return props?.message
      }
    },

  },
}

export default config;