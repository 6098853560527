import React from 'react';
import utils from './app-util';
import mostRelevantNotRelevantOperation from './relevant-operation';
import summaryDescription from './summaryDescription';

const valueConst = utils.createKeyFromSearchKeyWord();
function LearningItem({ reportLessonCallback, ll_id, title, subTitle, desc, summary, mostRelevantList, notRelevantList, globalMR }) {
    function onAddtoLesson(e) {
        reportLessonCallback({ type: "lesson", currentElement: e.currentTarget });
    }

    function onAddtoNoR(e) {
        mostRelevantNotRelevantOperation(e, "lesson", "/add_to_not_relevant", "/delete_from_not_relevant", "lessonid", "not relevant");
    }
    function onAddtoMostRelevantLesson(e) {
        mostRelevantNotRelevantOperation(e, "lesson", "/add_to_most_relevant", "/delete_from_most_relevant", "lessonid", "most relevant");
    }
    
    return (
        <div className="card card-box-shadow lesson-item learn-item mb-4" data-id={ll_id}
            key={`${valueConst}${ll_id}`}>
            <div className="learn-item-wrapper mx-2 pt-3 pb-2">
                <div className="d-flex justify-content-between learn-item-content px-2">
                    <div className="learn-item-header-text-content" data-toggle="collapse" data-target={`#learning-${ll_id}`} aria-expanded="false" aria-controls={ll_id}>
                        <h6 className="header-text" title={title}>{title}</h6>
                        <p className="mb-1 small sub-header-text">{subTitle}</p>
                        <br />
                    </div>
                    <div className="learn-item-control-content">
                        <div className="learn-item-report-controls">
                            <form>
                                <div className="form-group form-check mb-0">
                                    <input type="checkbox" className="form-check-input addreport" id={`addLessonReport${ll_id}`} data-lessontitle={title}
                                        onChange={onAddtoLesson} data-lessonid={ll_id} />
                                    <div className='Toastify__spinner checkbox-spinner'></div>
                                    <label className="form-check-label" htmlFor={`addLessonReport${ll_id}`}>Add to Report</label></div>
                                <div className="form-group form-check mb-0">
                                    <input type="checkbox" className="form-check-input mostrelevantlesson" id={`addMostRelevant${ll_id}`}
                                        onChange={onAddtoMostRelevantLesson} data-lessonid={ll_id} defaultChecked={mostRelevantList.includes(ll_id)} 
                                        disabled={globalMR.includes(ll_id) ? true : false}/>
                                    <div className='Toastify__spinner checkbox-spinner'></div>
                                    <label className="form-check-label" htmlFor={`addMostRelevant${ll_id}`}>Most Relevant</label></div>
                                <div className="form-group form-check mb-0">
                                    <input type="checkbox" className="form-check-input notrelevant" id={`addNotRelevant${ll_id}`} onChange={onAddtoNoR}
                                        data-lessonid={ll_id} defaultChecked={notRelevantList.includes(ll_id)} />
                                    <div className='Toastify__spinner checkbox-spinner'></div>
                                    <label className="form-check-label" htmlFor={`addNotRelevant${ll_id}`}>Not Relevant</label></div>
                            </form>

                        </div>
                    </div>
                </div>
                <div className="detail-content collapse mx-3 mt-3" id={`learning-${ll_id}`}>
                    {/* <p className='text-justify'>{desc}</p> */}
                    {/* --------Accordion start-------- */}
                    {summaryDescription("learnings", ll_id, summary, desc)}
                    {/* --------Accordion End-------- */}
                </div>
            </div>
        </div>
    );
}
export default LearningItem;
