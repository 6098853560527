import React, { useState } from 'react';
import utils from './app-util';
import recentSearchList from './recentSearch';
function clearMustIncludeInputField(searchvalue) {
    const query = window.sessionStorage.getItem("query");
    const mustInclude = document.querySelector('.must-include');
    if (query !== null && mustInclude !== null && query !== undefined && (searchvalue.length > 0)) {
        mustInclude.value = "";
        mustInclude.classList.remove('active');
        
    }
}
function searchKeypressAction(e, searchCallback) {
    if (e.currentTarget.value.length > 0) {
        clearMustIncludeInputField(e.currentTarget.value);
    }
    if (e.key === "Enter" && e.currentTarget.value !== "") {
        recentSearchList.saveSearch(e.currentTarget.value);
        searchCallback(e.currentTarget.value);
    }
}
function getsearchIcon() {
    return (
        <svg className="recent-search-icon" width="20" height="20" focusable="false" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 
            5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 
            5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
    );
}
const uniqueKeyList = utils.getUinqueRandomNumberList(200, 200)
function Search({ isAdvancedSearch, _defaultValue, searchCallback }) {
    const autocompleteRS = React.createRef();
    const [searchlist, setSearchlist] = useState(recentSearchList.fetchSearch());
    const [filterlist, setFilterlist] = useState(searchlist)
    const [movement, setMovement] = useState({ cursor: 0, result: searchlist })
    function onKeyPress(e) {
        searchKeypressAction(e, searchCallback);
    }
    function searchBtnOnClick(_e) {
        const searchInpVal = document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value;
        if (searchInpVal !== "") {
            recentSearchList.saveSearch(searchInpVal);
            searchCallback(searchInpVal);
        }
    }
    function onClick() {
        const elem = document.querySelector('.advanced-search-wrapper');
        if (elem.classList.contains("active")) {
            document.querySelector('.must-include').value = "";
            elem.classList.remove("active");
        } else {
            elem.classList.add("active");
        }
    }
    function clearBtnOnClick() {
        document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value = "";
    }
    function onFocus() {
        setSearchlist(recentSearchList.fetchSearch());
    }
    function recentSearchOnClick(e) {
        const query = e.target.dataset.value;
        clearMustIncludeInputField(query);
        document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value = query;
        recentSearchList.saveSearch(query);
        searchCallback(query);
    }
    function onKeyDown(e) {
        const searchInputField = document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR);
        const { cursor, result } = movement;
        if (e.keyCode === 38 && cursor > 0) {
            setMovement((prevState) =>
                ({ ...movement, cursor: prevState.cursor - 1 })
            );
            searchInputField.value = searchlist[cursor - 1];
        } else if (e.keyCode === 40 && cursor < result.length - 1) {
            setMovement((prevState) =>
                ({ ...movement, cursor: prevState.cursor + 1 })
            );
            searchInputField.value = searchlist[cursor + 1];
        }
    }
    function onInput(e) {
        const temp = [];
        for (let i = 0; i < searchlist.length; i++) {
            if (searchlist[i].toUpperCase().includes(e.target.value.toUpperCase())) {
                temp.push(searchlist[i]);
            }
        }
        setFilterlist(temp);
    }
    return (
        <div className="search d-flex align-items-center" style={isAdvancedSearch ? null : { marginTop: "-110px" }}>
            <div className={isAdvancedSearch ? "col position-relative d-inline-block mx-auto advanced-search-active" : "col-8 position-relative d-inline-block mx-auto"}>
                {isAdvancedSearch ? null : <h1 className='text-center tour-welcome'
                    style={{ color: "var(--red-bg-color)", fontWeight: "900", fontFamily: "FuturaBold", fontSize: "70px", marginBottom: "2.375rem" }}>Qurie</h1>}
                <div className='position-relative search-input-wrapper tour-searchinput'>
                    <input type="text" className="search-input-field form-control" placeholder="Enter keywords"
                        onKeyPress={onKeyPress} autoComplete="on" onFocus={onFocus} onKeyDown={onKeyDown} onInput={onInput} />
                    {isAdvancedSearch ? <button type='button' className='border-right' id="search-clear-btn" onClick={clearBtnOnClick}></button> : null}
                    <button type='button' id="search-icon-btn" onClick={searchBtnOnClick}></button>
                    <div className='autocomplete-recent-search' ref={autocompleteRS}>
                        <ul className='list-group'>
                            {
                                filterlist.map((item, index) => {
                                    return <li className={`list-group-item py-1 border-0 ${movement.cursor === index ? 'active' : ''}`} key={`rs${uniqueKeyList[index]}`}
                                        onMouseDown={recentSearchOnClick} data-value={item}><div className='d-inline mr-2'>{getsearchIcon()}</div>{item}</li>;
                                })
                            }
                        </ul>
                    </div>
                </div>
                {isAdvancedSearch ? null :
                    <div className='text-center mt-4 recent-search-lists'><span className='font-weight-bold d-inline-block overflow-hidden'>Recent Searches:&nbsp;</span>
                        <span className='text-danger d-inline-block recent'>
                            {recentSearchList.fetchSearch().length > 0 ? recentSearchList.fetchSearch().join(", ") : "No search keyword found!"}
                        </span>
                    </div>}
            </div>
            {isAdvancedSearch ?
                <span className="advanced-search tour-advancedsearch" onClick={onClick} style={{ cursor: "pointer", textDecoration: "underline" }}
                    title="Click here for advanced search">Advanced Search</span> :
                <span className=""></span>}
        </div>
    );
}

export default Search
